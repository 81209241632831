/** Copyright �2013 Renoworks, Inc. All rights reserved. www.Renoworks.com
 *
 * @param {jQuery} $ A reference to the jQuery object.
 */
(function($) {
  /**
   * TODO: Make this code more dynamic, consolidate the functions, addSiding, addRooding, etc. into single dynamic functions, addProduct(may require, addTileProduct, addGridProduct)
   */
  Renoworks = window.Renoworks || {};
  Renoworks.ProductHandler = new ProductHandler();

  var userProducts = [];

  function ProductHandler() {
    this.designerPicks = {};
    this.projectPicks = {};
    this.sidingColors = [];

    this.productMap = {
      siding: ['siding', 'siding main', 'siding accent', 'wall', 'wall accent', 'painted wall'],
      masonry: ['masonry', 'wall', 'wall accent', 'painted wall'],
      roofing: ['roofing'],
      trim: ['corner trim', 'trim', 'window trim', 'painted wall'],
      door: ['door', 'doors'],
      window: ['window', 'windows'],
      shutter: ['shutter'],
    };

    // NOTE: this information is not stored with the product data, therefore needs to be hardcoded
    //       also this information is used only for analytics
    this.brandMap = {
      siding: 'Royal',
      masonry: 'Royal',
      trim: 'Royal',
      shutter: 'Royal',
      roofing: 'Generic',
      door: 'Generic',
      window: 'Generic',
    };
  }

  ProductHandler.prototype.renderSelectedProduct = function renderSelectedProduct(data) {
    const { $applyToSection, selectedProductType } = Renoworks.ProjectViewPage;

    const layerNames = [];
    const isWall = selectedProductType === 'masonry' || selectedProductType === 'siding';

    $applyToSection.find('.apply_to_cell').each(function() {
      const $this = $(this);
      const $input = $this.find('input');
      const $label = $this.find('label');

      if ($input.is(':checked')) {
        $input.addClass('has_product');

        const name = $label.attr('data-layer-name');
        if (name) {
          if (layerNames.indexOf(name) === -1) {
            layerNames.push(name);
          }

          if (isWall) {
            // make sure we apply class to the other wall selections
            $('.siding_configurator .apply_to_cell').each(function() {
              // loop through both "apply to" sections for siding and apply class based on selected layer names
              if ($('label', $(this)).attr('data-layer-name') === name) {
                $('input', $(this)).addClass('has_product');
              }
            });
            $('.masonry_configurator .apply_to_cell').each(function() {
              // loop through both "apply to" sections for siding and apply class based on selected layer names
              if ($('label', $(this)).attr('data-layer-name') === name) {
                $('input', $(this)).addClass('has_product');
              }
            });
          }
        }
      }
    });

    if (layerNames.length) {
      const { type, data: dataStr } = data;
      let mainProduct = true;

      let { rwd, color } = Renoworks.Utils.parseDataString(dataStr);
      if (!rwd) {
        console.warn(`${selectedProductType}: Product rwd is missing`);
      }

      const product = Renoworks.ProductController.getProductByRWD(rwd);
      const isWindowsTrim = product && product.name && product.name.toLowerCase() === 'window trim';

      if (type === 'grid' && isWindowsTrim) {
        mainProduct = false;
      }

      // track state of project
      const state = Renoworks.ProductUndoRedo.getNewState();
      const layers = Renoworks.ProjectController.getLayers();

      layers.forEach(layer => {
        const layerState = getCurrentLayerState(layer);

        if (layerNames.indexOf(layer.name) >= 0) {
          // chosen products will overwrite the current ones
          layerState.id = layer.id;

          if (mainProduct) {
            layer.product = data;
            Renoworks.ProjectController.setProductForRegion(dataStr, type, layer.name);

            layerState.product = data;
          } else {
            layer.additionalProducts = [data];
            layerState.additionalProducts = [data];
          }

          // @HACK: both window and trim are mapped to window which would result in wrong product type tracked
          let productTypeAnalytics = '';
          if (isWindowsTrim) {
            productTypeAnalytics = 'trim';
          } else if (!isWindowsTrim && product.filters.includes('Windows')) {
            productTypeAnalytics = 'window';
          } else {
            productTypeAnalytics = this.getProductTypeFromName(layer.type);
          }

          Renoworks.AnalyticsHandler.addProductToTrack(
            productTypeAnalytics,
            this.getProductAnalyticsInfo(
              product,
              type,
              Renoworks.ProjectViewPage.selectedProductType,
              type === 'tile' ? { color } : {}
            )
          );
        }

        if (!$.isEmptyObject(layerState)) {
          state.layers.push(layerState);
        }
      });

      Renoworks.ProductUndoRedo.push(state);

      Renoworks.MaskingController.setDesignMode(true);
      // Hide masking canvas
      Renoworks.ProjectHandler.setProjectDesignMode(true);

      const finished = Renoworks.Utils.useOverlaySpinner(Renoworks.ProjectViewPage.$image);

      const renderingDone = () => {
        finished();
        this.updateProductSummary();
      };

      Renoworks.ProjectController.render()
        .then(() => {
          // commit analytics
          Renoworks.AnalyticsHandler.trackAppliedProducts();
          renderingDone();
        })
        .catch(err => {
          if (err) console.error(err);
          renderingDone();
        });
    }
  };

  ProductHandler.prototype.resetAccordions = function(accordion) {
    if (accordion) {
      $('h3:first-of-type', accordion).each(function() {
        if ($('h3:eq(1) + div', accordion).css('display') == 'none') {
          $('h3:eq(1)', accordion).click();
        }
      });

      $('.grid_cell.selected', accordion).removeClass('selected');
    }
  };

  ProductHandler.prototype.loadProductForLocale = function(productForLocale) {
    if (productForLocale !== this.productForLocale) {
      this.productForLocale = productForLocale;
      if (Renoworks.ProjectController.getCurrentProject()) {
        Renoworks.ProjectController.render();
      }

      Renoworks.dataSettings.xml = 'product' + (productForLocale !== 'US' ? '_CA' : '');
      Renoworks.ProductController.load(Renoworks.dataSettings);
    } else {
      this.resetAccordions();
    }
  };

  ProductHandler.prototype.loadDesignerPicks = function loadDesignerPicks() {
    return new Promise((resolve, reject) => {
      if (!this.designerPicks.ds && !this.designerPicks.regular) {
        $.getJSON(`${Renoworks.Api.data('/product/designer_picks.json', false)}`)
          .then(({ palettes: { ds, regular } }) => {
            this.designerPicks.ds = this.populatePaletteProducts(ds);
            this.designerPicks.regular = this.populatePaletteProducts(regular);
            resolve();
          })
          .fail(e => reject(e));
      }
    });
  };

  ProductHandler.prototype.loadSummaryData = function() {
    $.ajax({
      type: 'GET',
      url: `data/${Renoworks.client}/product/project_picks.json?date=${Date.now()}`,
      dataType: 'json',
      success: json => {
        this.projectPicks = json;
        this.populateProjectPicks();
      },
    });
  };

  ProductHandler.prototype.loadSidingColors = function() {
    $.ajax({
      type: 'GET',
      url: 'data/' + Renoworks.client + '/product/siding_colors.json?date=' + new Date().getTime(),
      dataType: 'json',
      success: sidingColors => {
        this.sidingColors = sidingColors;
        loadColorFamilies(sidingColors);
      },
    });
  };

  ProductHandler.prototype.populateProjectPicks = function populateProjectPicks() {
    Renoworks.ProductController.getProducts('Siding').forEach(product => {
      const pick = this.projectPicks.find(
        p => new RegExp(product.name, 'i').test(p.profile) && (!p.data || !p.data.length)
      );

      if (pick) {
        product.data = pick.relatedProducts;
      }
    });
  };

  ProductHandler.prototype.selectDoorConfiguration = function() {
    var door = Renoworks.ProductController.getProducts('Doors')[0];
    var doorLayer = Renoworks.ProjectController.getLayersByType('Door')[0];

    if (doorLayer) {
      var config = doorLayer.config;

      if (config && config != '') {
        var options = door.getTabOptions('Configuration');
        $.each(options, function(i, option) {
          if (option.name == config) {
            door.selectGrid(option.tab, option.grid);
            return false;
          }
        });
      } else {
        var option = door.getTabOptions('Configuration')[0];
        door.selectGrid(option.tab, option.grid);
      }

      loadDoors();
    }
  };

  ProductHandler.prototype.loadProductConfigurators = function() {
    const categories = Renoworks.ProductController.getCategories();
    const getCategory = categoryName => {
      const category = categories.find(
        ({ name }) => name.toLowerCase() === categoryName.toLowerCase()
      );
      return (category && category.categories) || [];
    };

    let sidingStyles = getCategory('siding');
    let roofingStyles = getCategory('roofing');
    let masonryStyles = getCategory('masonry');

    loadSiding(sidingStyles);
    loadTrim();
    loadDoors();
    loadWindows();
    loadShutters();
    loadRoofing(roofingStyles);
    loadMasonry(masonryStyles);

    // Close all nested sections
    Object.keys(this.productMap)
      .map(name => $(`#project_view #product_submenu section.${name}_configurator`))
      .filter($section => $section.find('.section-body').hasClass('open'))
      .forEach($section =>
        $section
          .find('.section-body.open')
          .prev('.section-header')
          .click()
      );
  };

  function getCurrentLayerState(layer) {
    var layerState = {};

    if (layer.product || layer.additionalProducts.length) {
      // track current products
      layerState.id = layer.id;

      if (layer.product) layerState.product = layer.product;
      if (layer.additionalProducts.length) layerState.additionalProducts = layer.additionalProducts;
    }

    return layerState;
  }

  /**
   * Set up palettes and load, if required. This met
   * This method must be called after project is set/opened (e.g. on Renoworks.Event.PROJECT_CHANGED)
   */
  ProductHandler.prototype.populateDesignerPicks = async function populateDesignerPicks() {
    if (!this.designerPicks.ds && !this.designerPicks.regular) {
      // To ensure palettes are loaded
      await this.loadDesignerPicks();
    }

    if (Renoworks.User.dsUser) {
      this.buildPalettes(true);
    }
    this.buildPalettes();
  };

  /**
   * Sets up HTML elements for palettes and their click handlers.
   *
   * @param {boolean} ds - specifies whether to build DS palettes or not
   */
  ProductHandler.prototype.buildPalettes = function buildPalettes(ds = false) {
    const $designerPicks = $(`#designer_picks_configurator_body .${ds ? 'ds_' : ''}designer_picks`);
    const designerPickHtml = $('.designer_pick:first')
      .wrap('<div />')
      .parent()
      .html();
    const locale = Renoworks.LocaleController.getLocale();
    const palettes = ds ? this.designerPicks.ds : this.designerPicks.regular;

    if (!palettes) {
      return;
    }

    const getWindowTrimGridProductData = (grid, tab, colorName) => {
      if (grid && tab && colorName) {
        const color = tab.selectedGrid.colors.find(
          c => c.name.toLowerCase() === colorName.toLowerCase()
        );

        grid.setColor(tab.name, color);
        return {
          data: `rwd=${grid.rwd}||gridSettings=${grid.getGridSettings()}`,
          type: 'grid',
        };
      }
    };

    const getGridProductData = grid => ({
      data: `rwd=${grid.rwd}||gridSettings=${grid.getGridSettings()}`,
      type: 'grid',
    });

    const getCornerTrimProductData = (trim, colorName) => {
      const color = Object.values(trim.colors).find(
        c => c.name.toLowerCase() === colorName.toLowerCase()
      );

      if (color && color.index) {
        trim.selectColor(color.index);
        return {
          data: `rwd=${trim.rwd}||settings=${trim.getSettings()}`,
          type: 'path',
        };
      }
    };

    const windowTrimGrid = Renoworks.ProductController.getProducts('Windows').find(
      w => w.name.toLowerCase() === 'window trim'
    );

    const windowTrimTab = windowTrimGrid && windowTrimGrid.tabs.find(t => t.visible);
    const cornerTrim = Renoworks.ProductController.getProducts('Corner Trim')[0];
    // Paths can be 0 or 2. 0 = 'Good', '2' = 'Best'
    cornerTrim.selectPath(
      Renoworks.client === 'homeplayprime' || Renoworks.client === 'power' ? 2 : 0
    );

    $designerPicks.html('');
    $('.designer_pick:first').unwrap();

    palettes.forEach(pick => {
      const $designerPick = $(designerPickHtml);
      const $swatchMain = $designerPick.find('.swatches');
      const $swatchAccent = $swatchMain.find('.sub:first');
      const $swatchTrim = $swatchMain.find('.sub:last');

      $designerPick.find('.info h3').html(Renoworks.ProductHandler.getProductName(pick));
      $designerPick.find('.info p').html(pick[`description${locale === 'fr' ? '_fr' : ''}`]);

      const productNames = pick.products.map(p => {
        const sidingSwatch = `url("${Renoworks.Api.product(
          `/${p.product.folder}/${p.color.swatch}`
        )}`;
        if (p.region === 'Siding Main') {
          $swatchMain.css('background-image', sidingSwatch);
        } else if (p.region === 'Siding Accent') {
          $swatchAccent.css('background-image', sidingSwatch);
        } else if (p.region === 'Trim') {
          const trimSwatch = `url("${Renoworks.Api.product(
            `/${Renoworks.ProductController.getProducts('Trim')[0].folder}/${p.color.swatch}`
          )}")`;
          $swatchTrim.css('background-image', trimSwatch);
        }

        return p.color.name;
      });

      $designerPick
        .find('.swatches')
        .data('label', productNames.join(', '))
        .end()
        .find('.apply_button')
        .on('click', ({ currentTarget }) => {
          const $this = $(currentTarget);
          const finished = Renoworks.Utils.useProgressSpinner($this);
          const history = Renoworks.ProductUndoRedo.getNewState();

          // clear any products before setting products from applied palette
          Renoworks.AnalyticsHandler.clearProductsToTrack();

          Renoworks.ProjectController.getLayers().forEach(layer => {
            const layerState = getCurrentLayerState(layer);
            layerState.id = layer.id;

            pick.products.forEach(({ region, color: { name: colorName }, product }) => {
              const layerType = layer.type.toLowerCase();
              const regionName = region.toLowerCase();
              const isWallType = layerType.includes('wall');
              const isSidingRegion = regionName.includes('siding');
              const isWindowType = layerType.includes('window');
              const isCornerTrimType = layerType.includes('corner trim');
              const isTrimRegion = regionName.includes('trim');
              const isWindowRegion = regionName.includes('windows');
              const isDoorsRegion = regionName.includes('doors');
              const isDoorsType = layerType.includes('door');
              const productType = this.getProductTypeFromName(regionName);

              if (
                layerType === regionName ||
                (isWallType && isSidingRegion) ||
                ((isWindowType || isCornerTrimType) && isTrimRegion) ||
                (isWindowType && isWindowRegion) ||
                (isDoorsType && isDoorsRegion)
              ) {
                if (isWindowType && isTrimRegion) {
                  const gridProduct = getWindowTrimGridProductData(
                    windowTrimGrid,
                    windowTrimTab,
                    colorName
                  );
                  layer.additionalProducts = [gridProduct];
                  layerState.additionalProducts = layer.additionalProducts;
                  Renoworks.ProjectController.setProductForRegion(
                    gridProduct.data,
                    gridProduct.type,
                    layer.name
                  );

                  const analyticsData = this.getProductAnalyticsInfo(
                    product,
                    gridProduct.type,
                    productType,
                    { color: colorName }
                  );
                  Renoworks.AnalyticsHandler.addProductToTrack(
                    this.getProductTypeFromName(regionName),
                    analyticsData
                  );
                } else if ((isWindowType && isWindowRegion) || (isDoorsType && isDoorsRegion)) {
                  const gridProduct = getGridProductData(product);
                  layer.product = gridProduct;
                  layerState.product = layer.product;
                  Renoworks.ProjectController.setProductForRegion(
                    gridProduct.data,
                    gridProduct.type,
                    layer.name
                  );

                  const analyticsData = this.getProductAnalyticsInfo(
                    product,
                    gridProduct.type,
                    productType,
                    { color: colorName }
                  );
                  Renoworks.AnalyticsHandler.addProductToTrack(productType, analyticsData);
                } else if (isCornerTrimType) {
                  const cornerTrimProduct = getCornerTrimProductData(cornerTrim, colorName);
                  if (cornerTrimProduct) {
                    layer.product = cornerTrimProduct;
                    layerState.product = layer.product;
                    Renoworks.ProjectController.setProductForRegion(
                      cornerTrimProduct.data,
                      cornerTrimProduct.type,
                      layer.name
                    );

                    const analyticsData = this.getProductAnalyticsInfo(
                      cornerTrim,
                      cornerTrimProduct.type,
                      productType,
                      { color: colorName }
                    );
                    Renoworks.AnalyticsHandler.addProductToTrack(productType, analyticsData);
                  }
                } else if (
                  (isWallType &&
                    layerType.indexOf('accent') !== -1 &&
                    regionName.indexOf('accent') !== -1) ||
                  (isWallType &&
                    layerType.indexOf('accent') === -1 &&
                    regionName.indexOf('accent') === -1) ||
                  !isWallType
                ) {
                  // Layertype is accent and region is accent (ie. accent layer gets accent product)
                  // OR Layertype is not accent and region is not accent (ie. main layer doesn't get accent product)
                  // OR Anything else
                  const data = `color=${colorName}||rwd=${product.rwd}||style=${product.name}`;
                  const type = 'tile';
                  layer.product = { data, type };
                  layerState.product = layer.product;
                  Renoworks.ProjectController.setProductForRegion(data, type, layer.name);

                  const analyticsData = this.getProductAnalyticsInfo(product, type, productType, {
                    color: colorName,
                  });
                  Renoworks.AnalyticsHandler.addProductToTrack(productType, analyticsData);
                }
              }
            });

            history.layers.push(layerState);
          });

          Renoworks.MaskingController.setDesignMode(true);
          // Hide masking canvas
          Renoworks.ProjectHandler.setProjectDesignMode(true);
          if (!Renoworks.mobile && Renoworks.ProjectController.getTag() !== 'sample') {
            // no need to re save the project in mobile because no masks would have been changed
            Renoworks.ProjectController.saveProject(null, true);
          }

          Renoworks.ProjectController.render().then(() => {
            Renoworks.Analytics.track(
              Renoworks.AnalyticsHandler.events.palettes.category,
              pick.name
            );
            Renoworks.AnalyticsHandler.trackAppliedProducts();
            Renoworks.ProductUndoRedo.push(history);
            finished();
          });

          this.updateProductSummary();
        });

      $designerPicks.append($designerPick.show());
    });
  };

  function loadColorFamilies(colors) {
    const $colorFamilies = $('#product_filters section.colors .filters').empty();
    const html = name =>
      `
      <div class="color_swatch_sm" data-name="${name}">
        <i class="fa fa-check" aria-hidden="true"></i>
      </div>
    `.trim();

    colors.forEach(primaryColor => {
      const { r, g, b } = Renoworks.ColorUtils.hexToRgb(primaryColor.hex);
      const contrastRatio =
        (Renoworks.ColorUtils.luminance(255, 255, 255) + 0.05) /
        (Renoworks.ColorUtils.luminance(r, g, b) + 0.05);
      const isWhite = contrastRatio <= 1.0;

      $(html(primaryColor.name))
        .css('background-color', primaryColor.hex)
        .addClass(isWhite ? 'white' : '')
        .appendTo($colorFamilies)
        .click(({ currentTarget }) => {
          const $this = $(currentTarget);
          $this.toggleClass('selected');
        });
    });
  }

  ProductHandler.prototype.showFilteredProduct = function showFilteredProduct(
    colors = [],
    products = []
  ) {
    const $accordion = $('.siding_configurator .accordion.siding');
    Renoworks.ProjectViewPage.showFilterResults();

    const displayProduct = color => {
      // Hide all but filter section and apply to section
      const html = $('.grid_cell.siding_style:first')
        .wrap('<div />')
        .parent()
        .html();
      $('.grid_cell.siding_style:first').unwrap();

      products.forEach(product => {
        const colors = color
          ? product.colors.filter(
              ({ filter }) => filter && filter.toLowerCase() === color.name.toLowerCase()
            )
          : product.colors;

        colors.forEach(color => {
          addSidingColor(product, color, html, $accordion, null, true);
        });
      });
    };

    if (products && products.length && colors && colors.length) {
      colors.forEach(c => displayProduct(c));
    } else if (products && products.length) {
      displayProduct();
    }
  };

  function loadSiding(styles) {
    if (styles && styles.length) {
      const $accordion = $('.siding_configurator .accordion.siding');
      const $gridCell = $('.grid_cell.siding_style:first');
      const html = $gridCell
        .wrap('<div />')
        .parent()
        .html();

      $('.grid_cell.siding_style:not(:first)').remove();
      $gridCell.unwrap();
      styles.forEach(s => addSidingStyle(s, html, $accordion));
    } else {
      $('.siding-header').addClass('no-products');
    }
  }

  function loadRoofing(styles) {
    if (styles && styles.length) {
      const $accordion = $('.roofing_configurator .accordion');

      $('.grid_cell.roof_style:not(:first)').remove();
      const roofingStyleHtml = $('.grid_cell.roof_style:first')
        .wrap('<div />')
        .parent()
        .html();
      $('.grid_cell.roof_style:first').unwrap();

      styles.forEach(style => addRoofingStyle(style, roofingStyleHtml, $accordion));

      // Add style tip
      $('.style_tip.roofing:not(:first)').remove();
      $('.style_tip.roofing:first')
        .clone()
        .show()
        .appendTo($('div.product_styles', $accordion)); // TODO CHANGE STYLE TIP
    } else {
      $('.roofing-header').addClass('no-products');
    }
  }

  function loadMasonry(styles) {
    if (styles && styles.length) {
      const $accordion = $('.masonry_configurator .accordion');

      $('.grid_cell.masonry_style:not(:first)').remove();
      const masonryStyleHtml = $('.grid_cell.masonry_style:first')
        .wrap('<div />')
        .parent()
        .html();
      $('.grid_cell.masonry_style:first').unwrap();

      styles.forEach(s => addMasonryStyle(s, masonryStyleHtml, $accordion));
    } else {
      $('.masonry-header').addClass('no-products');
    }
  }

  function loadTrim() {
    loadWindowTrim();
    loadCornerTrim();
    loadHouseTrim();
  }

  function loadCornerTrimColors(trim, path, $accordion) {
    const html = $('.grid_cell.trim_color:first')
      .wrap('<div />')
      .parent()
      .html();
    $('.grid_cell.trim_color:first').unwrap();

    const $trimColors = $accordion.find('div.product_colors');
    $trimColors.html('');

    const addCornerTrimColor = color => {
      const colorHtml = html.replace(
        /#PRODUCT_NAME#/g,
        Renoworks.ProductHandler.getProductName(color)
      );

      $(colorHtml)
        .find('.grid_swatch')
        .css('background-color', color.rgb)
        .end()
        .appendTo($trimColors)
        .show()
        .click(({ currentTarget }) => {
          const $this = $(currentTarget);
          $accordion.find('.product_colors .grid_cell.selected').removeClass('selected');
          $this.addClass('selected');

          trim.selectColor(color.index);

          Renoworks.ProductHandler.renderSelectedProduct({
            data: `rwd=${trim.rwd}||settings=${trim.getSettings()}`,
            type: 'path',
          });
        });
    };

    Object.values(trim.colors).forEach(color => {
      // Homeplay/Dreamdesigner have specific colors for each profile (good/better/best)
      if (Renoworks.client === 'homeplay' || Renoworks.client === 'dreamdesigner') {
        if (color.paths.includes(path.index)) {
          addCornerTrimColor(color);
        }
      } else {
        addCornerTrimColor(color);
      }
    });

    $accordion.find('h3.product_colors').trigger('click');
  }

  function loadWindowTrim() {
    const windows = Renoworks.ProductController.getProducts('Windows');
    const $accordion = $('.window_trim .accordion').empty();
    const windowTrim = windows.find(({ name }) => name.toLowerCase() === 'window trim');

    if (windows && windows.length && windowTrim) {
      $('.grid_cell.trim_color:not(:first)').remove();
      const trim_html = $('.grid_cell.trim_color:first')
        .wrap('<div />')
        .parent()
        .html();
      $('.grid_cell.trim_color:first').unwrap();

      buildGridAccordion(windowTrim, trim_html, $accordion, {
        tab: 'Window Trim',
        template: trim_html,
      });
    } else {
      $('.window-trim-header')
        .addClass('no-products')
        .next('.section-body')
        .addClass('no-products');
    }
  }

  function loadCornerTrim() {
    const $accordion = $('.corner_trim .accordion');
    const $productPaths = $accordion.find('div.product_paths');
    const trim = Renoworks.ProductController.getProducts('Corner Trim')[0];
    const html = $('.grid_cell.trim_color:first')
      .wrap('<div />')
      .parent()
      .html();
    $('.grid_cell.trim_color:first').unwrap();

    if (trim && Object.keys(trim.paths).length) {
      if (Renoworks.client === 'homeplayprime' || Renoworks.client === 'power') {
        trim.selectPath(2); // only select "best"
        $('.corner_trim .product_paths').remove();
        loadCornerTrimColors(trim, null, $accordion);
      } else {
        const $trimPaths = Object.values(trim.paths).map(path => {
          const pathHtml = html
            .replace(/#PRODUCT_NAME#/g, Renoworks.LocaleController.getValueForKey(path.name))
            .replace(
              /#PRODUCT_IMAGE#/g,
              `data/${Renoworks.client}/product/${trim.folder}/${path.thumbnail}`
            );

          return $(pathHtml)
            .show()
            .click(({ currentTarget }) => {
              const $this = $(currentTarget);
              $accordion.find('.product_paths .grid_cell.selected').removeClass('selected');
              $this.addClass('selected');

              const showColors = trim.selectPath(path.index);
              if (showColors) {
                loadCornerTrimColors(trim, path, $accordion);
              } else {
                $accordion.find('.product_colors').hide();
              }

              Renoworks.ProductHandler.closeSection($accordion, 'h3.product_paths').scrollTo(
                $accordion,
                'h3.product_colors'
              );
            });
        });

        $productPaths.append($trimPaths);
        trim.selectPath(0);
      }
    } else {
      $('.corner-trim-header')
        .addClass('no-products')
        .next('.section-body')
        .addClass('no-products');
    }
  }

  function loadHouseTrim() {
    const $accordion = $('.house_trim .accordion');
    const $trimColors = $accordion.find('div.product_colors');

    const trim = Renoworks.ProductController.getProducts('Trim');
    const html = $('.grid_cell.trim_color:first')
      .wrap('<div />')
      .parent()
      .html();
    $('.grid_cell.trim_color:first').unwrap();

    if (trim && trim.length) {
      trim.forEach(product => {
        const { colors } = product;
        colors.forEach(color => {
          const colorHtml = html
            .replace(/#PRODUCT_NAME#/g, Renoworks.ProductHandler.getProductName(color))
            .replace(
              /#PRODUCT_IMAGE#/g,
              `data/${Renoworks.client}/product/${product.folder}/${color.swatch}`
            );

          $(colorHtml)
            .appendTo($trimColors)
            .show()
            .click(({ currentTarget }) => {
              const $this = $(currentTarget);
              $accordion.find('.product_colors .grid_cell.selected').removeClass('selected');
              $this.addClass('selected');

              Renoworks.ProductHandler.renderSelectedProduct({
                data: `color=${color.name}||rwd=${product.rwd}||style=${product.name}`,
                type: 'tile',
              });
            });
        });
      });
    } else {
      $('.house-trim-header')
        .addClass('no-products')
        .next('.section-body')
        .addClass('no-products');
    }
  }

  function buildGridAccordion(grid, html, $accordion, color) {
    const accordionTitle = $('.product_accordion_wrapper:first .section-header')
      .wrap('<div />')
      .parent()
      .html();
    const accordionBody = $('.product_accordion_wrapper:first .section-body')
      .wrap('<div />')
      .parent()
      .html();
    let addedColorAccordion = false;

    if (grid) {
      grid.tabs
        .filter(({ visible }) => visible)
        .forEach(tab => {
          let tabClass = tab.name.toLowerCase().replace(/ /g, '');
          if (color && color.tab === 'Window Trim' && Renoworks.client === 'homeplayprime') {
            // For homeplay prime, don't show options for window trim, just select the best option by default and show the colors
            const options = grid.getTabOptions(tab.name);
            const option = options.find(({ name }) => name === 'Best');
            if (option) {
              grid.selectGrid(option.tab, option.grid);
            }
            tabClass += 'color';
            addColorAccordion();
            addGridColors(tab.selectedGrid.colors, tabClass, color.tab);
          } else {
            createAccordion(tab.name, tabClass, ($title, $body) => {
              $title.click(e => {
                $body.find('.grid_cell').remove();
                Renoworks.ProjectViewPage.accordionHeaderClickHandler(e, () => {
                  if (tab.name.toLowerCase().includes('color')) {
                    addGridColors(tab.selectedGrid.colors, tabClass);
                  } else {
                    const options = grid.getTabOptions(tab.name);
                    options.forEach(option => {
                      addGridOption(
                        grid,
                        option,
                        html,
                        $accordion.find(`div.${tabClass}`),
                        color,
                        $accordion
                      );
                    });
                    if (
                      tab.name === 'Glass' &&
                      options.length === 1 &&
                      options[0].name === 'No Glass'
                    ) {
                      $body.find('.grid_cell').click();
                    }
                  }
                });
              });
            });
          }
        });
    }

    if (!addedColorAccordion) {
      addColorAccordion();
    }

    function addColorAccordion() {
      if (color && grid) {
        addedColorAccordion = true;
        /**
         * For Window Trim and Doors, colors aren't a tab in the rwd's, the colors are
         * stored within a different tab for the grid object.
         * Set up the accordion structure. addGridOption will populate the colors.
         */
        createAccordion(
          'k_color',
          color.tab.toLowerCase().replace(/ /g, '') + 'color',
          ($title, _) => {
            $title.click(e => Renoworks.ProjectViewPage.accordionHeaderClickHandler(e));
          }
        );
      }
    }

    /**
     * Grid accordions are built dynamically, depending on tabs that a grid object has.
     *
     * @param title
     * @param accordionClass
     * @param cb - a callback function, with created $title and $body
     */
    function createAccordion(title, accordionClass, cb) {
      const $title = $(accordionTitle);
      const $body = $(accordionBody);

      $title.addClass(accordionClass);
      $body.addClass(accordionClass);

      const tabTitle = Renoworks.LocaleController.getValueForKey(title);

      $title
        .find('span.locale_html')
        .attr('data-locale-id', title)
        .html(tabTitle);
      $title.css('display', 'flex');

      $title.appendTo($accordion);
      $body.appendTo($accordion);

      if (cb) {
        cb(
          $accordion.find(`h3.${accordionClass}.section-header`),
          $accordion.find(`div.${accordionClass}.section-body`)
        );
      }
    }

    function addGridColors(colors, tabClass, tab) {
      colors.forEach(color => {
        addGridColor(grid, color, html, $accordion.find(`div.${tabClass}`), tab);
      });
    }
  }

  function loadDoors() {
    $('.grid_cell.door_style:not(:first)').remove();
    const product_html = $('.grid_cell.door_style')
      .first()
      .wrap('<div />')
      .parent()
      .html();
    $('.grid_cell.door_style')
      .first()
      .unwrap();

    $('.grid_cell.door_color:not(:first)').remove();
    const door_html = $('.grid_cell.door_color:first')
      .wrap('<div />')
      .parent()
      .html();
    $('.grid_cell.door_color:first').unwrap();

    var accordion = $('.door_configurator .accordion');
    $('> h3:not(.apply_to), > div:not(.apply_to)', accordion).remove();
    buildGridAccordion(
      Renoworks.ProductController.getProducts('Doors')[0],
      product_html,
      accordion,
      {
        tab: 'Configuration',
        template: door_html,
      }
    );

    // Remove first child (Configuration), it's not supposed to be used
    accordion.find('h3:first-child').remove();
  }

  function loadWindows() {
    const windows = Renoworks.ProductController.getProducts('Windows');

    if (windows && windows.length) {
      const $accordion = $('.window_configurator .accordion');
      $('.grid_cell.window_style:not(:first)').remove();
      const html = $('.grid_cell.window_style')
        .first()
        .wrap('<div />')
        .parent()
        .html();
      $('.grid_cell.window_style')
        .first()
        .unwrap();

      windows
        .filter(({ name }) => name.toLowerCase() !== 'window trim')
        .map(windowGrid => buildGridStyle(windowGrid, html, $accordion))
        .forEach(el => $('.window_product_styles').append(el));
    } else {
      $('.window-header')
        .addClass('no-products')
        .next('.section-body')
        .addClass('no-products');
    }
  }

  function loadShutters() {
    var shutters = Renoworks.ProductController.getProducts('Shutters');
    if (shutters && shutters.length) {
      var accordion = $('.shutter_configurator .accordion');
      $('.grid_cell.shutter_style:not(:first)').remove();
      var shutter_html = $('.grid_cell.shutter_style')
        .first()
        .wrap('<div />')
        .parent()
        .html();
      $('.grid_cell.shutter_style')
        .first()
        .unwrap();

      for (var i = 0; i < shutters.length; i++) {
        $('.shutter_product_styles').append(buildGridStyle(shutters[i], shutter_html, accordion));
      }
    } else {
      $('.shutter-header').addClass('no-products');
    }
  }

  /**
   * Build a single Grid Style cell
   *
   * @param {Object} grid - grid object
   * @param {string} styleHtml - html template string
   * @param $accordion - accordion where element will be stored
   * @return {jQuery} Grid style jQuery element
   */
  function buildGridStyle(grid, styleHtml, $accordion) {
    const html = styleHtml
      .replace(/#PRODUCT_NAME#/g, Renoworks.LocaleController.getValueForKey(grid.name))
      .replace(/#PRODUCT_IMAGE#/g, `data/${Renoworks.client}/product/rwds/${grid.folder}/logo.png`);

    return $(html)
      .show()
      .click(({ currentTarget }) => {
        const $this = $(currentTarget);
        $accordion.find('.grid_cell.selected').removeClass('selected');
        $this.addClass('selected');

        $accordion.find('> h3:not(:first, .apply_to), > div:not(:first, .apply_to)').remove();
        buildGridAccordion(grid, styleHtml, $accordion);

        Renoworks.ProductHandler.closeSection($accordion, 'h3.product_styles').scrollTo(
          $accordion,
          'div.product_styles + h3'
        );
      });
  }

  /**
   * Used for siding and shutters
   */
  function addGridColor(grid, color, html, $accordion, tab) {
    html = html.replace(
      /#PRODUCT_NAME#/g,
      Renoworks.LocaleController.getValueForKey(color.name) ||
        Renoworks.ProductHandler.getProductName(color)
    );

    $(html)
      .find('.grid_swatch')
      .css('background-color', '#' + color.hex)
      .end()
      .appendTo($accordion)
      .show()
      .click(({ currentTarget }) => {
        const $this = $(currentTarget);
        $('.grid_cell.selected', $accordion).removeClass('selected');
        $this.addClass('selected');

        grid.setColor(tab || 'Color', color);
        Renoworks.ProductHandler.renderSelectedProduct({
          data: `rwd=${grid.rwd}||gridSettings=${grid.getGridSettings()}`,
          type: 'grid',
        });
      });
  }

  function addGridOption(grid, option, html, $accordion, color, $topAccordion) {
    var thumbnail =
      grid.name.toLowerCase() === 'window trim'
        ? 'data/' +
          Renoworks.client +
          '/product/rwds/' +
          option.rwd +
          '/' +
          option.name.toLowerCase() +
          '.jpg'
        : option.thumbImage;
    html = html.replace(/#PRODUCT_NAME#/g, Renoworks.LocaleController.getValueForKey(option.name));
    html = html.replace(/#PRODUCT_IMAGE#/g, thumbnail);

    $accordion.append(
      $(html)
        .show()
        .addClass(option.selected ? 'selected' : '')
        .click(function() {
          $accordion.find('.grid_cell.selected').removeClass('selected');
          const $this = $(this);
          $this.addClass('selected');
          grid.selectGrid(option.tab, option.grid);
          Renoworks.ProductHandler.closeSection($accordion, $accordion.prev()).scrollTo(
            $accordion,
            $accordion.next()
          );

          if (color) {
            var colorClass = color.tab.toLowerCase().replace(/ /g, '') + 'color';
            var $accordionBody = $accordion.parent().find('div.' + colorClass);
            $accordionBody.html('');

            var options = grid.getTabOptions(color.tab);
            if (options) {
              for (var i = 0; i < options.length; i++) {
                if (options[i].selected) {
                  var colors = options[i].grid.colors;
                  colors.forEach(function(c, i) {
                    var $template = $(color.template);
                    $template.find('.grid_swatch').css('background-color', '#' + c.hex);
                    $template.attr('title', Renoworks.LocaleController.getValueForKey(c.name));

                    $template.find('label').text(Renoworks.LocaleController.getValueForKey(c.name));

                    $template.click(({ currentTarget }) => {
                      const $this = $(currentTarget);
                      $accordionBody.find('.grid_cell.selected').removeClass('selected');
                      $this.addClass('selected');
                      grid.setColor(color.tab, c);
                      Renoworks.ProductHandler.renderSelectedProduct({
                        data: `rwd=${grid.rwd}||gridSettings=${grid.getGridSettings()}`,
                        type: 'grid',
                      });
                    });

                    $accordionBody.append($template.show());
                  });
                  break;
                }
              }
            }
          }
        })
    );
  }

  function addSidingStyle(style, productHtml, $accordion) {
    const profiles = Renoworks.ProductController.getProducts('Siding,' + style.name);
    if (profiles.length > 0) {
      const html = productHtml
        .replace(
          /#PRODUCT_NAME#/g,
          Renoworks.LocaleController.getLocale() === 'en' ? style.name : style.name_fr
        )
        .replace(
          /#PRODUCT_IMAGE#/g,
          `data/${Renoworks.client}/product/thumbnails/${style.thumbnail}`
        );

      $(html)
        .appendTo($accordion.find('.siding_product_styles'))
        .show()
        .click(({ currentTarget }) => {
          const $this = $(currentTarget);
          $accordion.find('.product_styles .grid_cell.selected').removeClass('selected');
          $this.addClass('selected');

          $accordion.find('.product_profiles .grid_cell').remove();

          const sidingProfileHtml = $('.grid_cell.siding_profile:first')
            .wrap('<div />')
            .parent()
            .html();
          $('.grid_cell.siding_profile:first').unwrap();

          profiles.forEach(p => addSidingProfile(p, sidingProfileHtml, $accordion));
          Renoworks.ProductHandler.closeSection($accordion, 'h3.product_styles').scrollTo(
            $accordion,
            'h3.product_profiles'
          );
        });
    }
  }

  function addSidingProfile(sidingProfile, product_html, $accordion) {
    const html = product_html
      .replace(
        /#PRODUCT_NAME#/g,
        Renoworks.LocaleController.getLocale() === 'en' ? sidingProfile.name : sidingProfile.name_fr
      )
      .replace(
        /#PRODUCT_IMAGE#/g,
        `data/${Renoworks.client}/product/thumbnails/${sidingProfile.thumbnail}`
      );

    $(html)
      .appendTo($('div.product_profiles', $accordion))
      .show()
      .click(({ currentTarget }) => {
        const $this = $(currentTarget);

        $accordion.find('.product_profiles .grid_cell.selected').removeClass('selected');
        $this.addClass('selected');

        const colors = sidingProfile.colors;
        const sidingColorHtml = $('.grid_cell.siding_color:first')
          .wrap('<div />')
          .parent()
          .html();
        $('.grid_cell.siding_color:first').unwrap();

        $accordion.find('.product_colors .grid_cell').remove();

        colors.forEach(c => addSidingColor(sidingProfile, c, sidingColorHtml, $accordion));
        Renoworks.ProductHandler.closeSection($accordion, 'h3.product_profiles').scrollTo(
          $accordion,
          'h3.product_colors'
        );
      });
  }

  function addSidingColor(product, color, product_html, $accordion, searchResults, filterResults) {
    const html = product_html
      .replace(
        /#PRODUCT_NAME#/g,
        searchResults || filterResults
          ? Renoworks.LocaleController.getLocale() === 'en'
            ? `${color.name} - ${product.name}`
            : `${color.name_fr} - ${product.name_fr}`
          : Renoworks.LocaleController.getLocale() === 'en'
          ? color.name
          : color.name_fr
      )
      .replace(
        /#PRODUCT_IMAGE#/g,
        `data/${Renoworks.client}/product/${product.folder}/${color.swatch}`
      );

    let $container;
    if (searchResults) {
      $container = $accordion.find('div.search_results');
    } else if (filterResults) {
      $container = $accordion.find('div.filter_results');
    } else {
      $container = $accordion.find('div.product_colors');
    }

    if (!$container.prev().hasClass('open')) {
      $container.prev().click();
    }

    $(html)
      .appendTo($container)
      .show()
      .click(({ currentTarget }) => {
        const $this = $(currentTarget);

        if (searchResults) {
          $('div.search_results .grid_cell.selected', $accordion).removeClass('selected');
        } else if (filterResults) {
          $('div.filter_results .grid_cell.selected', $accordion).removeClass('selected');
        } else {
          $('.product_colors .grid_cell.selected', $accordion).removeClass('selected');
        }

        $this.addClass('selected');

        Renoworks.ProductHandler.renderSelectedProduct({
          data: `color=${color.name}||rwd=${product.rwd}||style=${product.name}`,
          type: 'tile',
        });
      });
  }

  function addRoofingStyle(style, roofindItemHtml, $accordion) {
    const styleName = Renoworks.ProductHandler.getProductName(style);
    const roofingProduct = Renoworks.ProductController.getProducts(`Roofing,${style.name}`);

    if (roofingProduct.length) {
      const html = roofindItemHtml
        .replace(/#PRODUCT_NAME#/g, styleName)
        .replace(
          /#PRODUCT_IMAGE#/g,
          `data/${Renoworks.client}/product/${roofingProduct[0].folder}/${roofingProduct[0].colors[0].swatch}`
        );

      $(html)
        .appendTo($accordion.find('div.product_styles'))
        .show()
        .click(({ currentTarget }) => {
          const $this = $(currentTarget);

          const $productColors = $accordion.find('div.product_colors');
          const $productProfiles = $accordion.find('h3.product_profiles');
          const $productProfilesSection = $productProfiles.next('.section-body');

          let nextSection;
          if (roofingProduct.length === 1) {
            // Display roofing without profiles
            const product = roofingProduct[0];

            $accordion.find('.product_styles .grid_cell.selected').removeClass('selected');
            $this.addClass('selected');
            $productColors.find('.grid_cell').remove();

            const roofingColorHtml = $('.grid_cell.roof_color:first')
              .wrap('<div />')
              .parent()
              .html();
            $('.grid_cell.roof_color:first').unwrap();

            product.colors.forEach(color =>
              addRoofingColor(product, color, roofingColorHtml, $accordion)
            );

            nextSection = 'h3.product_colors';
            if ($productProfiles.hasClass('open')) {
              $productProfiles.click().hide();
            } else {
              $productProfiles.hide();
            }
          } else {
            // Display available profiles
            $accordion.find('.product_styles .grid_cell.selected').removeClass('selected');
            $this.addClass('selected');

            $productProfilesSection.find('.grid_cell').remove();

            const roofingProfileHtml = $('.grid_cell.roof_profile:first')
              .wrap('<div />')
              .parent()
              .html();

            $('.grid_cell.roofing_profile:first').unwrap();

            roofingProduct.forEach(profile =>
              addRoofingProfile(profile, roofingProfileHtml, $accordion)
            );

            nextSection = 'h3.product_profiles';
            if (!$productProfiles.is(':visible')) {
              $productProfiles.show();
            }
            $productProfiles.click();

            if ($accordion.find('h3.product_colors').hasClass('open')) {
              $accordion.find('h3.product_colors').click();
            }
          }

          Renoworks.ProductHandler.closeSection($accordion, 'h3.product_styles').scrollTo(
            $accordion,
            nextSection
          );
        });
    }
  }

  function addRoofingProfile(roofingProfile, product_html, $accordion) {
    var html = product_html
      .replace(/#PRODUCT_NAME#/g, Renoworks.ProductHandler.getProductName(roofingProfile))
      .replace(
        /#PRODUCT_IMAGE#/g,
        'data/' +
          Renoworks.client +
          '/product/' +
          roofingProfile.folder +
          '/' +
          roofingProfile.colors[0].swatch
      );

    var $productProfiles = $accordion.find('div.product_profiles');

    $(html)
      .appendTo($productProfiles)
      .show()
      .click(({ currentTarget }) => {
        const $this = $(currentTarget);
        $('.product_profiles .grid_cell.selected', $accordion).removeClass('selected');
        $this.addClass('selected');

        var roofingColors = roofingProfile.colors;
        var roofingColorHtml = $('.grid_cell.roof_color:first')
          .wrap('<div />')
          .parent()
          .html();
        $('.grid_cell.roof_color:first').unwrap();

        $('.product_colors .grid_cell', $accordion).remove();
        for (var i = 0; i < roofingColors.length; i++) {
          addRoofingColor(roofingProfile, roofingColors[i], roofingColorHtml, $accordion);
        }

        Renoworks.ProductHandler.closeSection($accordion, 'h3.product_profiles').scrollTo(
          $accordion,
          'h3.product_colors'
        );
      });
  }

  function addMasonryStyle(style, product_html, $accordion) {
    var products = Renoworks.ProductController.getProducts('Masonry,' + style.name);
    var html = product_html.replace(
      /#PRODUCT_NAME#/g,
      Renoworks.LocaleController.getLocale() === 'en' ? style.name : style.name_fr
    );

    if (style.name === 'Stone') {
      html = html.replace(
        /#PRODUCT_IMAGE#/g,
        'data/' +
          Renoworks.client +
          '/product/' +
          products[0].folder +
          '/' +
          products[0].colors[0].swatch
      );

      $(html)
        .appendTo($('div.product_styles', $accordion))
        .show()
        .click(({ currentTarget }) => {
          const $this = $(currentTarget);

          $accordion.find('h3.product_profiles').css('display', 'flex');
          $accordion.find('.product_styles .grid_cell.selected').removeClass('selected');
          $this.addClass('selected');
          $('.product_profiles .grid_cell', $accordion).remove();

          const masonryProfileHtml = $('.grid_cell.masonry_profile:first')
            .wrap('<div />')
            .parent()
            .html();
          $('.grid_cell.masonry_profile:first').unwrap();

          products.forEach(p => addMasonryProfile(p, masonryProfileHtml, $accordion));

          Renoworks.ProductHandler.closeSection($accordion, 'h3.product_styles').scrollTo(
            $accordion,
            'h3.product_profiles'
          );
        });
    } else {
      var product = products[0];
      var masonryColors = product.colors;

      html = html.replace(
        /#PRODUCT_IMAGE#/g,
        'data/' + Renoworks.client + '/product/' + product.folder + '/' + masonryColors[0].swatch
      );

      $(html)
        .appendTo($('div.product_styles', $accordion))
        .show()
        .click(({ currentTarget }) => {
          $accordion.find('h3.product_profiles').hide();
          $accordion.find('.product_styles .grid_cell.selected').removeClass('selected');
          $(currentTarget).addClass('selected');
          $('.product_profiles .grid_cell', $accordion).remove();
          addMasonryColors(product, $accordion);

          Renoworks.ProductHandler.closeSection($accordion, 'h3.product_styles').scrollTo(
            $accordion,
            'h3.product_colors'
          );
        });
    }
  }

  function addMasonryProfile(profile, product_html, accordion) {
    var html = product_html.replace(
      /#PRODUCT_NAME#/g,
      Renoworks.LocaleController.getLocale() == 'en' ? profile.name : profile.name_fr
    );
    html = html.replace(
      /#PRODUCT_IMAGE#/g,
      'data/' + Renoworks.client + '/product/thumbnails/' + profile.thumbnail
    );

    $(html)
      .appendTo($('div.product_profiles', accordion))
      .show()
      .click(({ currentTarget }) => {
        const $this = $(currentTarget);
        addMasonryColors(profile, accordion);
      });
  }

  function addMasonryColors(product, $accordion) {
    if (product.filters.includes('Brick')) {
      if ($accordion.find('div.product_profiles').hasClass('open')) {
        $accordion
          .find('h3.product_profiles')
          .click()
          .end()
          .find('div.product_profiles')
          .html('');
      }
    }
    $('.product_styles .grid_cell.selected', $accordion).removeClass('selected');
    $(this).addClass('selected');

    $('.product_colors .grid_cell', $accordion).remove();

    var masonryColorHtml = $('.grid_cell.masonry_color:first')
      .wrap('<div />')
      .parent()
      .html();
    $('.grid_cell.masonry_color:first').unwrap();

    for (var i = 0; i < product.colors.length; i++) {
      addMasonryColor(product, product.colors[i], masonryColorHtml, $accordion);
    }

    Renoworks.ProductHandler.closeSection($accordion, 'h3.product_profiles').scrollTo(
      $accordion,
      'h3.product_colors'
    );
  }

  function addMasonryColor(product, color, product_html, $accordion) {
    var html = product_html.replace(
      /#PRODUCT_NAME#/g,
      color
        ? Renoworks.LocaleController.getLocale() == 'en'
          ? color.name
          : color.name_fr
        : Renoworks.LocaleController.getLocale() == 'en'
        ? product.name
        : product.name_fr
    );
    html = html.replace(
      /#PRODUCT_IMAGE#/g,
      'data/' + Renoworks.client + '/product/' + product.folder + '/' + color.swatch
    );

    $(html)
      .appendTo($('div.product_colors', $accordion))
      .show()
      .click(({ currentTarget }) => {
        const $this = $(currentTarget);
        $('.product_colors .grid_cell.selected', $accordion).removeClass('selected');
        $this.addClass('selected');

        Renoworks.ProductHandler.renderSelectedProduct({
          data: `color=${color.name}||rwd=${product.rwd}||style=${product.name}`,
          type: 'tile',
        });
      });
  }

  function addRoofingColor(product, color, product_html, $accordion) {
    var html = product_html.replace(
      /#PRODUCT_NAME#/g,
      color
        ? Renoworks.LocaleController.getLocale() == 'en'
          ? color.name
          : color.name_fr
        : Renoworks.LocaleController.getLocale() == 'en'
        ? product.name
        : product.name_fr
    );
    html = html.replace(
      /#PRODUCT_IMAGE#/g,
      'data/' + Renoworks.client + '/product/' + product.folder + '/' + color.swatch
    );

    $(html)
      .appendTo($('div.product_colors', $accordion))
      .show()
      .click(({ currentTarget }) => {
        const $this = $(currentTarget);
        $('.product_colors .grid_cell.selected', $accordion).removeClass('selected');
        $this.addClass('selected');

        // Show a message if there is no project set
        if (!Renoworks.ProjectController.isProjectSet()) {
          showNoProjectSetPreviewDialog();
          return;
        }

        Renoworks.ProductHandler.renderSelectedProduct({
          data: `color=${color.name}||rwd=${product.rwd}||style=${product.name}`,
          type: 'tile',
        });
      });
  }

  function displayAccordionSection($section) {
    $section.first().click();
  }

  ProductHandler.prototype.updateProductSummary = function updateProductSummary() {
    // Homeplay prime has the extra heading, we don't want to delete it
    const $productSummary =
      Renoworks.client !== 'homeplayprime'
        ? $('#product_summary .section')
        : $('#product_summary .product_summary');

    $productSummary.empty();

    // Get layers with unique names
    const uniqueLayers = Renoworks.ProjectController.getLayers().reduce(
      (arr, layer) => (arr.find(({ name }) => layer.name === name) ? arr : [...arr, layer]),
      []
    );

    // async map the layers to summary table elements
    const summaryItems = uniqueLayers
      .map(layer => {
        const regionData = Renoworks.ProjectController.getProductForRegion(layer.name);
        if (regionData) {
          let $summaryItem;
          const layerData = Renoworks.Utils.parseDataString(regionData.data);
          const layerName = layer.getName();
          const { layerType } = layer;
          const { rwd } = layerData;

          if (layerType === 'RWRegion') {
            const { color } = layerData;
            const product = Renoworks.ProductController.getProductByRWD(rwd);
            if (product) {
              const productColor = product.colors.find(
                c => c.name.toLowerCase() === color.toLowerCase()
              );
              const { data: relatedProducts } = product;

              if (productColor) {
                if (
                  Renoworks.client !== 'homeplayprime' &&
                  relatedProducts &&
                  relatedProducts.length > 0
                ) {
                  $summaryItem = buildSummaryItem(
                    product,
                    productColor,
                    layerName,
                    relatedProducts
                  );
                } else {
                  $summaryItem = buildSummaryItem(product, productColor, layerName);
                }
              }
            }
          } else if (layerType === 'RWPath') {
            const { iColor: colorIndex } = layerData;
            const cornerTrim = Renoworks.ProductController.getProductByRWD(rwd);
            const trimColor = cornerTrim.colors[colorIndex];

            if (trimColor) {
              $summaryItem = buildSummaryItem(cornerTrim, trimColor, layerName);
            }
          } else {
            const grid = Renoworks.ProductController.getProductByRWD(rwd);

            if (grid) {
              $summaryItem = buildSummaryGridItem(grid, layerName);
            }
          }

          return new Promise(resolve => resolve($summaryItem));
        }
      })
      .filter($item => $item);

    // build summary (project picks) table
    return Promise.all(summaryItems).then(items => $productSummary.append(items));
  };

  function buildSummaryItem(product, color, layerName, relatedProducts) {
    const summaryHtml = $('.summary_item:first')
      .wrap('<div />')
      .parent()
      .html();
    $('.summary_item:first').unwrap();
    const $summaryItem = $(summaryHtml);
    const $swatch = $summaryItem.find('.summary_swatch');
    const $tableBody = $summaryItem.find('tbody');

    $summaryItem.find('.summary_info h3').text(layerName);

    if (!color.swatch && color.rgb) {
      $swatch.css('background-color', color.rgb);
    } else if (color.swatch) {
      $swatch.css(
        'background-image',
        `url("${Renoworks.Api.product(`/${product.folder}/${color.swatch}`)}")`
      );
    }

    $summaryItem.find('.profile_name').text(Renoworks.ProductHandler.getProductName(product));
    $summaryItem.find('.color_name').text(Renoworks.ProductHandler.getProductName(color));

    if (Renoworks.client !== 'homeplayprime' && relatedProducts) {
      // homeplay prime has a different summary table
      relatedProducts.forEach(product => {
        const $tr = $('<tr/>');
        for (let p in product) {
          let data;
          if (
            Renoworks.client === 'homeplay' &&
            color.name === 'White' &&
            product[p] === 'Royal® Crest Double 4" Traditional' // https://renoworks.atlassian.net/browse/ROY-826
          ) {
            data = 'Royal® Crest Double 4" Vertical';
          } else {
            data = product[p];
          }

          $tr.append(`<td>${data}</td>`);
        }
        $tableBody.append($tr);
      });
    } else {
      $summaryItem.find('.summary_table').remove();
    }

    return $summaryItem.show();
  }

  function buildSummaryGridItem(grid, layerName) {
    const summaryHtml = $('.summary_item:first')
      .wrap('<p></p>')
      .parent()
      .html();
    $('.summary_item:first').unwrap();

    const $summaryItem = $(summaryHtml);
    const $summaryTable = $summaryItem.find('.summary_table table');
    const $tableHeader = $summaryTable.find('thead tr');
    const $tableContent = $summaryTable.find('tbody');
    const $row = $('<tr/>');

    const gridColors = grid.getColors();

    $summaryItem.find('.summary_table').addClass('no_preview');
    $tableHeader.empty();

    $summaryItem
      .find('.summary_swatch')
      .hide()
      .end()
      .find('.summary_info h3')
      .text(layerName);

    if (Renoworks.client === 'homeplayprime') {
      $summaryItem.css('width', '100%');
    }

    const row = $el => {
      const $tr = $('<tr/>');
      const $td = $('<td/>', { class: 'grid_summary_cell' });
      $td.append($el);
      $tr.append($td);

      return $tr;
    };

    grid.summary().forEach(({ name, value, color, image }, _, arr) => {
      const $cell = $('<td/>');
      const $summaryTable = $('<table><tbody></tbody></table>');
      const $img = $('<img/>');

      $img.attr('src', image);
      if (arr.length === 1) {
        // hack: make image smaller if there's only one summary item.
        //       needed to match other grid summary items
        $img.css('width', '5%');
      }

      $tableHeader.append(`<th>${name}</th>`);
      $summaryTable.append(row(`<p>${value}</p>`));

      const configColor = gridColors.find(c => c.name === name);
      if (color && configColor) {
        const $container = $('<div/>', { class: 'grid_summary_item' });
        const $color = $(`<p>${color}</p>`);
        const $swatch = $('<div/>', { class: 'grid_item_swatch' });

        $swatch.css('background-color', `#${configColor.selectedColor.hex}`);
        $container.append($color).append($swatch);
        $summaryTable.append(row($container));
      }

      $summaryTable.append(row($img));
      $cell.append($summaryTable);
      $row.append($cell);
    });

    $tableContent.append($row);
    return $summaryItem.show();
  }

  ProductHandler.prototype.removeProductIcon = function(e, selector) {
    e.stopPropagation();

    var swatch = selector.parent().parent();
    var layerName = swatch
      .parent()
      .parent()
      .attr('data-label');

    Renoworks.Dialog.confirm('rem_curr_prod', () => {
      Renoworks.ProjectController.clearProductForRegion(layerName);
      if (!Renoworks.mobile) {
        // no need to re save on mobile
        Renoworks.ProjectController.saveProject(null, true);
      }
      Renoworks.ProjectController.render();
      Renoworks.MaskingController.eraseRasterCanvas();
      $(
        '.layer[data-label="' +
          swatch
            .parent()
            .parent()
            .attr('data-label') +
          '"]'
      ).each(function() {
        Renoworks.ProductHandler.resetProductSwatch($(this));
      });
      //after a layer product is deleted, all other layers are minimized and should not be selected
      var layers = Renoworks.ProjectController.getLayers();
      for (var i = 0; i < layers.length; i++) {
        Renoworks.LayerHandler.setSelectedLayer(layers[i], false, true);
      }
    });
  };

  ProductHandler.prototype.searchProduct = function(string, $input) {
    const $sidingConfigurator = $('.siding_configurator');
    const $searchResults = $sidingConfigurator.find('.accordion.search_results');
    const $searchSectionHeader = $searchResults.find('h3.search_results');
    const $searchboxForm = $sidingConfigurator.find('.searchbox');
    const $clearBtn = $searchboxForm.find('.clear');

    const products = Renoworks.ProductController.getProducts('Siding');
    const results = [];
    let addColors = false;

    for (let i = 0; i < products.length; i++) {
      const product = products[i];
      if (
        this.getProductName(product)
          .toLowerCase()
          .includes(string.toLowerCase())
      ) {
        addColors = true; // if search matches a RWD, then we want to show all the colors in that tile
      }

      const colors = product.colors;
      for (let j = 0; j < colors.length; j++) {
        const color = colors[j];
        if (
          this.getProductName(color)
            .toLowerCase()
            .includes(string.toLowerCase()) ||
          addColors
        ) {
          results.push({ product, color });
        }
      }

      addColors = false;
    }

    const hideSearchResults = () => {
      $sidingConfigurator.removeClass('hasSearchResults');
    };
    const showSearchResults = () => {
      $sidingConfigurator.addClass('hasSearchResults');
      $searchResults.find('div.search_results').empty();
      $clearBtn.one('click', () => {
        $input.val('');
        hideSearchResults();
      });
    };

    if (!string || !results.length) {
      hideSearchResults();
      return;
    }
    showSearchResults();

    const html = $('.grid_cell.siding_style')
      .first()
      .wrap('<div />')
      .parent()
      .html();
    $('.grid_option.siding_style')
      .first()
      .unwrap();

    results.forEach(({ product, color }) =>
      addSidingColor(product, color, html, $searchResults, true)
    );

    if (!$searchSectionHeader.hasClass('open')) {
      $searchSectionHeader.click();
    }
  };

  ProductHandler.prototype.setProductSwatch = function(product, color, region) {
    var div = $('.layer[data-label="' + region + '"]');
    if (Renoworks.LocaleController.getLocale == 'fr') {
      if (product) {
        div
          .find('.product_name')
          .html(product.name_fr)
          .attr('title', product.name_fr);
      }
    } else {
      if (product && color) {
        div
          .find('.product_name')
          .html(product.name)
          .attr('title', product.name);
        div.find('.product_info').html(color.name);
      } else {
        //Trim, Soffit, Fascia
        div.find('.product_name').html(product.name);
        div.find('.product_info').html(product.rgb);
      }
    }

    div
      .find('.swatch')
      .addClass('product_applied')
      .children()
      .hide();
    if (product && color) {
      // tile with color
      div
        .find('.swatch')
        .css(
          'background-image',
          'url("' +
            'data/' +
            Renoworks.client +
            '/product/' +
            product.folder +
            '/' +
            color.swatch +
            '")'
        )
        .css('background-size', 'cover');
    } else {
      // just a color
      div.find('.swatch').css('background-color', product.rgb);
    }
    div.find('.delete_icon').show();
  };

  ProductHandler.prototype.resetProductSwatch = function(div) {
    if (!div) {
      div = $('.layer');
    }
    div
      .find('.product_name')
      .html('-----')
      .attr('title', '');
    div.find('.product_info').html('');
    div
      .find('.swatch')
      .css('background-image', '')
      .removeClass('product_applied');
    div
      .find('.swatch')
      .children(':not(.change_product_overlay)')
      .show();
    div.find('.delete_icon').show();
  };

  ProductHandler.prototype.redoProduct = function() {
    return Renoworks.ProductUndoRedo.redo().then(() => this.updateProductSummary());
  };

  ProductHandler.prototype.undoProduct = function() {
    return Renoworks.ProductUndoRedo.undo().then(() => this.updateProductSummary());
  };

  ProductHandler.prototype.clearProducts = function() {
    return new Promise(resolve => {
      Renoworks.ProjectController.clearSelectedProduct(
        Renoworks.ProjectController.getCurrentProject()
      );

      // Push empty state to be able to undo/redo
      Renoworks.ProductUndoRedo.push(Renoworks.ProductUndoRedo.getNewState());
      $('.btn.revert').addClass('disabled');

      Renoworks.ProjectController.render()
        .then(() => this.updateProductSummary().then(() => resolve()))
        .catch(() => resolve());
    });
  };

  /**
   * Get product name for current locale (en or fr).
   * If there's no `product.name_fr` property, the function will return `product.name`.
   *
   * @param product product object
   * @returns {string} product name for current locale
   */
  ProductHandler.prototype.getProductName = function getProductName(product) {
    if (product && (product.name || product.name_fr)) {
      const locale = Renoworks.LocaleController.getLocale();
      let { name } = product;

      if (product.name_fr === name) {
        product.name_fr = Renoworks.LocaleController.getValueForKey(name) || name;
      }

      if (locale && locale === 'fr' && product.name_fr) {
        name = product.name_fr;
      }

      return name;
    }
  };

  /**
   * Populates palettes with products from product library. This method must be called once the product
   * is defined (for example, when Renoworks.Event.PRODUCT_LOADED is triggered).
   *
   * @param {Object[]} palettes - array of palette objects, defined in designer_picks.json file
   * @returns {Object[]} modified palettes with populated products field
   */
  ProductHandler.prototype.populatePaletteProducts = function populatePaletteProducts(palettes) {
    if (!palettes || !palettes.length) {
      return;
    }

    const sidingProducts = Renoworks.ProductController.getProducts('Siding');
    const trimProducts = Renoworks.ProductController.getProducts('Trim');
    const doorProducts = Renoworks.ProductController.getProducts('Doors');
    const windowsProducts = Renoworks.ProductController.getProducts('Windows');
    const roofingProducts = Renoworks.ProductController.getProducts('Roofing');
    const shuttersProducts = Renoworks.ProductController.getProducts('Shutters');
    const cornerTrimProducts = Renoworks.ProductController.getProducts('Corner Trim');

    const mapProduct = (product, paletteProduct, region) => {
      // TODO: Replace once all palettes have been restructured
      //       to support the following structure, for example "wall": { "main": { ... }, "accent": { ... }, "painted": { ... } }
      const colorName =
        paletteProduct instanceof Object
          ? paletteProduct.color.toLowerCase()
          : paletteProduct.toLowerCase();
      const color = product.colors.find(c => c.name.toLowerCase() === colorName);

      return { product, color, region };
    };

    const mapGrid = (grid, paletteProduct, region) => {
      const colorName = paletteProduct.color.toLowerCase();
      let color = undefined;

      if (region.toLowerCase() !== 'window trim') {
        const { options } = paletteProduct;

        Object.entries(options).forEach(([name, val]) => {
          const options = grid.getTabOptions(name);
          if (options.length) {
            const option = options.find(o => o.name.toLowerCase() === val.toLowerCase());
            if (option) {
              grid.selectGrid(option.tab, option.grid);

              if (region.toLowerCase().includes('doors')) {
                // handle door color selection the same way as it's handled when selecting manually
                const configurationOptions = grid.getTabOptions('Configuration');
                configurationOptions
                  .filter(o => o.selected)
                  .forEach(o => {
                    const { colors } = o.grid;
                    if (colors && colors.length) {
                      color = colors.find(c => c.name.toLowerCase() === colorName);
                      grid.setColor('Configuration', color);
                    }
                  });
              } else {
                const colors =
                  option.grid.colors && option.grid.colors.length
                    ? option.grid.colors
                    : grid.getTabOptions('color')[0].grid.colors;
                color = colors.find(c => c.name.toLowerCase() === colorName);
                grid.setColor(option.tab.name, color);
              }
            }
          }
        });
      } else {
        let tab = grid.tabs.find(t => t.selected);
        if (!tab) {
          tab = grid.tabs[0];
        }

        color = tab.selectedGrid.colors.find(c => c.name.toLowerCase() === colorName.toLowerCase());

        grid.setColor(tab.name, color);
      }

      return { product: grid, color, region };
    };

    const mapCornerTrim = (product, paletteProduct, region) => {
      // Paths can be 0 or 2. 0 = 'Good', '2' = 'Best'
      const path =
        paletteProduct.profile ||
        Renoworks.client === 'homeplayprime' ||
        Renoworks.client === 'power'
          ? 2
          : 0;
      product.selectPath(path);
      const color = Object.values(product.colors).find(
        c => c.name.toLowerCase() === paletteProduct.color.toLowerCase()
      );
      return { color, product, region };
    };

    const productDefined = ({ color, product }) => color && product;

    const getSidingProducts = (palette, region) =>
      palette
        ? sidingProducts
            .filter(p => p.filters.includes(palette.style))
            .filter(p => p.name.toLowerCase() === palette.profile.toLowerCase())
            .map(p => mapProduct(p, palette, region))
            .filter(productDefined)
        : [];

    return palettes.map(palette => {
      let sidingMain = [];
      let sidingAccent = [];
      let paintedWall = [];
      let trim = [];

      if (
        palette.wall &&
        !(palette.mainColor || palette.mainProfile) &&
        !(palette.accentColor || palette.accentProfile)
      ) {
        const { main, accent, painted } = palette.wall;
        sidingMain = getSidingProducts(main, 'Siding Main');
        sidingAccent = getSidingProducts(accent, 'Siding Accent');
        paintedWall = getSidingProducts(painted, 'Painted Wall');
      } else if (
        palette.mainColor &&
        palette.mainProfile &&
        palette.accentColor &&
        palette.accentProfile
      ) {
        sidingMain = sidingProducts
          .filter(p => p.name.toLowerCase() === palette.mainProfile.toLowerCase())
          .map(p => mapProduct(p, palette.mainColor, 'Siding Main'))
          .filter(productDefined);

        sidingAccent = sidingProducts
          .filter(p => p.name.toLowerCase() === palette.accentProfile.toLowerCase())
          .map(p => mapProduct(p, palette.accentColor, 'Siding Accent'))
          .filter(productDefined);
      }

      if (palette.trim && !palette.trimColor) {
        const { house, window, corner } = palette.trim;
        const houseTrim = house
          ? trimProducts.map(p => mapProduct(p, house.color, 'Trim')).filter(productDefined)
          : [];

        const windowTrim = window
          ? windowsProducts
              .filter(p => p.name.toLowerCase() === 'window trim')
              .map(p => mapGrid(p, window, 'Window Trim'))
              .filter(productDefined)
          : [];

        const cornerTrim = corner
          ? cornerTrimProducts
              .map(p => mapCornerTrim(p, corner, 'Corner Trim'))
              .filter(productDefined)
          : [];

        trim = [...houseTrim, ...windowTrim, ...cornerTrim];
      } else if (palette.trimColor) {
        trim = palette.trimColor
          ? trimProducts.map(p => mapProduct(p, palette.trimColor, 'Trim')).filter(productDefined)
          : [];
      }

      const doors = palette.doors
        ? doorProducts.map(p => mapGrid(p, palette.doors, 'Doors')).filter(productDefined)
        : [];

      const windows = palette.windows
        ? windowsProducts
            .filter(p => p.name.toLowerCase() === palette.windows.style.toLowerCase())
            .map(p => mapGrid(p, palette.windows, 'Windows'))
            .filter(productDefined)
        : [];

      const roofing = palette.roofing
        ? roofingProducts
            .filter(p => p.filters.includes(palette.roofing.style))
            .filter(p => p.name.toLowerCase() === palette.roofing.profile.toLowerCase())
            .map(p => mapProduct(p, palette.roofing, 'Roofing'))
            .filter(productDefined)
        : [];

      const shutters = palette.shutters
        ? shuttersProducts
            .filter(p => p.name.toLowerCase() === palette.shutters.style.toLowerCase())
            .map(p => mapGrid(p, palette.shutters, 'Shutters'))
            .filter(productDefined)
        : [];

      palette.products = [
        ...sidingMain,
        ...sidingAccent,
        ...paintedWall,
        ...trim,
        ...doors,
        ...windows,
        ...roofing,
        ...shutters,
      ];
      return palette;
    });
  };

  ProductHandler.prototype.scrollTo = function scrollTo($accordion, targetSelector) {
    const $target =
      typeof targetSelector === 'string' ? $accordion.find(targetSelector) : targetSelector;

    if ($target.length) {
      if (!$target.next().hasClass('open')) {
        $target.click();
      }

      const $scrollContainer = $accordion.closest('.section-body[data-product]');

      const openSections = $.makeArray($scrollContainer.find('.section-body.open'));
      const scrollHeight = $scrollContainer.get(0).scrollHeight;
      const totalHeight = openSections.reduce((acc, el) => acc + $(el).height(), 0);
      const scrollTop = scrollHeight - totalHeight - $target.outerHeight(true) * 2;

      $scrollContainer.animate(
        {
          scrollTop,
        },
        {
          duration: 500,
        }
      );
    }
    return this;
  };

  ProductHandler.prototype.closeSection = function closeSection($accordion, targetSelector) {
    const $target =
      typeof targetSelector === 'string' ? $accordion.find(targetSelector) : targetSelector;

    if ($target.length && $target.next().hasClass('open')) {
      $target.click();
    }
    return this;
  };

  ProductHandler.prototype.getProductTypeFromName = function getProductTypeFromName(name) {
    const entry = Object.entries(this.productMap).find(([type, names]) =>
      names.includes(name.toLowerCase())
    );
    return entry[0];
  };

  ProductHandler.prototype.getProductAnalyticsInfo = function getProductAnalyticsInfo(
    product,
    type,
    selectedProductType = Renoworks.ProjectViewPage.selectedProductType,
    overrides = {}
  ) {
    const isWindowsTrim = product && product.name && product.name.toLowerCase() === 'window trim';
    let color = '';
    let style = '';
    let profile = '';

    try {
      if (type === 'grid') {
        if (product.tabs && product.tabs.length) {
          const selectedTabs = product.tabs.filter(({ selected }) => selected);

          if (isWindowsTrim) {
            // window trim has only one selected option
            const [tab] = selectedTabs;
            if (tab) {
              const { selectedGrid } = tab;
              if (selectedGrid) {
                // get the full name of selected window trim (e.g. "Good: J Trim" instead of "Good"), which happens to be stored in locale
                // since this is for analytics, get english version only
                profile = Renoworks.LocaleController.getValue(selectedGrid.name, 'en');
                style = product.name;
                color = selectedGrid.selectedColor.name;
              }
            }
          } else if (selectedProductType === 'door' || selectedProductType === 'window') {
            let selectedGrids = product.tabs
              .map(({ selectedGrid }) => selectedGrid)
              .filter(g => g && g.parent);

            if (selectedProductType === 'door') {
              // @HACK: specifically ignore these tabs as they are not configurable
              selectedGrids = selectedGrids.filter(
                ({ parent: { name } }) =>
                  name.toLowerCase() !== 'handles' && name.toLowerCase() !== 'configuration'
              );
            } else {
              selectedGrids = selectedGrids.filter(
                ({ parent: { name } }) => name.toLowerCase() === 'configurations'
              );
            }

            // build string of selected styles
            profile = selectedGrids.map(({ name }) => name).join(': ');
            style = product.name;
          }
        }

        if (!color && product.tabs) {
          const configTab = product.tabs.find(t => t.name.toLowerCase() === 'configuration');
          if (configTab && configTab.selectedGrid && configTab.selectedGrid.selectedColor) {
            color = configTab.selectedGrid.selectedColor.name;
          } else {
            const colorTab = product.tabs.find(t => t.name.toLowerCase() === 'color');
            color = colorTab && colorTab.selectedGrid && colorTab.selectedGrid.selectedColor.name;
          }
        }
      } else if (type === 'path') {
        color = product.currentColor ? product.currentColor.name : '';
        profile = product.currentPath ? product.currentPath.name : '';
        style = product.name;
      } else {
        const styles = Renoworks.ProductController.getCategories().find(
          ({ name }) => name.toLowerCase() === selectedProductType
        );

        if (styles && styles.categories && styles.categories.length) {
          const { categories } = styles;
          const [foundStyle] = Renoworks.Utils.intersect(
            categories.map(({ name }) => name),
            product.filters
          );
          style = foundStyle || product.name;
        }
      }

      // fallback to product name, if no style has been found/defined
      style = style || product.name;
      profile = profile || product.name;
    } catch (e) {
      console.warn('Failed to extract product information', e);
    }

    const data = { color, style, profile, brand: this.brandMap[selectedProductType] };
    return Object.assign(data, overrides);
  };
})(jQuery);
