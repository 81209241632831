/** Copyright �2013 Renoworks, Inc. All rights reserved. www.Renoworks.com
 *
 * @param {jQuery} $ A reference to the jQuery object.
 */
(function($) {
  Renoworks = window.Renoworks || {};
  Renoworks.ProjectHandler = new ProjectHandler();

  function ProjectHandler() {}

  ProjectHandler.prototype.setProjectDesignMode = function(designMode) {
    if (designMode) {
      $('.raphael').hide();
      Renoworks.MaskingController.eraseRasterCanvas();
    } else {
      $('.raphael').show();
      Renoworks.MaskingController.refresh();
    }
  };

  ProjectHandler.prototype.projectImageClickHandler = function(e, selector) {
    if (
      Renoworks.LayerHandler.layersCollapsed() &&
      !Renoworks.MaskingController.getPanning() &&
      !Renoworks.MaskingController.scaleOpen() &&
      !Renoworks.mobile
    ) {
      e.stopPropagation();
      var scale = Renoworks.MaskingController.getScale();
      var offset = selector.find('.project_canvas').offset();
      var x = Math.round((e.pageX - offset.left) / scale);
      var y = Math.round((e.pageY - offset.top) / scale);
      var layers = Renoworks.ProjectController.getLayers();
      var gutter = Renoworks.MaskingController.getGutter();
      for (var i = 0; i < layers.length; i++) {
        var p;
        if (layers[i].layerType != 'RWRegion') {
          var point = {
            x: x - gutter,
            y: y - gutter,
          };

          if (layers[i].perspective.pointInside(point)) {
            // if the click is within the polygon
            var id = layers[i].id;
            var regions = $('.layer');
            for (var k = 0; k < regions.length; k++) {
              var reg = regions[k];
              if (reg.getAttribute('data-layer-id') == id) {
                $(reg)
                  .find('.swatch .click_to_apply')
                  .click();
                $(reg).trigger('click');
                break;
              }
            }
            break;
          }
        } else {
          //p = layers[i].context.getImageData(x, y, 1, 1).data;
          var context = Renoworks.ProjectController.getCurrentProject().canvases.getMask(
            layers[i].id,
            layers[i].mask_color
          );
          if (context) {
            p = context.getImageData(x, y, 1, 1).data;
          }
          if (p[3] > 0) {
            var id = layers[i].id;
            var regions = $('.layer');
            for (var k = 0; k < regions.length; k++) {
              var reg = regions[k];
              if (reg.getAttribute('data-layer-id') == id) {
                $(reg)
                  .find('.swatch .click_to_apply')
                  .click();
                $(reg).trigger('click');
                break;
              }
            }
          }
        }
      }
    }
  };

  function filterSubnavLayers() {
    var layers = Renoworks.ProjectController.getLayers();

    var hideSubnavElement = function(type, trimTypes) {
      $('.product_list .' + type + '-header').hide();
      $('.product_list .' + type + '_configurator').hide();
      if (trimTypes && trimTypes.length) {
        trimTypes.forEach(function(t) {
          hideSubnavElement(t);
        });
      }
    };
    var showSubnavElement = function(type, trimTypes) {
      $('.product_list .' + type + '-header').show();
      $('.product_list .' + type + '_configurator').show();
      if (trimTypes && trimTypes.length) {
        trimTypes.forEach(function(t) {
          showSubnavElement(t);
        });
      }
    };
    hideSubnavElement('siding');
    hideSubnavElement('trim', ['house-trim', 'corner-trim', 'window-trim']);
    hideSubnavElement('masonry');
    hideSubnavElement('window');
    hideSubnavElement('door');
    hideSubnavElement('roofing');
    hideSubnavElement('shutter');

    var layers = Renoworks.ProjectController.getLayers();
    layers.forEach(function(layer) {
      var type = layer.type.toLowerCase();

      if (type.includes('siding')) {
        showSubnavElement('siding');
      } else if (type.includes('wall')) {
        showSubnavElement('siding');
        showSubnavElement('masonry');
        showSubnavElement('trim', ['house-trim']);
      } else if (type.includes('trim')) {
        var trims = ['house-trim'];
        if (type === 'corner trim') {
          trims.push('corner-trim');
        }
        showSubnavElement('trim', trims);
      } else if (type.includes('door')) {
        showSubnavElement('door');
      } else if (type.includes('window')) {
        showSubnavElement('window');
        showSubnavElement('trim', ['window-trim']);
      } else if (type.includes('roofing')) {
        showSubnavElement('roofing');
      } else if (type.includes('shutter')) {
        showSubnavElement('shutter');
      }
    });
  }

  ProjectHandler.prototype.buildFlagsBadge = async function buildFlagsBadge(force = false) {
    const $badge = $('#project_flags_badge').hide();
    const project = Renoworks.ProjectController.getCurrentProject();
    const { hasDS, hasAI, dsID, aiID } = await Renoworks.JavaApi.getProjectFlags(
      project.folder,
      force
    );

    const shouldShow = hasDS || hasAI;
    if (!shouldShow) return;

    const linkedID = hasAI ? dsID : aiID;
    const linkedProject = Renoworks.ProjectController.getUploads().find(p => p.folder === linkedID);
    $badge
      .find('.for_ds')
      .toggle(hasDS)
      .end()
      .find('.for_ai')
      .toggle(hasAI)
      .end()
      .find('.for_unlinked')
      .toggle(!linkedID)
      .end()
      .find('.for_linked')
      .toggle(Boolean(linkedProject?.completed))
      .end();

    if (hasAI) {
      if (linkedID) {
        // switch to linked project on link click
        $badge
          .find('.switch_project')
          .off('click.buildFlagsBadge')
          .on('click.buildFlagsBadge', () => {
            Renoworks.ProjectController.setUpload(linkedID);
          });
      } else {
        // populate UploadState with this project, so it'll be picked up in the scene_upgrade dialog
        const blob = await Renoworks.Utils.fetchBlob(Renoworks.ProjectController.getMasterImage());
        const file = new File([blob], 'master.jpg', { type: blob.type });
        Renoworks.UploadState = [
          {
            file,
            surfaces_id: project.folder,
            isAI: true,
          },
        ];
      }
    }

    $badge.show();
  };

  ProjectHandler.prototype.projectChangedEventHandler = () => {
    Renoworks.MaskingController.clearUndoRedo();
    Renoworks.ProductUndoRedo.clear(); // Clear history so we can track actions on the new project
    Renoworks.ProjectViewPage.init();

    $('#before_after_icon.selected').removeClass('selected');

    // Clear the preview canvases when the project has changed
    Renoworks.LayerViewController.clearCanvases();

    resetProductTabs();
    Renoworks.ProjectController.clearSelectedProduct();
    var $div =
      Renoworks.client !== 'homeplayprime'
        ? $('#product_summary .section')
        : $('#product_summary .product_summary');
    $div.html('<h4>' + Renoworks.LocaleController.getValueForKey('no_siding_product') + '</h4>');

    Renoworks.ProductHandler.resetAccordions();
    filterSubnavLayers();

    Renoworks.ProjectHandler.setProjectDesignMode(true);

    $('.project_image')
      .show()
      .attr('top_pos', null)
      .attr('left_pos', null);

    Renoworks.ProjectHandler.buildFlagsBadge();
  };

  ProjectHandler.prototype.projectSavedEventHandler = function(e) {
    if (Renoworks.MaskingController.isMasking()) {
      $(Renoworks.Event).trigger({
        type: Renoworks.Event.UPLOADS_UPDATED,
      });
      var html =
        '<p class="locale_html" data-locale-id="k_proj_saved">' +
        Renoworks.LocaleController.getValueForKey('k_proj_saved') +
        '</p>';
      var title = Renoworks.LocaleController.getValueForKey('succ');
      var classes = 'success';
      var buttons = [];
      var button = new Renoworks.Button();
      button.name = Renoworks.LocaleController.getValueForKey('close');
      button.classes = 'btn';
      button.callback = function() {
        $('.dialog_close:first').trigger('click');
      };
      buttons.push(button);

      Renoworks.Notify.show(html, title, classes, buttons);
    } else {
      resetProductTabs();
      $('.product_region')
        .first()
        .click();
      if (window.Layout.EXPAND_PREVIEW) {
      } else {
      }
    }

    if (Renoworks.User.loggedIn) {
      // Save upload to account
      if (!Renoworks.ProjectController.isSaved()) {
        Renoworks.User.saveUpload(Renoworks.ProjectController.getFolder(), true);
      }
      if (e.folder) {
        Renoworks.User.saveUpload(e.folder, true);
      }
    }
  };

  ProjectHandler.prototype.projectImageChangedEventHandler = function(e) {
    const { path: url, hasOverlay } = e;
    Renoworks.ProjectHandler.changeProjectImage(url, hasOverlay);
    const $img = $('.project_image');

    if (Renoworks.mobile) {
      const ratio = $img.width() / $img.height();
      $img.attr('ratio', ratio);
    }

    Renoworks.MaskingController.eraseRasterCanvas();
  };

  ProjectHandler.prototype.changeProjectImage = function changeProjectImage(
    url,
    hasOverlay = false
  ) {
    const isMasterImg = url.includes('master.jpg');
    const $projectImage = $('#project_image');
    const $beforeAfter = $('#project-preview .before-after-container');

    if (!url) {
      console.error('Missing image url');
      return;
    }

    $projectImage.css(
      'background-image',
      `url("${url}")${hasOverlay ? `,url("${Renoworks.ProjectController.getMasterImage()}")` : ''}`
    );

    if (isMasterImg) {
      window.Layout.EXPAND_PREVIEW = true;
      Renoworks.MaskingController.canZoom(true);
    }

    $('#loading').hide();
    $('.rendering').hide();

    $beforeAfter.find(isMasterImg ? '.before-wrapper.img' : '.after-wrapper .img.after').css({
      backgroundImage: `url("${url}")`,
    });
  };

  ProjectHandler.prototype.designsUpdatedEventHandler = function() {};
  ProjectHandler.prototype.uploadsUpdatedEventHandler = function() {};

  ProjectHandler.prototype.saveDesignSuccessEventHandler = function() {
    Renoworks.ProjectHandler.showSaveSuccessDialog();
  };

  ProjectHandler.prototype.loadDesign = function(design) {
    // Don't allow to mask a saved design
    if (Renoworks.MaskingController.isMasking()) {
      Renoworks.MaskingController.cancel(false);
    }

    if (design) {
      // Set product only when the project has finished loading
      $(Renoworks.Event).one(Renoworks.Event.PROJECT_CHANGED, async () => {
        let productSet = false;
        const layers = Renoworks.ProjectController.getLayers().reduce(
          (acc, layer) => Object.assign(acc, { [layer.name]: layer.id }),
          {}
        );

        if (design.palette) {
          Object.entries(design.palette)
            .filter(([name]) => layers[name])
            .forEach(([name, product]) => {
              // setProductForLayerId expects product.id, which doesn't exists
              // so use product data as id
              // results in mapping: data -> [layers that use the product]
              product.id = product.data;
              Renoworks.ProjectController.setProductForRegion(product.data, product.type, name);
              Renoworks.ProjectController.setProductForLayerId(layers[name], product);
              productSet = true;
            });
        }

        if (design.products) {
          Object.entries(design.products).forEach(([layerId, product]) => {
            Renoworks.ProjectController.setProductForLayerId(layerId, product);
            productSet = true;
          });
        }

        if (productSet) {
          const productHistoryState = Renoworks.ProductUndoRedo.getNewState();

          Object.values(Renoworks.ProjectController.getLayerProducts()).forEach(layers => {
            productHistoryState.layers.push(...layers.map(({ id, product }) => ({ id, product })));
          });

          Renoworks.ProductUndoRedo.push(productHistoryState);
          await Renoworks.ProjectController.render();
          await Renoworks.ProductHandler.updateProductSummary();
        }

        $(Renoworks.Event).trigger({
          type: Renoworks.Event.SAVED_DESIGN_LOADED,
          design: design,
        });
      });

      // Set project
      Renoworks.ProjectController.setProject(design.tag + '/' + design.folder);
    }
  };

  ProjectHandler.prototype.savedDesignLoadedEventHandler = function(e) {
    var design = e.design;
    var project = Renoworks.ProjectController.getCurrentProject();
    project.created = design.created;
    project.name = design.name;
  };

  ProjectHandler.prototype.addScene = function(project, type, container) {
    if (!type) {
      type = 'sample_project';
    }

    type = '.' + type;

    var html = $(type)
      .first()
      .wrap('<div />')
      .parent()
      .html();
    $(type)
      .first()
      .unwrap();

    html = html.replace(new RegExp('#PROJECT_NAME#', 'g'), project.name);
    var image = project.tag + '/' + project.folder + '/sample.jpg';
    if (type == '.saved_design') {
      image = project.render;
    }
    html = html.replace(
      new RegExp('#PROJECT_IMAGE#', 'g'),
      'data/' + Renoworks.client + '/projects/' + image
    );

    var div = $(html);

    // If the project name is too large, shrink the text
    if (project.name && project.name.length > 34) {
      div.find('.footer').css('font-size', '16pt');
    }
    div.show();
    if (type == '.sample_project') {
      //console.log("div: " + div.html());
      if (Renoworks.LocaleController.getLocale() == 'en') {
        div.find('label').html(project.name);
      } else if (Renoworks.LocaleController.getLocale() == 'fr') {
        div.find('label').html(project.name_fr);
      }
      div.attr('data-label', project.tag + '/' + project.folder);
      div.attr('data-analytics', project.name);
      $('#select_scene .paginator').append(div);
      if (project.layers.length != 0) {
        // some uploads are marked as sample projects, so check if they are masked
        div.find('.not_masked').show();
        div.click(':not(i)', function(e) {
          Renoworks.ProjectController.setProject($(this).attr('data-label'));
          //Renoworks.MaskingController.start(true);
        });
      } else {
        div.find('.not_masked').hide();
      }
    } else if (type == '.saved_design') {
      div.attr('data-label', project.id);
    } else if (type == '.saved_upload') {
      if (!project.masked && Renoworks.mobile) {
        div.addClass('nomasking');
      }
      div.attr('data-label', project.id);
      div.addClass('no_scale');
      $('#' + container + ' .paginator').append(div);
    }

    return div;
  };

  /**
   * Sets the background-size and background-position to appropriate px values representing contain or cover
   * Takes into account the header and sidebar and positions relative to those rather than the full window (The values set to top and left will need to be adjusted if the site layout changes; separately for both landscape and portrait)
   *
   * ****NOTE**** If masking for mobile on responsive sites is implemented this code causes the mask canvas to be misaligned from the background image.
   * 		The masking_controller currently does not have the ability to move the canvas separately from the background-image
   *
   * @param {string} size: 'cover' or 'contain'
   */
  ProjectHandler.prototype.setBackgroundSize = function(size, WINDOW_HEIGHT, WINDOW_WIDTH) {
    var buttonDiv = $('.zoom_buttons a.expand_contain');
    var orientation = WINDOW_HEIGHT / WINDOW_WIDTH > 1 ? 'portrait' : 'landscape';

    var image_url = $('.project_image')
      .css('background-image')
      .replace('url', '')
      .replace('(', '')
      .replace(')', '')
      .replace(new RegExp('"', 'g'), '');

    image = new Image();

    // just in case it is not already loaded
    $(image).load(function() {
      var bgImgWidth = image.width;
      var bgImgHeight = image.height;
      var bgRatio = bgImgWidth / bgImgHeight;

      if (size == 'contain') {
        //buttonDiv.attr('title', 'Full screen');

        var bSize = 'contain';

        if (orientation == 'landscape') {
          if (bgRatio > 1) {
            var back_pos = 'center center';
            var maxWidth = $(window).width();
            if (bgImgWidth > maxWidth) {
              bSize = maxWidth + 'px ' + maxWidth / bgRatio + 'px';
            }
          } else {
            var back_pos = 'center center';
          }
        } else {
          var top = $('header').height();
          var back_pos = 'center ' + top + 'px';

          var maxHeight = $(window).height() - $('header').height();

          if (bgImgHeight > maxHeight && bgRatio < 1) {
            bSize = maxHeight * $('.project_image').attr('ratio') + 'px ' + maxHeight + 'px';
            //console.log('peanuts');
          }

          if (bgImgHeight > bgImgWidth) {
            bSize = 'contain';
          } // blame James!
        }

        //console.log(bSize);

        $('.project_image').css({
          'background-size': bSize,
          'background-position': back_pos,
        });

        /*if(!Renoworks.mobile){
                    buttonDiv.tooltip("option", "content", 'Full screen'); //Use this instead of changing the title attribute so that the custom tooltip changes correctly
                }*/

        $('.project_image').css('cursor', 'default');
      } else if (size == 'cover') {
        /*if(!Renoworks.mobile){
                    buttonDiv.tooltip("option", "content", 'Fit to screen');//Use this instead of changing the title attribute so that the custom tooltip changes correctly
                }*/

        $('.project_image').css('background-size', 'cover');

        //We want the image to be centered and the bottom of the image to be at the bottom of the screen so that the flooring is most prominent when contained
        $('.project_image').css('background-position', 'center center');

        //$('.project_image').css('cursor', 'move');
      }
    });

    image.src = image_url;

    buttonDiv.show();
  };

  ProjectHandler.prototype.finishUploadMobile = function() {
    $(Renoworks.Event).one(Renoworks.Event.UPLOAD_SAVED, function(e) {
      var folder = e.folder;

      $('#layer_selection, #utilities, #pristine_button').hide();

      var image =
        '<div class="thumbnail" style="background-image: url(data/' +
        Renoworks.client +
        '/projects/uploaded/' +
        folder +
        '/master.jpg);"></div><div class="locale_html" data-locale-id-"no_mobile_mask">' +
        Renoworks.LocaleController.getValueForKey('no_mobile_mask') +
        '</div>';

      $('#splash').fadeIn(function() {
        var html = image;
        var title = Renoworks.LocaleController.getValueForKey('img_up');
        var classes = 'notice';
        var buttons = [];
        var button = new Renoworks.Button();
        button.name = 'Ok';
        button.classes = 'btn';
        button.callback = function() {
          Renoworks.Notify.hide();
        };
        buttons.push(button);
        Renoworks.Notify.show(html, title, classes, buttons);
        $('#project_image, #layer_selection').hide();
      });
    });

    var date = new Date();
    var name =
      date.getFullYear() +
      '-' +
      (date.getMonth() + 1) +
      '-' +
      date.getDate() +
      ' ' +
      date.getHours() +
      ':' +
      date.getMinutes();
    var project = new Renoworks.Project();
    project.name = name;
    project.folder = Renoworks.upload;
    project.tag = 'uploaded';
    project.masked = false; // meaning that when a photo is uploaded, it is assumed to be un-masked

    var url = 'data/' + Renoworks.client + '/projects/uploaded/' + Renoworks.upload + '/master.jpg';
    var img = $('<img/>')
      .css('visibility', 'hidden')
      .attr('src', url)
      .load(function() {
        project.width = $(this).get(0).width;
        project.height = $(this).get(0).height;
        project.saved = false;
        img.remove();

        Renoworks.ProjectController.addUpload(project);
        project.loaded = true;

        if (!Renoworks.User.loggedIn) {
          var html = image;
          var title = Renoworks.LocaleController.getValueForKey('log_save_up');
          var classes = 'notice';
          var buttons = [];
          var button = new Renoworks.Button();
          button.name = Renoworks.LocaleController.getValueForKey('sign');
          button.classes = 'btn';
          button.callback = function() {
            Renoworks.Notify.hide();
          };
          buttons.push(button);
          Renoworks.Notify.show(html, title, classes, buttons);
        }
        project.save(true); // save project
      });

    Renoworks.ViewController.closeModal('upload_scene');
  };

  ProjectHandler.prototype.sampleProjectClick = function(e) {
    if (Renoworks.ProjectController.getTag() == 'sample') {
      $('#loading').show();
    }
    Renoworks.ViewController.showModal('project_view');
  };

  // Replaces core Renoworks.User.saveDesign() with better implementation
  ProjectHandler.prototype.saveDesign = async function saveDesign(
    name = undefined,
    design = Renoworks.ProjectController.createDesign(),
    saveForUser = false
  ) {
    // Convert design to xml
    const xml = Renoworks.DesignController.buildDesignXml(name, design);
    const project = Renoworks.ProjectController.getCurrentProject();
    const { hasSV } = await Renoworks.JavaApi.getProjectFlags(project.folder);
    const renderPath =
      design.render === 'sample'
        ? 'sample.jpg'
        : `/images/${design.render}.${hasSV ? 'png' : 'jpg'}`;

    //Check User object for childId, so that we can save a design to a child user account. API requires logged in user to be parent of account trying to save to. Created for CAP - DB 18/01/2016
    return $.ajax({
      type: 'POST',
      url: 'Account',
      data: {
        request: `saveDesign${saveForUser ? 'ForUser' : ''}`,
        data: xml,
        render: `${design.tag}/${design.folder}/${renderPath}`,
        site: Renoworks.client,
        userId: Renoworks.User.childId,
      },
      dataType: 'text',
      success: designId => {
        if (designId.includes('ERROR')) {
          alert(designId);
        } else {
          $(Renoworks.Event).trigger(Renoworks.Event.SAVE_DESIGN_SUCCESS);
          Renoworks.User.loadDesigns(Renoworks.User.dsUser && saveForUser, design.folder);
        }
      },
      error: ({ statusText }) => {
        alert(statusText);
      },
    });
  };

  ProjectHandler.prototype.showSaveSuccessDialog = function showSaveSuccessDialog() {
    Renoworks.Dialog.alert({
      title: Renoworks.LocaleController.getValueForKey('succ'),
      body: Renoworks.LocaleController.getValueForKey('design_save'),
    });
  };

  //TODO:THis function is bunk
  function resetProductTabs() {
    var layers = Renoworks.ProjectController.getLayers();
    var hasMain = false,
      hasAccent = false,
      hasTrim = false,
      hasCeiling = false;
    for (var i = 0; i < layers.length; i++) {
      if (layers[i].name == 'Main') {
        hasMain = true;
      } else if (layers[i].name == 'Accent') {
        hasAccent = true;
      } else if (layers[i].name == 'Trim') {
        hasTrim = true;
      } else if (layers[i].name == 'Ceiling') {
        hasCeiling = true;
      }
    }
  }
})(jQuery);
