const Renoworks = window.Renoworks || {};

function Api() {}

Renoworks.Api = Api;

Api.data = (path, cache = true, client = Renoworks.client) =>
  `data/${client}${path}${!cache ? `?d=${Date.now()}` : ''}`;

Api.product = (path, cache = true, client = Renoworks.client) =>
  Api.data(`/product${path}`, cache, client);
