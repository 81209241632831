$(function() {
  'use strict';

  Renoworks = window.Renoworks || {};

  Renoworks.MultiFileUpload = new MultiFileUpload();

  function MultiFileUpload() {}

  const files = [];
  const allowedTypes = 'jpeg,jpg,png,pdf';
  const maxFiles = 6;
  const $uploadForm = $('#frmMain');
  const $input = $uploadForm.find('#files');
  const $submit = $uploadForm.find('[type="submit"]');
  const $dropZone = $uploadForm.find('.dragonDrop-dropzone');

  function getBuyflowOptions() {
    return {
      includeDS: $uploadForm.find('#include_ds').is(':checked'),
      includeAI: $uploadForm.find('#include_ai').is(':checked'),
    };
  }
  function checkValidity() {
    if (!files.length) return 'files';
    if (files.find((f, i) => files.findIndex(ff => ff.name === f.name) !== i))
      return 'duplicate_filenames';
    const { includeDS, includeAI } = getBuyflowOptions();
    if (!includeDS && !includeAI) return 'buyflow_options';
    if (!$uploadForm.find('.privacy_policy').is(':checked')) return 'privacy_policy';
    return false;
  }
  function updateValidity() {
    // sync fancy UI active-state with inner checkbox state
    const { includeDS } = getBuyflowOptions();
    $uploadForm.find('.for_ds').toggleClass('disabled', !includeDS);

    $submit.toggleClass('disabled', Boolean(checkValidity()));
  }
  $uploadForm.find(':input').on('change', () => updateValidity());

  var submissionCompleted;
  function reset() {
    $('.image_list .image .delete').trigger('click');
    $uploadForm
      .trigger('reset')
      .find('[type="checkbox"]')
      .trigger('change');
  }
  function uploadDS(filesToSend = files, formData = $uploadForm.serializeArray()) {
    const { projectSubmitted } = Renoworks.royalConfig.templateData.email;
    const locale = Renoworks.LocaleController.getLocale();
    const dataToAppend = {
      useMustache: true,
      templateData: JSON.stringify(projectSubmitted[locale]),
      to: Renoworks.User.email,
      appName: Renoworks.royalConfig.app,
      client: Renoworks.royalConfig.app,
      locale,
      saveToAccount: true,
      attachToAccount: Renoworks.User.id,
      sendConfirmationEmail: true,
      multiple: true,
    };
    Object.entries(dataToAppend).forEach(([name, value]) => formData.push({ name, value }));

    return $uploadForm.fileupload('send', {
      paramNames: [$input.attr('name')],
      files: filesToSend,
      formData,
    });
  }
  function uploadAI(file, dsID = undefined) {
    const config = {
      file,
      surfaces_id: window.Raphael.createUUID().toLowerCase(),
    };
    if (dsID) {
      config.ds_project_guid = dsID;
    }
    const request = Renoworks.JavaApi.createOrderRequest(config);
    const upload = {
      ...config,
      request,
      isAI: true,
    };
    return upload;
  }

  $uploadForm
    .fileupload({
      autoUpload: false,
      fileInput: $input,
      dropzone: $dropZone,
      singleFileUploads: false,
    })
    .on('fileuploadadd', function(e, data) {
      var failed = 0;
      data.files.forEach(function(file, i, arr) {
        const isLastFile = i === arr.length - 1;
        const fileType = file.name
          .toLowerCase()
          .split('.')
          .pop();
        if (!allowedTypes.split(',').includes(fileType)) {
          return;
        }

        var url = URL.createObjectURL(file);
        var loaded = function loaded() {
          if (fileType !== 'pdf' && this.width < 1000) {
            failed++;
          } else if (files.length < maxFiles) {
            $dropZone.addClass('has_images');
            Renoworks.AnalyticsHandler.track('uploadForm', 'openForm');

            var $prev = $(
              $('#image_template')
                .html()
                .trim()
            );
            if (fileType === 'pdf') {
              $prev.css('background-image', `url("sites/${Renoworks.client}/img/pdf.jpg")`);
            } else {
              $prev.css('background-image', `url("${url}")`);
            }

            $('.delete', $prev).click(function(event) {
              event.preventDefault();
              event.stopPropagation();

              var index = files.indexOf(file);
              if (index !== -1) {
                files.splice(index, 1);
              }

              $prev.fadeOut(function() {
                $(this).remove();
              });

              if (!files.length) {
                $dropZone.removeClass('has_images');
              }
              updateValidity();
            });

            $('.image_list', $dropZone).append($prev);

            files.push(file);
          }

          if (isLastFile && failed) {
            const isSingle = failed === 1 && arr.length === 1;
            const key = `${isSingle ? 'single' : 'multiple'}_failed_upload`;
            Renoworks.Dialog.alert({
              title: Renoworks.LocaleController.getValueForKey('uploads_failed'),
              body: Renoworks.LocaleController.getValueForKey(key),
            });
          }
          updateValidity();
        };
        if (fileType === 'pdf') {
          loaded();
        } else {
          var image = new Image();
          image.src = url;
          image.onload = loaded;
        }
      });
    })
    .on('submit', async e => {
      e.preventDefault();

      const hasErrors = checkValidity();
      if (hasErrors) {
        if (hasErrors === 'files') {
          Renoworks.Dialog.alert({
            title: Renoworks.LocaleController.getValueForKey('select_a_photo'),
            body: Renoworks.LocaleController.getValueForKey('image_length_error'),
          });
        }
        throw new Error(hasErrors);
      }

      submissionCompleted = Renoworks.Utils.useProgressSpinner($submit);
      Renoworks.UploadState = [];
      let dsIDs = {};
      const filesToUpload = [...files];
      const { includeDS, includeAI } = getBuyflowOptions();
      if (includeDS) {
        try {
          dsIDs = await uploadDS();

          // store uploads for displaying on upload_status page
          Renoworks.UploadState.push(
            ...filesToUpload.map(file => {
              const upload = {
                file,
                surfaces_id: dsIDs[file.name],
                isDS: true,
              };
              return upload;
            })
          );
        } catch (err) {
          submissionCompleted?.();

          Renoworks.Dialog.alert({
            title: Renoworks.LocaleController.getValueForKey('failed'),
            body: Renoworks.LocaleController.getValueForKey('upload_failed'),
          });

          console.error(err);
        }
      }
      if (includeAI) {
        // trigger AI request(s)
        Renoworks.UploadState.push(...filesToUpload.map(file => uploadAI(file, dsIDs[file.name])));
      }

      submissionCompleted?.();
      reset();

      Renoworks.User.loadUploads();

      Renoworks.ViewController.showModal('upload_status');
    });

  $(document)
    .on('drop dragover', function(e) {
      e.preventDefault();
    })
    .on('dragover', function(e) {
      var timeout = window.dropZoneTimeout;
      if (!timeout) {
        $dropZone.addClass('in');
      } else {
        clearTimeout(timeout);
      }
      window.dropZoneTimeout = setTimeout(function() {
        window.dropZoneTimeout = null;
        $dropZone.removeClass('in hover');
      }, 100);

      $dropZone.toggleClass('hover', Boolean($(e.target).closest($dropZone).length));
    });

  MultiFileUpload.prototype.uploadDS = uploadDS;
  MultiFileUpload.prototype.uploadAI = uploadAI;
});
