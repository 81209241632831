const { $, Renoworks } = window;

class RenderingEngine {
  /**
   * Render a project. Rendering Engine version is determined based on project flags.
   *
   * NOTE: Overrides ProjectController.render behaviour
   *
   * @param project, project object to render, defaults to current project
   * @returns {Promise<string>|Promise<Error>}, promise that resolves to a rendered image path or throws an error
   */
  async render(project = Renoworks.ProjectController.getCurrentProject()) {
    if (Renoworks.mobile) {
      $('#loading, .loading').show();
    }

    if (!project) {
      throw new Error('Project is required to render');
    }

    return new Promise(async (resolve, reject) => {
      const reVersion = '2';
      const { hasSV } = await Renoworks.JavaApi.getProjectFlags(project.folder);
      $.ajax({
        type: 'POST',
        url: 'RenderProject',
        data: {
          xml: this.getProjectXmlString(project),
          folder: project.folder,
          tag: project.tag,
          width: this.getRenderWidth(project),
          site: Renoworks.client,
          java: false,
          cache: false,
          skipShadows: reVersion === '2',
          cachePath: project.cacheFolder,
          hasSV,
          reVersion,
        },
        success(data) {
          // This is to account for RE1 vs. RE2 rendering:
          //   - RE1 returns a path to generated image.
          //   - RE2 returns a JSON object with image path and que file
          const imgPath = typeof data === 'object' ? data.imgPath : data;
          const path = `data/${Renoworks.client}/${imgPath}`;

          Renoworks.ProjectController.setRenderPath(path);
          Renoworks.ViewController.setRenderedImage(true);

          project.render = path.substring(path.lastIndexOf('/'), path.lastIndexOf('.'));
          project.renderedImage = imgPath;

          $(Renoworks.Event).trigger({
            type: Renoworks.Event.PROJECT_IMAGE_CHANGED,
            path: path,
            hasOverlay: hasSV,
          });

          resolve(imgPath);
        },
        error: err => reject(err),
      });
    });
  }

  getRenderWidth(project) {
    // Set the render width based on the image dimensions
    let { width: renderWidth } = project;

    // see email: Artistic Tiles - Rendering Engine Stops Working
    if (
      Renoworks.ProjectController.getProjectHeight() > Renoworks.ProjectController.getProjectWidth()
    ) {
      renderWidth = Math.floor(
        renderWidth *
          (Renoworks.ProjectController.getProjectWidth() /
            Renoworks.ProjectController.getProjectHeight())
      );
    }

    return renderWidth;
  }

  getProjectXmlString(project) {
    const { xml: $xml } = Renoworks.ProjectController.generateRenderXML(project);

    return window.XMLSerializer
      ? new XMLSerializer().serializeToString($xml.get(0))
      : $xml.get(0).xml;
  }
}

Renoworks.RenderingEngine = new RenderingEngine();
