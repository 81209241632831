const { $, Renoworks } = window;

function Dialog() {}

Renoworks.Dialog = Dialog;

function setText($dialog, title = '', body = '') {
  $dialog.find('.title').html(title);
  $dialog.find('.body').html(body);
}

function setupClickHandler($dialog, onClose) {
  const close = () => {
    if (onClose) {
      onClose();
    }

    Renoworks.ViewController.closeModal($dialog.attr('id'));
  };

  $dialog.find('.footer .btn, .close').click(close);
}

Dialog.alert = ({ title, body, onClose }) => {
  const dialogName = 'royal_alert_dialog';
  const $dialog = $(`#${dialogName}`);
  setupClickHandler($dialog, onClose);
  setText($dialog, title, body);
  Renoworks.ViewController.showModal(dialogName);
};

Dialog.confirm = (key, onConfirm = undefined, onCancel = undefined) => {
  const html = `<p class="locale_html" data-locale-id="${key}">
    ${Renoworks.LocaleController.getValueForKey(key)}
  </p>`;
  const title = Renoworks.LocaleController.getValueForKey('please_confirm');
  const classes = 'notice';
  const buttons = [];

  const yesButton = new Renoworks.Button();
  yesButton.name = Renoworks.LocaleController.getValueForKey('yes');
  yesButton.classes = 'btn';
  yesButton.callback = async () => {
    await onConfirm?.();
    Renoworks.Notify.hide();
  };
  buttons.push(yesButton);

  const noButton = new Renoworks.Button();
  noButton.name = Renoworks.LocaleController.getValueForKey('no');
  noButton.classes = 'btn';
  noButton.callback = async () => {
    await onCancel?.();
    Renoworks.Notify.hide();
  };
  buttons.push(noButton);

  Renoworks.Notify.show(html, title, classes, buttons);
};
