(function($) {
  'use strict';

  Renoworks = window.Renoworks || {};
  Renoworks.ProductUndoRedo = new ProductUndoRedo();

  function ProductUndoRedo() {
    this.stack = [];
    this.stackIndex = -1;
  }

  /**
   * Applies the saved state of products to the project
   */
  function apply(state) {
    return new Promise(resolve => {
      Renoworks.ProjectController.clearSelectedProduct(
        Renoworks.ProjectController.getCurrentProject()
      );

      if (state) {
        state.layers.forEach(({ id, product, additionalProducts }) => {
          const layer = Renoworks.ProjectController.getLayerByID(id);

          if (product) {
            layer.product = product;
            Renoworks.ProjectController.setProductForRegion(product.data, product.type, layer.name);
          }

          if (additionalProducts) {
            layer.additionalProducts = additionalProducts;
          }
        });
      }

      Renoworks.ProjectController.render().finally(() => resolve());
    });
  }

  ProductUndoRedo.prototype.getNewState = function() {
    return {
      layers: [],
    };
  };

  ProductUndoRedo.prototype.undo = function() {
    return new Promise(resolve => {
      if (this.stackIndex <= 0) {
        this.stackIndex = -1;
        // At beginning of history, so we can remove the products entirely
        apply(null).then(() => {
          $(Renoworks.Event).trigger(Renoworks.Event.PRODUCT_UNDO_UNAVAILABLE);
          $(Renoworks.Event).trigger(Renoworks.Event.PRODUCT_REDO_AVAILABLE);
          resolve();
        });
      } else {
        this.stackIndex--;
        apply(this.stack[this.stackIndex]).then(() => {
          $(Renoworks.Event).trigger(Renoworks.Event.PRODUCT_UNDO_AVAILABLE);
          $(Renoworks.Event).trigger(Renoworks.Event.PRODUCT_REDO_AVAILABLE);
          resolve();
        });
      }
    });
  };

  ProductUndoRedo.prototype.redo = function() {
    return new Promise(resolve => {
      if (this.stackIndex >= this.stack.length - 1) {
        resolve();
        return;
      }

      this.stackIndex++;
      apply(this.stack[this.stackIndex]).then(() => {
        if (this.stackIndex === this.stack.length - 1) {
          $(Renoworks.Event).trigger(Renoworks.Event.PRODUCT_REDO_UNAVAILABLE);
        }

        $(Renoworks.Event).trigger(Renoworks.Event.PRODUCT_UNDO_AVAILABLE);
        resolve();
      });
    });
  };

  /**
   * Used to track the state of the project products at every product appliance action
   */
  ProductUndoRedo.prototype.push = function(state) {
    this.stackIndex++;

    // Make sure there is no future in the history list.
    // Ex) if the user applies a product, clicks undo, and
    // then applies something else, we need to dispose of
    // the future state
    this.stack.splice(this.stackIndex);
    $(Renoworks.Event).trigger(Renoworks.Event.PRODUCT_REDO_UNAVAILABLE);

    if (this.stackIndex >= 0) {
      $(Renoworks.Event).trigger(Renoworks.Event.PRODUCT_UNDO_AVAILABLE);
    }

    this.stack[this.stackIndex] = state;
  };

  ProductUndoRedo.prototype.clear = function() {
    this.stack = [];
    this.stackIndex = -1;

    $(Renoworks.Event).trigger(Renoworks.Event.PRODUCT_REDO_UNAVAILABLE);
    $(Renoworks.Event).trigger(Renoworks.Event.PRODUCT_UNDO_UNAVAILABLE);
  };
})(jQuery);
