/** Copyright �2013 Renoworks, Inc. All rights reserved. www.Renoworks.com
 *
 * @param {jQuery} $ A reference to the jQuery object.
 */
(function($) {
  var Renoworks = window.Renoworks || {};

  function DesignReport() {}

  Renoworks.DesignReport = new DesignReport();

  DesignReport.prototype.printDesignReportButtonClickHandler = function printDesignReportButtonClickHandler() {
    const host = window.location.origin + window.location.pathname;
    const targetUrl = `${host}/design/loading.html`;
    const downloadWindow = window.open(targetUrl, '_blank');
    const projectJson = Renoworks.ShareUpdate.createShareObject().map(project => {
      project.pdf = true;
      return project;
    });

    const { client: site } = Renoworks;
    const locale = Renoworks.LocaleController.getLocale();
    const data = {
      site,
      locale,
      json: encodeURI(JSON.stringify(projectJson)),
    };

    $.post('ShareProject', data).done(function(id) {
      const pdfOptions = {
        htmlViewerWidth: 700,
        pageSize: 'Letter',
        url: `${host}/designReportV2/?id=${id}&site=${site}&locale=${locale}`,
      };

      $.post('HtmlToPdf', {
        site,
        json: encodeURI(JSON.stringify(pdfOptions)),
      }).done(function(pdfLocation) {
        downloadWindow.location.href = pdfLocation.split('\\').join('/');
      });
    });
  };
})(jQuery);
