/** Copyright �2013 Renoworks, Inc. All rights reserved. www.Renoworks.com
 *
 * @param {jQuery} $ A reference to the jQuery object.
 */
(function($) {
  Renoworks = window.Renoworks || {};
  Renoworks.ShareUpdate = new ShareUpdate();

  function ShareUpdate() {}
  Renoworks.emailOnce = true;

  ShareUpdate.prototype.createShareObject = function() {
    const project = Renoworks.ProjectController.getCurrentProject();

    const jsonProject = {
      name: project.name,
      render: project.renderedImage,
      link: window.location.host,
      master: project.tag + '/' + project.folder,
      created: project.created
        ? Renoworks.Util.formatDate(new Date(project.created))
        : Renoworks.Util.formatDate(new Date()),
      products: [],
    };

    const layers = Renoworks.ProjectController.getLayers();
    const linkProducts = [];

    layers
      .filter(({ name }) => Renoworks.ProjectController.getProductForRegion(name))
      .forEach(layer => {
        const p = Renoworks.ProjectController.getProductForRegion(layer.name);
        const { data } = p;

        // This is for constructing the design report data
        const sharedProduct = {
          layerName: Renoworks.ProductHandler.getProductName(layer),
          type: layer.type,
          renderedImage: '',
        };

        // This is for making the design link from the design report back to the app, with product applied
        const sharedLayer = {
          name: layer.name,
          type: layer.type,
          product: {},
        };

        // Grids
        if (layer.type === 'Window' || layer.type === 'Shutter' || layer.type === 'Door') {
          const { rwd, gridSettings } = Renoworks.Utils.parseDataString(data);
          const grid = Renoworks.ProductController.getProductByRWD(rwd);

          if (grid) {
            grid.selectByGridSettings(gridSettings);

            sharedLayer.product.rwd = rwd;
            sharedLayer.product.gridSettings = gridSettings;

            sharedProduct.product = Renoworks.LocaleController.getValueForKey(grid.name);

            if (!grid.renderedImage) {
              // Render grid synchronously
              grid.render(null, 400, 400, null, true);
            }

            sharedProduct.renderedImage = normalizePath(grid.renderedImage);

            if (layer.type === 'Window' || layer.type === 'Shutter') {
              const colorTab = grid.getSelectedGridForTab('Color');
              if (colorTab && colorTab.selectedColor) {
                sharedProduct.color = {
                  name: Renoworks.LocaleController.getValueForKey(colorTab.selectedColor.name),
                };

                sharedLayer.product.color = grid.getSelectedGridForTab('Color').selectedColor.name;
              }

              if (layer.type === 'Window' && layer.additionalProducts.length) {
                const windowTrimProduct = layer.additionalProducts
                  .map(({ data: windowProductData }) => {
                    const { rwd } = Renoworks.Utils.parseDataString(windowProductData);
                    return Renoworks.ProductController.getProductByRWD(rwd);
                  })
                  .find(product => product && product.name.toLowerCase() === 'window trim');

                if (windowTrimProduct) {
                  const windowTrimTab = windowTrimProduct.getSelectedGridForTab('Window Trim');
                  if (windowTrimTab) {
                    sharedProduct.trimColor = windowTrimTab.selectedColor.name;
                  }
                }
              }
            } else {
              sharedProduct.color = {
                name: Renoworks.LocaleController.getValueForKey(
                  grid.getSelectedGridForTab('Configuration').selectedColor.name
                ),
              };
              sharedLayer.product.color = grid.getSelectedGridForTab(
                'Configuration'
              ).selectedColor.name;
            }
          }
        } else {
          if (layer.layerType === 'RWPath') {
            const parsedData = Renoworks.Utils.parseDataString(data);
            const { rwd, settings } = parsedData;
            if (rwd && settings) {
              const { iColor, iPath } = Renoworks.Utils.deparam(settings);
              const cornerTrimProduct = Renoworks.ProductController.getProducts('Corner Trim')[0];

              if (cornerTrimProduct) {
                cornerTrimProduct.folder = normalizePath(cornerTrimProduct.folder);
                cornerTrimProduct.selectPath(iPath);
                cornerTrimProduct.selectColor(iColor);

                sharedLayer.product.rwd = rwd;
                sharedLayer.product.iPath = iPath;
                sharedLayer.product.iColor = iColor;

                sharedProduct.product = {
                  name: Renoworks.ProductHandler.getProductName(cornerTrimProduct.getPath()),
                  folder: cornerTrimProduct.folder,
                };

                sharedProduct.color = {
                  name: Renoworks.ProductHandler.getProductName(cornerTrimProduct.getColor()),
                  swatch: cornerTrimProduct.getPath().thumbnail,
                };
              }
            }
          } else {
            // Tiles
            const { color, rwd, style } = Renoworks.Utils.parseDataString(data);
            const product = Renoworks.ProductController.getProductByRWD(rwd);

            if (product && product.colors) {
              const productColor = product.colors.find(
                ({ name }) => name.toLowerCase() === color.toLowerCase()
              );
              if (productColor) {
                product.folder = normalizePath(product.folder);
                sharedLayer.product.rwd = rwd;
                sharedLayer.product.color = color;

                sharedProduct.product = {
                  name: Renoworks.ProductHandler.getProductName(product),
                  folder: product.folder,
                };

                sharedProduct.color = {
                  name: Renoworks.ProductHandler.getProductName(productColor),
                  swatch: normalizePath(productColor.swatch),
                };
                sharedProduct.renderedImage = `data/${Renoworks.client}/product/${product.folder}/${productColor.swatch}`;

                if (
                  layer.type.toLowerCase().indexOf('siding') !== -1 ||
                  product.folder.toLowerCase().indexOf('siding') !== -1
                ) {
                  sharedProduct.relatedProducts = product.data;
                }
              }
            }
          }
        }

        jsonProject.products.push(sharedProduct);
        linkProducts.push(sharedLayer);
      });

    const { lastSavedDesign: design } = Renoworks.DesignController;
    jsonProject.link += `?project=${project.tag}/${project.folder}${
      design ? `&design=${design.id}` : ''
    }`;

    return [jsonProject];
  };

  ShareUpdate.prototype.doShare = function(type, email, id, replyTo, message) {
    var project, render, product;
    if (id) {
      // Design ID
      var design = Renoworks.User.getDesign(id);
      if (design) {
        project = design.tag + '/' + design.folder;
        render = design.render;

        var products = [];
        for (var key in design.palette) {
          design.palette[key].name = key;
          products.push(design.palette[key]);
        }
      }
    } else if (
      Renoworks.ProjectController.isProjectSet() &&
      Renoworks.ProjectController.isDesigned()
    ) {
      project = Renoworks.ProjectController.getProjectName();
      render = project + '/images' + Renoworks.ProjectController.getProjectRender() + '.jpg';
      var products = Renoworks.ProjectController.getProduct();
    }
    if (products) {
      return shareProduct(type, email, project, render, this.createShareObject(), replyTo, message);
    } else {
      Renoworks.Notify.notDesigned();
      return Promise.reject();
    }
  };

  function shareProduct(type, email, project, render, jsonProject, replyTo, message) {
    if (project) {
      const loadingUrl = `${window.location.pathname || '/'}/design/loading.html`.replace(
        '//',
        '/'
      );
      const shareWindow = type !== 'email' && window.open(loadingUrl, '_blank');
      const description = Renoworks.LocaleController.getValueForKey('check_des'); // TODO - load this from client

      return $.ajax({
        type: 'POST',
        url: 'ShareProject',
        data: {
          site: Renoworks.client,
          json: encodeURI(JSON.stringify(jsonProject)),
          locale: Renoworks.LocaleController.getLocale(),
        },
        success: data => {
          const { host } = window.location;
          const designReportUrl = `${host}/designReportV2/?id=${data}&site=${
            Renoworks.client
          }&locale=${Renoworks.LocaleController.getLocale()}`;

          if (type === 'pinterest') {
            const params = {
              url: designReportUrl,
              media: `${host}/data/${Renoworks.client}/projects/${render}`,
              description: description,
            };
            Renoworks.AnalyticsHandler.trackShare('pinterest');
            shareWindow.location.href = `http://pinterest.com/pin/create/button/?${$.param(
              params
            )}`;
          } else if (type === 'facebook') {
            const params = {
              u: designReportUrl,
            };
            Renoworks.AnalyticsHandler.trackShare('facebook');
            shareWindow.location.href = `http://www.facebook.com/sharer.php?${$.param(params)}`;
          } else if (type === 'twitter') {
            const params = {
              url: designReportUrl,
              text: description,
            };
            Renoworks.AnalyticsHandler.trackShare('twitter');
            shareWindow.location.href = `http://www.twitter.com/intent/tweet/?${$.param(params)}`;
          } else if (type === 'google') {
            const params = {
              url: designReportUrl,
            };

            shareWindow.location.href = `http://plus.google.com/share?${$.param(params)}`;
          } else if (type === 'email') {
            if (Renoworks.emailOnce) {
              Renoworks.emailOnce = false;
              $('#loading, .loading').show();
              $.ajax({
                type: 'POST',
                url: 'EmailProject',
                data: {
                  site: Renoworks.client.toLowerCase(),
                  id: data,
                  email: email,
                  locale: Renoworks.LocaleController.getLocale(),
                  replyTo: replyTo,
                  message: message,
                },
                success: function(resp) {
                  $(Renoworks.Event).trigger({
                    type: Renoworks.Event.EMAIL_TO_FRIEND_SUCCESS,
                  });
                },
              });
            }
          } else if (type === 'print') {
            Renoworks.Analytics.track('share', 'print');
            shareWindow.location.href = `${designReportUrl}&print=true`;
          }
        },
      });
    } else {
      if (!Renoworks.ProjectController.isProjectSet()) {
        var html =
          '<p class="locale_html" data-locale-id="choose_proj">' +
          Renoworks.LocaleController.getValueForKey('choose_proj') +
          '</p>';
        var title =
          '<div class="locale_html" data-locale-id="share">' +
          Renoworks.LocaleController.getValueForKey('share') +
          '</div>';
        var classes = 'notice';
        var buttons = [];
        var button = new Renoworks.Button();
        button.name = 'Ok';
        button.classes = 'btn';
        button.callback = function() {
          $('.modal').click();
        };
        buttons.push(button);
        Renoworks.Notify.show(html, title, classes, buttons);
      } else if (!Renoworks.ProjectController.isDesigned()) {
        Renoworks.Notify.notDesigned();
      }
    }
  }

  ShareUpdate.prototype.createProductString = function(products) {
    var layers = Renoworks.ProjectController.getLayers();
    var productStrings = [];

    var rendered = 0,
      toRender = 0;
    // Add each product to the productString
    var complete = false;
    for (var i = 0; i < products.length; i++) {
      var productString = '';
      var product = products[i];
      var attributes = product.data.split('||');
      var attrStart = false;
      // Add each attribute of the product to the productString
      for (var j = 0; j < attributes.length; j++) {
        var attribute = attributes[j].split('=');
        var key = attribute[0];
        var value = attribute[1];
        product[key] = value;
      }

      if (product.type != 'color') {
        // colors don't belong to any rwd's

        var rwdName = product.rwd.split('/')[0];
        if (rwdName == 'Siding') {
          //use the product.name to distinguish between main and siding and set that as the type
          var sidingName = '';
          if (product.name) {
            sidingName = product.name.substring(product.name.indexOf('Siding'));
          } else if (product.layerName) {
            sidingName = product.layerName.substring(product.layerName.indexOf('Siding'));
          }

          productString += 'productType=' + sidingName + '||';
        } else {
          productString += 'productType=' + product.rwd.split('/')[0] + '||';
        }
      } else {
        var productName = product.name;
        var type;
        for (var p = 0; p < layers.length; p++) {
          if (layers[p].name == productName) {
            type = layers[p].type;
            break;
          }
        }

        productString += 'productType=' + type + '||';
      }

      if (product.layerName) {
        if (Renoworks.ProjectController.getTag() == 'sample') {
          productString +=
            'layerName=' +
            Renoworks.LocaleController.getValueForKey(
              product.layerName.toLowerCase().replace(' ', '_')
            ) +
            '||';
        } else {
          productString += 'layerName=' + product.layerName + '||';
        }
      } else if (product.name) {
        productString += 'layerName=' + product.name + '||';
      }

      // Add the product rendered image to the product string
      if (product.type == 'grid') {
        var grid = Renoworks.ProductController.getProductByRWD(product.rwd);
        grid.selectByGridSettings(product.gridSettings);
        grid.renderedImage = normalizePath(grid.renderedImage);
        if (Renoworks.LocaleController.getLocale() == 'fr') {
          productString +=
            grid.getData() +
            '||productImage=' +
            grid.renderedImage +
            '||productName (FR)=' +
            grid.name_fr;
        } else {
          productString +=
            grid.getData() + '||productImage=' + grid.renderedImage + '||productName=' + grid.name;
        }
      }
      // Add the swatch thumbnail if the product is not multigrid
      else if (product.type == 'tile') {
        // Go through the product getting the building the string
        productString += product.data;

        // Get the swatch path for the product
        var tileProduct = Renoworks.ProductController.getProductByRWD(product.rwd);
        if (Renoworks.LocaleController.getLocale() == 'fr') {
          productString += '||style=' + tileProduct.name_fr;
        } else {
          productString += '||style=' + tileProduct.name;
        }

        var swatch = tileProduct.getSwatchByColor(product.color);
        var swatchPath =
          'data/' + Renoworks.client + '/product/' + tileProduct.folder + '/' + swatch;
        productString += '||productImage=' + swatchPath;
      } else if (product.type == 'color') {
        productString += '||Color=#' + product.data; // product.data is the hex value of the color
        productString += '||productImage=#' + product.data; // hex value handled on design report page
      }

      productStrings[i] = productString;
    }

    productString = '';
    // Concatenate all the product strings
    var started = false;
    for (var key in productStrings) {
      if (started) {
        productString += ',,';
      }
      productString += productStrings[key];
      started = true;
    }

    return productString;
  };

  /**
   * Copied from gridObject to avoid events so print wouldn't be blocked.  TODO - core code should be modified
   * to handle this case
   */
  ShareUpdate.prototype.renderGridSync = function(grid, projectPpf, maxWidth, maxHeight) {
    var rWidth = grid.width,
      rHeight = grid.height;
    // Constrain width/height
    if (maxWidth != null) {
      if (maxHeight == null) {
        maxHeight = maxWidth;
      }
      var ratio = grid.width / grid.height;
      if (ratio * maxHeight > maxWidth) {
        rWidth = maxWidth;
        rHeight = maxWidth / ratio;
      } else {
        rHeight = maxHeight;
        rWidth = maxHeight * ratio;
      }
    }

    // Create grid settings
    var gridSettings = '';
    for (var i = 0; i < grid.tabs.length; i++) {
      if (grid.tabs[i].selectedGrid) {
        gridSettings += grid.tabs[i].selectedGrid.guid;
        if (grid.tabs[i].selectedGrid.selectedColor) {
          gridSettings += ',' + grid.tabs[i].selectedGrid.selectedColor.guid;
        }
      }
      if (i < grid.tabs.length - 1) {
        gridSettings += ',';
      }
    }

    // Default ppf
    if (!projectPpf) {
      projectPpf = 80;
    }

    // Render
    var renderedImage = '';
    $.ajax({
      type: 'POST',
      url: 'RenderGrid',
      data: {
        rwd: grid.rwd,
        gridSettings: gridSettings,
        width: parseInt(rWidth),
        height: parseInt(rHeight),
        site: Renoworks.client,
        ppf: projectPpf,
        //cache: 'true'
      },
      async: false,
      success: function(data) {
        if (data) {
          renderedImage = 'data/' + Renoworks.client + '/' + data;
          grid.renderedImage = renderedImage;
        }
      },
    });

    return renderedImage;
  };

  function normalizePath(path) {
    return decodeURIComponent(path || '').replace(/\/\//g, '/');
  }
})(jQuery);
