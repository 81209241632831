/** Copyright �2013 Renoworks, Inc. All rights reserved. www.Renoworks.com
 *
 * @param {jQuery} $ A reference to the jQuery object.
 */
(function($) {
  Renoworks = window.Renoworks || {};
  Renoworks.CanvasHandler = new CanvasHandler();

  /**
   * @class CanvasHandler
   * @constructor
   */
  function CanvasHandler() {}

  /**
   * Sets up panning on the project image
   */
  CanvasHandler.prototype.setupImagePan = function() {
    var startX, startY;
    $('#background').on('mousedown', '.project_image', function(e) {
      if (!Renoworks.MaskingController.isMasking() && EXPAND_PREVIEW) {
        startX = e.pageX;
        startY = e.pageY;

        $(window).bind('mousemove', function(e) {
          var moveX = e.pageX - startX;
          var moveY = e.pageY - startY;

          var size = $('.project_image').css('background-size');
          var split = size.split(' ');
          var width = 0;
          if (split[0].trim().indexOf('px') != -1) {
            width = split[0].trim().substring(0, split[0].trim().indexOf('px'));
          }
          width = parseInt(width);
          if (!width) {
            width = 0;
          }
          var height = 0;
          if (split[1].trim().indexOf('px') != -1) {
            height = split[1].trim().substring(0, split[1].trim().indexOf('px'));
          }
          height = parseInt(height);
          if (!height) {
            height = 0;
          }

          var pos = $('.project_image').css('background-position');
          var split = pos.split(' ');
          var left = 0;
          if (split[0].trim().indexOf('px') != -1) {
            left = split[0].trim().substring(0, split[0].trim().indexOf('px'));
          }
          left = parseInt(left);
          if (!left) {
            left = 0;
          }
          var top = 0;
          if (split[1].trim().indexOf('px') != -1) {
            top = split[1].trim().substring(0, split[1].trim().indexOf('px'));
          }
          top = parseInt(top);
          if (!top) {
            top = 0;
          }

          // Keep image in view
          var newLeft = left + moveX;
          var newTop = top + moveY;
          if (newLeft > 0) {
            newLeft = 0;
          }
          if (width > window.Layout.WINDOW_WIDTH) {
            if (newLeft < -(width - window.Layout.WINDOW_WIDTH)) {
              newLeft = -(width - window.Layout.WINDOW_WIDTH);
            }
          } else {
            newLeft = 0;
          }
          var topMin = $('header').height();
          topMin = parseInt(topMin);
          if (newTop > topMin) {
            newTop = topMin;
          }

          if (height > window.Layout.WINDOW_HEIGHT - topMin) {
            if (newTop <= -(height - (window.Layout.WINDOW_HEIGHT - topMin))) {
              newTop = -(height - window.Layout.WINDOW_HEIGHT);
            }
          } else {
            newTop = topMin;
          }

          $('.project_image').attr('left_pos', newLeft);
          $('.project_image').attr('top_pos', newTop);

          $('.project_image').css('background-position', newLeft + 'px ' + newTop + 'px');

          startX = e.pageX;
          startY = e.pageY;
        });
        $(window).bind('mouseup', function() {
          $(window).unbind('mouseup');
          $(window).unbind('mousemove');
        });
      }
    });
  };
})(jQuery);
