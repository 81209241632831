const Renoworks = window.Renoworks || {};

function isLocalhost() {
  return /^localhost|:3000$/.test(window.location.host);
}
function getBase() {
  if (isLocalhost() || /^qa\.|\.test\./.test(window.location.host)) {
    return `http://api.test.renoworks.com`;
  } else if (/\.(staging|beta)\./.test(window.location.host)) {
    return 'https://api.staging.renoworks.com';
  }
  return 'https://api.renoworks.com';
}
function getUrl(path = '') {
  let base = getBase();

  return `${base}${path}`;
}

const flagsCache = {};
function getProjectFlags(pid, force = false) {
  // fallback to 'most-likely' case, e.g. when project doesn't exist in DB yet
  const fallbackFlags = {
    has2D: true,
    has3D: false,
    hasSV: false,
    hasDS: true,
    hasAI: false,
    dsID: undefined,
    aiID: undefined,
  };

  const deferred = $.Deferred(); // NB: jQ-promises don't fail-chain natively, so do so manually
  if (!flagsCache[pid] || force) {
    $.ajax({
      url: getUrl('/property/scene/surfaces'),
      method: 'POST',
      data: JSON.stringify({
        surfaces_ids: [pid],
      }),
    }).then(
      scenes => {
        // there can be multiple scenes per pid, so trim down list before checking relevant ones
        const projectScenes = scenes.filter(scene => scene.surfaces_id === pid.toLowerCase());
        if (projectScenes.length) {
          return deferred.resolve({
            has2D: projectScenes.some(scene => scene.is2D),
            has3D: projectScenes.some(scene => scene.is3D),
            hasSV: projectScenes.some(scene => scene.isSV),
            hasDS: projectScenes.some(scene => scene.isDS),
            hasAI: projectScenes.some(scene => scene.isAI),
            dsID: projectScenes.find(scene => scene.ds_project_guid)?.ds_project_guid,
            aiID: projectScenes.find(scene => scene.ai_project_guid)?.ai_project_guid,
          });
        }

        return deferred.resolve(fallbackFlags);
      },
      jqXHR => {
        if (jqXHR.status !== 404) {
          console.warn(jqXHR.responseText);
        }

        return deferred.resolve(fallbackFlags);
      }
    );
  } else {
    deferred.resolve(flagsCache[pid]);
  }

  deferred.then(flags => {
    flagsCache[pid] = flags;
  });

  return deferred;
}

function generateFormData(data) {
  return Object.entries(data).reduce((fd, [key, val]) => {
    fd.append(key, val);
    return fd;
  }, new FormData());
}
function createOrderRequest(data) {
  const TEST_URL = 'http://homeplay.test.cf.renoworks.com';
  let base = isLocalhost() ? TEST_URL : window.location.origin;
  if (base === TEST_URL) base += '/Cuttlefish';

  return $.ajax(getUrl('/v1/order/requests'), {
    type: 'POST',
    processData: false,
    contentType: false,
    dataType: 'json',
    data: generateFormData({
      site: window.Renoworks.client,
      user_id: Renoworks.User.id,
      data: JSON.stringify(Renoworks.User.getData()),
      email: Renoworks.User.email,
      item_type: 'AI_UPLOAD',
      callback_url: `${base}/ai/sync`,
      ...data,
    }),
    xhrFields: {
      withCredentials: true,
    },
  });
}

Renoworks.JavaApi = {
  getBase,
  getUrl,
  getProjectFlags,
  generateFormData,
  createOrderRequest,
};
