/** Copyright �2013 Renoworks, Inc. All rights reserved. www.Renoworks.com
 *
 * @param {jQuery} $ A reference to the jQuery object.
 */
(function($) {
  Renoworks = window.Renoworks || {};
  Renoworks.AnalyticsHandler = new AnalyticsHandler();

  function AnalyticsHandler() {
    this.events = {
      mainNavBar: {
        category: 'main-nav-bar',
        actions: {
          logo: 'logo',
        },
      },
      loginForm: {
        category: 'Log In Form',
        actions: {
          openForm: 'form - open',
          closeForm: 'form - close',
        },
      },
      createAccountForm: {
        category: 'Create Account - Guest Form',
        actions: {
          openForm: 'form - open',
          closeForm: 'form - close',
          submitSuccess: 'submit - success',
        },
      },
      createAccountFormNoGuest: {
        category: 'Create Account - NoGuest Form',
        actions: {
          openForm: 'form - open',
          closeForm: 'form - close',
          submitSuccess: 'submit - success',
        },
      },
      customerProject: {
        category: 'Customer Project',
        actions: {
          openYourProjects: 'your projects modal - open',
          saveProject: 'save',
          openSubmitPhotoForm: 'submit photo form - open',
          submitProject: 'DS_Upload - success',
          openProject: 'Open',
          openDSProject: 'DS_Open',
          openBeforeAfter: 'request - before after slider',
          yourProjectPicksSave: 'Your Project Picks - save',
          picksShareEmail: 'You Project Picks - share > Email',
          picksShareFacebook: 'Your Project Picks - share > Facebook',
          picksShareTwitter: 'Your Project Picks - share > Twitter',
          picksSharePinterest: 'Your Project Picks - share > Pinterest',
          picksDownloadSummary: 'Your Project Picks - download summary',
          areYouFinished: 'Are Your Finished - save',
          areYouFinishedDownloadImage: 'Are You Finished - download image',
          areYouFinishedDownloadSummary: 'Are You Finished - download summary',
          areYouFinishedShareEmail: 'Are You Finished - share > Email',
          areYouFinishedShareFacebook: 'Are You Finished - share > Facebook',
          areYouFinishedSharePinterest: 'Are You Finished - share > Pinterest',
          areYouFinishedShareTwitter: 'Are You Finished - share > Twitter',
        },
      },
      stockProject: {
        category: 'Stock Project',
        actions: {
          openStockGallery: 'stock gallery - open',
          closeStockGallery: 'stock gallery - close',
          openProject: 'Open',
          openProjectNotSaved: 'project not saved modal - open',
          openBeforeAfter: 'request - before after slider',
          yourProjectPicksSave: 'Your Project Picks - duplicated',
          picksShareEmail: 'You Project Picks - share > Email',
          picksShareFacebook: 'Your Project Picks - share > Facebook',
          picksShareTwitter: 'Your Project Picks - share > Twitter',
          picksSharePinterest: 'Your Project Picks - share > Pinterest',
          picksDownloadSummary: 'Your Project Picks - download summary',
          areYouFinished: 'Are Your Finished - duplicated',
          areYouFinishedDownloadImage: 'Are You Finished - download image',
          areYouFinishedDownloadSummary: 'Are You Finished - download summary',
          areYouFinishedShareEmail: 'Are You Finished - share > Email',
          areYouFinishedShareFacebook: 'Are You Finished - share > Facebook',
          areYouFinishedSharePinterest: 'Are You Finished - share > Pinterest',
          areYouFinishedShareTwitter: 'Are You Finished - share > Twitter',
        },
      },
      logInForm: {
        category: 'Log In Form',
        actions: {
          closeForm: 'form - close',
          openForm: 'form - open',
          submitSuccess: 'submit - success',
        },
      },
      resetPasswordForm: {
        category: 'Reset Password Form',
        actions: {
          openForm: 'form - open',
          closeForm: 'form - close',
          submitSuccess: 'submit - success',
        },
      },
      registrationForm: {
        category: 'Registration_form',
        actions: {
          openForm: 'Open_form',
          userStart: 'User_start',
          success: 'Success',
          userExit: 'User_exit',
        },
      },
      uploadForm: {
        category: 'Upload_form',
        actions: {
          openForm: 'Open_form',
          userStart: 'User_start',
          success: 'Success',
          userExit: 'User_exit',
        },
      },
      productAction: {
        category: 'Product action',
        actions: {
          undo: 'Undo product(s)',
          redo: 'Redo product(s)',
          clear: 'Clear all products',
        },
      },
      palettes: {
        category: 'Palettes',
        actions: {
          needHelp: 'Need Help Designing > Designer Picks',
        },
      },
    };

    this.modalState = {
      open: 'open',
      closed: 'closed',
    };

    const updateEventMap = (selector, [key, val], open = false, appendNoGuest = false) => {
      const {
        AccountHandler: { guestsAllowed },
      } = Renoworks;
      const {
        modalEventMap,
        modalState: { [open ? 'open' : 'closed']: stateKey },
      } = this;

      let suffix = '';
      if (!appendNoGuest && !guestsAllowed()) {
        suffix = 'NoGuest';
      }

      modalEventMap[selector][stateKey][key] = `${val}${suffix}`;
    };

    this.modalEventMap = {
      '#sign_up': {
        [this.modalState.open]: {
          category: 'createAccountForm',
          action: 'openForm',
          beforeTrackHook() {
            updateEventMap('#sign_up', ['category', 'createAccountForm'], true);
          },
        },
        [this.modalState.closed]: {
          category: 'createAccountForm',
          action: 'closeForm',
          beforeTrackHook() {
            updateEventMap('#sign_up', ['category', 'createAccountForm']);
          },
        },
      },
      '#sign_in': {
        [this.modalState.open]: {
          category: 'loginForm',
          action: 'openForm',
        },
        [this.modalState.closed]: {
          category: 'loginForm',
          action: 'closeForm',
          checkIfCanTrackHook: () => !Renoworks.User.loggedIn,
        },
      },
      '#reset_password': {
        [this.modalState.open]: {
          category: 'resetPasswordForm',
          action: 'openForm',
        },
        [this.modalState.closed]: {
          category: 'resetPasswordForm',
          action: 'closeForm',
        },
      },
      '#your_projects': {
        [this.modalState.open]: {
          category: 'customerProject',
          action: 'openYourProjects',
        },
      },
      '#select_scene': {
        [this.modalState.open]: {
          category: 'stockProject',
          action: 'openStockGallery',
        },
        [this.modalState.closed]: {
          category: 'stockProject',
          action: 'closeStockGallery',
        },
      },
      '#project-preview': {
        [this.modalState.open]: {
          beforeTrackHook() {
            const category = Renoworks.AnalyticsHandler.getProjectCategory();
            const action = 'openBeforeAfter';

            updateEventMap('#project-preview', ['category', category], true, true);
            updateEventMap('#project-preview', ['action', action], true, true);

            const label = Renoworks.ProjectController.getCurrentProject().folder;
            Renoworks.AnalyticsHandler.setLabelFor(category, action, label);
          },
        },
      },
    };

    this.labelMap = {};

    this.productMap = [];

    this.clickEvent = 'click.analytics';

    this.currentModal = undefined;

    this.share = {
      email: 'Email',
      facebook: 'Facebook',
      twitter: 'Twitter',
      pinterest: 'Pinterest',
    };
  }

  /**
   * In the future, add class to divs called "analytics"? with data-category, data-action, etc. and have a global analytic handler
   *
   */
  AnalyticsHandler.prototype.initAnalytics = function() {
    const handleModalTracking = (state = '', id) => {
      if (this.modalEventMap[id] && this.modalEventMap[id][state]) {
        const { beforeTrackHook, checkIfCanTrackHook } = this.modalEventMap[id][state];

        if (beforeTrackHook) {
          beforeTrackHook();
        }

        const { category, action } = this.modalEventMap[id][state];
        const label = this.getLabelFor(category, action);
        if (category && action) {
          // track if no hook defined or if hook is defined and it returns true
          if (!checkIfCanTrackHook || checkIfCanTrackHook()) {
            this.track(category, action, label);
          }
        }
      }
    };

    $('#logo').click(() => {
      this.track('mainNavBar', 'logo');
    });

    $('header [data-locale-id="upload_your_home_nav"]').click(({ currentTarget }) => {
      const label = `main-nav-bar-${$(currentTarget).text()}`;
      if (Renoworks.User.loggedIn) {
        this.setLabelFor('customerProject', 'openSVForm', label);
      }
    });

    $('header [data-locale-id="start_designing_now"]').click(({ currentTarget }) => {
      const label = `main-nav-bar-${$(currentTarget).text()}`;
      if (!Renoworks.User.loggedIn) {
        this.setLabelFor('createAccountForm', 'openForm', label);
      } else {
        this.setLabelFor('createAccountForm', 'openForm', label);
      }
    });

    $('#splash .section .btn[data-locale-id="start_designing_now"]').click(({ currentTarget }) => {
      const label = Renoworks.User.loggedIn ? `homescreen-${$(currentTarget).text()}` : 'guest';
      this.setLabelFor('stockProject', 'openStockGallery', label);
    });

    $('header [data-locale-id="your_projects"]').click(({ currentTarget }) => {
      if (Renoworks.User.loggedIn) {
        const label = `main-nav-bar-${$(currentTarget).text()}`;
        this.setLabelFor('customerProject', 'openYourProjects', label);
      }
    });

    $('#splash .section .btn[data-locale-id="log_in"]').click(({ currentTarget }) => {
      if (!Renoworks.User.loggedIn) {
        const label = `homescreen-${$(currentTarget).text()}`;
        this.setLabelFor('loginForm', 'openForm', label);
      }
    });

    $('#splash .section .btn[data-locale-id="upload_your_home"]').click(({ currentTarget }) => {
      if (Renoworks.User.loggedIn) {
        const label = `homescreen-${$(currentTarget).text()}`;
        this.track('customerProject', 'openSVForm', label);
      }
    });

    $('#splash .section [data-locale-id="start_designing_now"]').click(({ currentTarget }) => {
      if (!Renoworks.User.loggedIn) {
        const label = `homescreen-${$(currentTarget).text()}`;
        this.setLabelFor('createAccountForm', 'openForm', label);
      }
    });

    $(Renoworks.Event).on(Renoworks.Event.MODAL_OPENED, ({ id, currentModal: fromModal }) => {
      handleModalTracking(this.modalState.open, id);
      this.currentModal = fromModal;

      if (id === '#sign_up') {
        $('#sign_up .button_modal[data-label="sign_in"]')
          .off(this.clickEvent)
          .on(this.clickEvent, ({ currentTarget }) => {
            const label = `Create Account - ${
              Renoworks.AccountHandler.guestsAllowed() ? 'Guest' : 'NoGuest'
            } Form - ${$(currentTarget).text()}`;
            this.setLabelFor('loginForm', 'openForm', label);
          });

        if (Renoworks.AccountHandler.guestsAllowed()) {
          $('#sign_up .guests-allowed .button_modal')
            .off(this.clickEvent)
            .on(this.clickEvent, () => {
              this.track('stockProject', 'openStockGallery', 'guest');
            });
        }
      }

      if (id === '#sign_in') {
        $('#sign_in [data-label="sign_up"]')
          .off(this.clickEvent)
          .on(this.clickEvent, ({ currentTarget }) => {
            const label = `Log In Form - ${$(currentTarget).text()}`;
            this.setLabelFor(
              this.modalEventMap['#sign_in'][this.modalState.open].category,
              'openForm',
              label
            );
          });
      }

      if (id === '#select_scene') {
        $('#select_scene .description_container a')
          .off(this.clickEvent)
          .on(this.clickEvent, ({ currentTarget }) => {
            if (!Renoworks.User.loggedIn) {
              const label = `header body text - ${$(currentTarget).text()}`;
              this.setLabelFor(
                this.modalEventMap['#sign_in'][this.modalState.open].category,
                'openForm',
                label
              );
            }
          });

        $('#select_scene .sample_project')
          .off(this.clickEvent)
          .on(this.clickEvent, ({ currentTarget }) => {
            const projectId = $(currentTarget).data('label');
            this.track('stockProject', 'openProject', projectId);
          });
      }
    });

    $(Renoworks.Event).on(Renoworks.Event.NOTIFY_SHOW, ({ id }) => {
      const modalStack = Renoworks.ViewController.getModalStack();
      if (modalStack && modalStack.length) {
        if (
          modalStack[modalStack.length - 1] === id &&
          modalStack[modalStack.length - 2] === 'project_view'
        ) {
          this.track('stockProject', 'openProjectNotSaved');
        }
      }

      if (!Renoworks.User.loggedIn) {
        $(`#${id} [data-locale-id="save_to_account"]`)
          .off(this.clickEvent)
          .on(this.clickEvent, ({ currentTarget }) => {
            const label = `Project not saved modal - ${$(currentTarget).text()}`;
            this.track('logInForm', 'openForm', label);
          });
      }
    });

    $(Renoworks.Event).on(Renoworks.Event.MODAL_CLOSED, ({ id }) =>
      handleModalTracking(this.modalState.closed, id)
    );

    $(Renoworks.Event).on(Renoworks.Event.SAVE_DESIGN_SUCCESS, ({ projectId }) => {
      this.track('customerProject', 'saveProject', projectId);
    });

    $('#select_scene .upload_card').click(({ currentTarget }) => {
      if (!Renoworks.User.loggedIn) {
        const label = `stock gallery - ${$(currentTarget).text()}`;
        this.setLabelFor('createAccountFormNoGuest', 'openForm', label);
      }
    });

    $('#upload_scene #frmMain').on('submit', () => {
      this.track('customerProject', 'submitProject');
    });

    $('#upload_scene [data-locale-id="upload_cta_go_back_to_property"]').click(
      ({ currentTarget }) => {
        const label = `header body text - ${$(currentTarget).text()}`;
        this.setLabelFor('customerProject', 'openSVForm', label);
      }
    );

    $('#sign_in').on('click', '.btn.button_modal[data-label="create_account"]', () => {
      Renoworks.ViewController.clearFormAndError($('#create_account'));
      $('#create_account input[name="opt_in_register"]').prop('checked', false);
    });

    $(Renoworks.Event).on(Renoworks.Event.FORGOT_PASSWORD_SUCCESS, () => {
      if (!Renoworks.User.loggedIn) {
        this.track('resetPasswordForm', 'submitSuccess');
      }
    });

    $(Renoworks.Event).on(Renoworks.Event.CREATE_ACCOUNT_SUCCESS, () => {
      const { category } = this.modalEventMap['#sign_up'][this.modalState.open];
      this.track(category, 'submitSuccess');
    });

    $('#product_summary [data-locale-id="save_project"]').click(() =>
      this.trackProject('yourProjectPicksSave')
    );
    $('#product_summary [data-locale-id="download_summary"]').click(() =>
      this.trackProject('picksDownloadSummary')
    );
    $('#finish_menu .menu [data-label="show_save_design"]').click(() =>
      this.trackProject('areYouFinished')
    );
    $('#finish_menu .menu [data-locale-id="finish_menu_download_render"]').click(() =>
      this.trackProject('areYouFinishedDownloadImage')
    );
    $('#finish_menu .menu [data-locale-id="finish_menu_download"]').click(() =>
      this.trackProject('areYouFinishedDownloadSummary')
    );

    this.trackForm(
      { formSelector: '#frmMain .step2_form', containerSelector: '#upload_scene' },
      'uploadForm'
    );

    $(Renoworks.Event).on(Renoworks.Event.UPLOAD_SUCCESS, () =>
      this.track('uploadForm', 'success')
    );
  };

  AnalyticsHandler.prototype.trackForm = function trackForm(
    { formSelector, containerSelector },
    categoryName
  ) {
    let userStarted = false;

    $(containerSelector || formSelector)
      .find('.dialog_close')
      .click(() => {
        this.track(categoryName, 'userExit');
        userStarted = false;
      });

    $(formSelector)
      .find(':input')
      .on('input', () => {
        if (!userStarted) {
          this.track(categoryName, 'userStart');
          userStarted = true;
        }
      });
  };

  AnalyticsHandler.prototype.trackDIFMForm = function trackDIFMForm() {
    this.track('uploadForm', 'success');
  };

  AnalyticsHandler.prototype.track = function track(categoryName, actionName, label = '') {
    const event = this.events[categoryName];
    if (event) {
      const { category, actions } = event;
      Renoworks.Analytics.track(category || '', actions[actionName] || '', label);
    }
  };

  AnalyticsHandler.prototype.trackAppliedProducts = function trackAppliedProducts() {
    Object.entries(this.productMap).forEach(([type, values]) => {
      values.forEach(({ profile, style, brand, color }) => {
        // remove duplicate information
        const productInfo = `${brand}: ${style}${
          style.toLowerCase() !== profile.toLowerCase() ? `: ${profile}` : ''
        }`;
        Renoworks.Analytics.track(type, productInfo, color);
      });
    });
    this.clearProductsToTrack();
  };

  AnalyticsHandler.prototype.setLabelFor = function setLabelFor(category, action, label) {
    this.labelMap[`${category}.${action}`] = label;
  };

  AnalyticsHandler.prototype.getLabelFor = function getLabelFor(category, action) {
    return this.labelMap[`${category}.${action}`] || '';
  };

  AnalyticsHandler.prototype.addProductToTrack = function addProductToTrack(type, productData) {
    // @NOTE: There are only exterior products
    type = `Exterior > ${Renoworks.Utils.capitalize(type)}`;
    const { profile, style, color, brand } = productData;

    // store products in a map and ensure there are no duplicate products
    if (this.productMap[type]) {
      const containsProduct = this.productMap[type].some(
        p => p.profile === profile && p.style === style && p.brand === brand && p.color === color
      );

      if (!containsProduct) {
        this.productMap[type].push(productData);
      }
    } else {
      this.productMap[type] = [productData];
    }
  };

  AnalyticsHandler.prototype.clearProductsToTrack = function clearProductsToTrack() {
    this.productMap = {};
  };

  AnalyticsHandler.prototype.trackUserSignIn = function trackUserSignIn() {
    this.track('logInForm', 'submitSuccess');
  };

  AnalyticsHandler.prototype.trackShare = function trackShare(service) {
    const action = this.currentModal === 'project_view' ? 'areYouFinishedShare' : 'picksShare';
    const project = Renoworks.ProjectController.getCurrentProject();
    const { folder, tag } = project;
    const social = this.share[service];
    if (folder && tag && social) {
      this.trackProject(`${action}${social}`);
    }
  };

  AnalyticsHandler.prototype.getProjectCategory = function getProjectCategory(
    project = Renoworks.ProjectController.getCurrentProject()
  ) {
    const { tag } = project;
    const isSample = tag === 'sample';
    return `${isSample ? 'stock' : 'customer'}Project`;
  };

  AnalyticsHandler.prototype.trackProject = function trackProject(
    action,
    project = Renoworks.ProjectController.getCurrentProject()
  ) {
    if (action && project && project.folder) {
      this.track(this.getProjectCategory(project), action, project.folder);
    }
  };
})(jQuery);
