/** Copyright �2013 Renoworks, Inc. All rights reserved. www.Renoworks.com
 *
 * @param {jQuery} $ A reference to the jQuery object.
 */
(function($) {
  Renoworks = window.Renoworks || {};
  Renoworks.FolderHandler = new FolderHandler();

  function FolderHandler() {}

  FolderHandler.prototype.updateMyProjects = function(folder = undefined) {
    return $.ajax({
      type: 'GET',
      url: 'Folder',
      cache: false,
      data: {
        site: Renoworks.client,
      },
      dataType: 'text',
      success: async function(xml) {
        Renoworks.User.folders = [];
        const designIdsInFolders = [];
        if (xml !== 'false') {
          var $folders = $($.parseXML(xml));

          $('folder', $folders).each(function(i, f) {
            var $folder = $(f);
            var folderObj = {
              id: $folder.find('id').text(),
              name: $folder.find('name').text(),
              designs: [],
            };

            $('content', $(this)).each(function() {
              const design = Renoworks.User.getDesign($(this).text());
              if (design) {
                folderObj.designs.push(design);
                designIdsInFolders.push(design.id);
              }
            });

            Renoworks.User.folders.push(folderObj);
          });
        }

        const uncategorizedDesigns = Renoworks.User.getDesigns().filter(
          d => !designIdsInFolders.includes(d.id)
        );
        const uncategorizedFolder = {
          designs: uncategorizedDesigns,
        };

        // Sort most recently designed last
        [...Renoworks.User.folders, uncategorizedFolder].forEach(f => {
          f.designs.sort(function(a, b) {
            a = new Date(a.created.split(' ').join('T'));
            b = new Date(b.created.split(' ').join('T'));
            return b - a;
          });
        });

        await buildYourProjectsView(Renoworks.User.folders, uncategorizedFolder.designs, folder);
      },
      error: function(request, status, error) {
        console.log(request.statusText);
      },
    });
  };

  FolderHandler.prototype.updateDropDowns = function() {
    $('#save_design form select, #your_projects_choose_folder select').html('');
    $('#save_design form select, #your_projects_choose_folder select').append(
      '<option class="locale_html" data-locale-id="choose_folder_select" value="">' +
        Renoworks.LocaleController.getValueForKey('choose_folder_select') +
        '</option>'
    );

    $.each(Renoworks.User.folders, function(i, folder) {
      $('#save_design form select, #your_projects_choose_folder select').append(
        '<option value="' + folder.id + '">' + folder.name + '</option>'
      );
    });

    $('#save_design form select, #your_projects_choose_folder select').append(
      '<option class="locale_html" data-locale-id="new_folder" value="new">' +
        Renoworks.LocaleController.getValueForKey('new_folder') +
        '</option>'
    );
  };

  FolderHandler.prototype.clearFolderView = function() {
    $('#your_projects')
      .find('.folder_tab_page, .no_folders')
      .remove();
  };

  FolderHandler.prototype.getNextFolderNumber = function() {
    return Renoworks.User.folders.length > 0 ? Renoworks.User.folders.length + 1 : 1;
  };

  FolderHandler.prototype.api = (data, options = {}, asParams = false) => {
    const payload = {
      site: Renoworks.client,
      ...data,
    };
    return $.ajax({
      type: 'POST',
      url: `Folder${asParams ? `?${$.param(payload)}` : ''}`,
      cache: false,
      ...options,
      data: asParams ? undefined : payload,
      dataType: 'text',
    });
  };

  FolderHandler.prototype.addDesignToFolder = async function(
    designId,
    folderId = undefined,
    folderName = undefined
  ) {
    if (!folderId) return false;

    const defaultFolderName = Renoworks.LocaleController.getValueForKey('new_folder_name');
    return this.api({
      command: 'addContent',
      content: designId,
      folderId: folderId === 'new' ? undefined : folderId,
      folderName: folderId === 'new' ? folderName || defaultFolderName : undefined,
    })
      .then(() => {
        Renoworks.FolderHandler.updateMyProjects();
        return true;
      })
      .fail(request => {
        console.error(request.statusText);
        return false;
      });
  };

  FolderHandler.prototype.removeDesignFromFolder = function(designId, folder) {
    return this.api(
      {
        command: 'deleteContent',
        content: designId,
        folderId: folder.id,
      },
      {
        type: 'DELETE',
      },
      true
    )
      .then(() => {
        Renoworks.FolderHandler.updateMyProjects(folder);
      })
      .fail(request => {
        console.error(request.statusText);
      });
  };

  FolderHandler.prototype.renameFolder = function(folderId, folderName) {
    var nameChanged = false;
    $.each(Renoworks.User.folders, function(i, folder) {
      if (folder.id == folderId) {
        if (folder.name != folderName) {
          nameChanged = true;
          return false;
        }

        return false;
      }
    });
    if (!nameChanged) {
      return;
    }

    return this.api({
      command: 'modifyFolder',
      folderId,
      folderName,
    })
      .then(data => {
        if (data.indexOf('ERROR') == -1) {
          $.each(Renoworks.User.folders, function(i, folder) {
            if (folder.id == folderId) {
              folder.name = folderName;
              $('.folder[data-folder-id="' + folder.id + '"] .folder_name').text(
                folder.name + ' (' + folder.designs.length + ')'
              );

              return false;
            }
          });

          Renoworks.FolderHandler.updateDropDowns();
        }
      })
      .fail(request => {
        console.log(request.statusText);
      });
  };

  FolderHandler.prototype.removeFolder = function(folderId) {
    return this.api(
      {
        command: 'deleteFolder',
        folderId: folderId,
      },
      {
        method: 'DELETE',
      },
      true
    )
      .then(() => {
        Renoworks.FolderHandler.updateMyProjects();
      })
      .fail(request => {
        console.log(request.statusText);
      });
  };

  /******************************** PRIVATE FUNCTIONS ********************************/
  let activeTab = undefined;
  const refinements = {
    state: {
      uploads: {
        orderBy: '-created',
        filterBy: null,
      },
      folder: {
        orderBy: '-created',
        filterBy: null,
      },
      folder_uncategorized: {
        orderBy: '-created',
        filterBy: null,
      },
    },
    sort(list, orderBy) {
      const sort =
        typeof orderBy === 'string'
          ? (a, b) => {
              const [, reverse, key] = orderBy.match(/^(\-?)(.*)$/); // 'key' || '-key'
              const [A, B] = reverse ? [b, a] : [a, b];
              return A?.[key]?.localeCompare(B?.[key], undefined, {
                numeric: true, // use 'natural' sorting order, e.g. '1' < '2' < '10'
                sensitive: 'base', // make case-insensitive
              });
            }
          : orderBy;
      return orderBy ? list.sort(sort) : list;
    },
    filter(list, filterBy) {
      const filter =
        typeof filterBy === 'string'
          ? item => {
              const [, not, key] = filterBy.match(/^(\!?)(.*)$/); // 'key' || '!key'
              const v = item[key];
              return not ? !v : v;
            }
          : filterBy;
      return filterBy ? list.filter(filter) : list;
    },
    refine(list, { orderBy, filterBy }) {
      const refined = refinements.sort(refinements.filter(list, filterBy), orderBy);
      return refined;
    },
    buildNoResults: () => `<div class="refinements_no_results locale_html" data-locale-id="refinements_no_results">
      ${Renoworks.LocaleController.getValueForKey('refinements_no_results')}
    </div>`,
  };
  function buildRefinements(key, onChange) {
    const $refinements = getTemplate('.card_list_refinements');
    $refinements.find('[name="order_by"]').on('change', e => {
      refinements.state[key].orderBy = $(e.target).val();
      onChange?.();
    });
    const $filters = $refinements.find('[name="filter_by"]');
    if (key !== 'uploads') $filters.find('.for_project').remove();
    $filters.on('change', e => {
      refinements.state[key].filterBy = $(e.target).val();
      onChange?.();
    });

    return $refinements;
  }
  function getTemplate(selector) {
    const $template = $($(`${selector}.template`).prop('outerHTML')).removeClass('template');
    return $template;
  }
  function slugify(text) {
    return text.split(' ').join('');
  }
  async function populateFlags(project) {
    try {
      const flags = await Renoworks.JavaApi.getProjectFlags(project.folder);
      project.isAI = flags.hasAI;
      project.isDS = flags.hasDS;
    } catch (err) {
      console.error(err);
    }
    return project;
  }

  async function buildYourProjectsView(
    folders = [],
    uncategorizedDesigns = [],
    folder = undefined
  ) {
    Renoworks.FolderHandler.clearFolderView();
    Renoworks.FolderHandler.updateDropDowns();

    // tabs
    $('#your_projects .material-tabs.ui-tabs').tabs('destroy');
    $('#your_projects .material-tabs').tabs({
      active: activeTab,
      activate() {
        activeTab = $(this).tabs('option', 'active');
      },
    });

    // designs
    if (folders.length) {
      $('#your_projects .folder_tabs > .card_list')
        .empty()
        .show();
      folders.map(f => buildFolder(f));
    } else {
      const $message = $(
        `<div class="no_folders locale_html" data-locale-id="no_folders">
          ${Renoworks.LocaleController.getValueForKey('no_folders')}
        </div>`
      );
      $('#your_projects .folder_tabs').append($message);
    }
    $('#your_projects .folder_tabs.ui-tabs').tabs('destroy');
    $('#your_projects .folder_tabs').tabs({
      collapsible: true,
      active: false,
      activate() {
        // only show the folders list when a folder is not already open
        $('#your_projects .folder_tabs > .card_list').css(
          'display',
          $('#your_projects .folder_tab_page').is(':visible') ? 'none' : ''
        );

        // set scroll to top of .main
        $('#your_projects .main').scrollTop(0);
      },
    });
    if (folder) {
      // shows the view we were on last
      const href = slugify(`#${folder.name}${folder.id}`);
      $('#your_projects .folder_tabs .folder a[href="' + href + '"]').click();
    }

    const $uncategorized = $('#your_projects .folder_uncategorized');
    if (uncategorizedDesigns.length) {
      $uncategorized.show();

      async function buildList() {
        const $list = $uncategorized.find('.card_list').empty();
        const loaded = Renoworks.Utils.useProgressSpinner($list);
        const refined = refinements.refine(
          await Promise.all(uncategorizedDesigns.map(populateFlags)),
          refinements.state.folder_uncategorized
        );
        loaded();

        $list.empty();
        if (refined.length) {
          refined.forEach(design => {
            const $card = buildDesignCardItem(design);
            $list.append($card.css('display', ''));
          });
        } else {
          $list.append(refinements.buildNoResults());
        }
      }
      await buildList();

      const $refinements = buildRefinements('folder_uncategorized', () => buildList());
      $uncategorized.find('.card_list_refinements').replaceWith($refinements.css('display', ''));
    } else {
      $uncategorized.hide();
    }

    // uploads
    async function buildList() {
      const $list = $('#your_projects #tab-projects .card_list').empty();
      const loaded = Renoworks.Utils.useProgressSpinner($list);
      const refined = refinements.refine(
        await Promise.all(Renoworks.ProjectController.getUploads().map(populateFlags)),
        refinements.state.uploads
      );
      loaded();

      $list.empty();
      if (refined.length) {
        refined.forEach(project => {
          const $card = buildUploadCardItem(project);
          $list.append($card.css('display', ''));
        });
      } else {
        $list.append(refinements.buildNoResults());
      }
    }
    await buildList();

    // refinements
    const $refinements = buildRefinements('uploads', () => buildList());
    $('#tab-projects .card_list_refinements').replaceWith($refinements.css('display', ''));
  }

  function getDesignBackgroundImage(design) {
    const overlay = `data/${Renoworks.client}/projects/${design.render}`;
    const master = `data/${Renoworks.client}/projects/${design.tag}/${design.folder}/master.jpg`;
    const hasSV = true; // NB: designs themselves aren't StreetView-aware, so use overlay ALWAYS
    return `url("${overlay}")${hasSV ? `, url("${master}")` : ''}`;
  }

  function buildFolder(folder) {
    const $folder = getTemplate('.folder');

    $folder.attr('data-folder-id', folder.id);
    $folder.find('a').attr('href', slugify(`#${folder.name}${folder.id}`));
    $folder.find('.folder_name').text(`${folder.name} (${folder.designs.length})`);
    if (folder.designs.length) {
      $folder
        .find('.grid_swatch')
        .css('background-image', getDesignBackgroundImage(folder.designs[0]));
    }

    $('#your_projects .folder_tabs > .card_list').append($folder.css('display', ''));

    buildFolderTabPage(folder); // NB: don't await since contents are hidden while loading anyway
  }

  async function buildFolderTabPage(folder) {
    var $tabPage = getTemplate('.folder_tab_page');

    var id = slugify(`${folder.name}${folder.id}`);
    $tabPage.attr('id', id);
    $tabPage.attr('data-folder-id', folder.id);
    $tabPage
      .find('.folder_name')
      .text(folder.name)
      .val(folder.name);

    function setRenaming(isRenaming) {
      if (isRenaming) {
        $tabPage.find('.rename_folder').hide();
        $tabPage.find('.save_folder_name').show();
        return $tabPage
          .find('.folder_name')
          .prop('readonly', false)
          .focus();
      } else {
        $tabPage.find('.rename_folder').show();
        $tabPage.find('.save_folder_name').hide();
        return $tabPage
          .find('.folder_name')
          .prop('readonly', true)
          .focusout();
      }
    }
    $tabPage.on('click', 'input.folder_name', e => {
      e.stopPropagation();
    });

    $tabPage.on('click', '.rename_folder', function(e) {
      e.stopPropagation();

      setRenaming(true);
    });

    $tabPage.on('click', '.save_folder_name', function(e) {
      e.stopPropagation();

      const $input = setRenaming(false);

      const folderId = $(this)
        .closest('.folder_tab_page')
        .attr('data-folder-id');
      $input.text($input.val());
      Renoworks.FolderHandler.renameFolder(folderId, $input.val());
    });

    $tabPage.on('click', '> header:first-child', () => {
      $('#your_projects .folder_tabs .folder[aria-controls="' + id + '"] a').trigger('click');
      setRenaming(false);
    });
    $tabPage.on('click', '.delete_folder', async e => {
      e.preventDefault();

      Renoworks.Dialog.confirm('del_folder', async () => {
        await Renoworks.FolderHandler.removeFolder(folder.id);
      });
    });

    $('#your_projects .folder_tabs').append($tabPage.css('display', ''));

    async function buildList() {
      const $list = $tabPage.find('.card_list').empty();
      const loaded = Renoworks.Utils.useProgressSpinner($list);
      const refined = refinements.refine(
        await Promise.all(folder.designs.map(populateFlags)),
        refinements.state.folder
      );
      loaded();

      $list.empty();
      if (refined.length) {
        refined.forEach(design => {
          const $card = buildDesignCardItem(design, folder);
          $list.append($card.css('display', ''));
        });
      } else {
        $list.append(refinements.buildNoResults());
      }
    }
    await buildList();

    const $refinements = buildRefinements('folder', () => buildList());
    $tabPage.find('.card_list_refinements').replaceWith($refinements.css('display', ''));
  }

  function buildCardItem(item) {
    const $card = getTemplate('.card_item');
    $card.find(`.for_${item.isAI ? 'ai' : 'ds'}`).css('display', '');
    $card.find('.project_name').text(item.name);
    if (item.created) {
      $card.find('.project_last_saved').text(Renoworks.Utils.getFormattedDate(item.created));
    }

    return $card;
  }

  function buildDesignCardItem(design, folder = undefined) {
    const $card = buildCardItem(design);
    $card.attr('data-design-id', design.id);
    $card.find('.for_design').css('display', '');
    $card.find(`.for_${folder ? '' : 'un'}categorized`).css('display', '');
    $card.find('.overlay').hide();
    $card.find('.grid_swatch').css('background-image', getDesignBackgroundImage(design));

    $card.on('click', '.grid_swatch, .open_design', () => {
      Renoworks.ProjectHandler.loadDesign(design);
      Renoworks.ViewController.showModal('project_view');

      Renoworks.AnalyticsHandler.track(
        'customerProject',
        design.tag === 'sample' ? 'openProject' : 'openDSProject',
        design.folder
      );
    });

    $card.on('click', '.delete_design', () => {
      $(Renoworks.Event).one(Renoworks.Event.DESIGN_DELETE_SUCCESS, () => {
        $(Renoworks.Event).one(Renoworks.Event.DESIGNS_UPDATED, () => {
          // need to wait until designs have been updated
          Renoworks.FolderHandler.updateMyProjects(folder);
        });
      });

      Renoworks.Dialog.confirm('del_design', async () => {
        await Renoworks.User.deleteDesign(design.id);
      });
    });

    $card.on('click', '.add_to_folder', e => {
      e.preventDefault();

      const $dialog = $('#your_projects_choose_folder');
      const $select = $dialog.find('select').val('');
      const $newFolder = $dialog.find('.new_folder_name').addClass('hidden');

      $select
        .off('change.your_projects_choose_folder')
        .on('change.your_projects_choose_folder', ({ currentTarget }) => {
          $newFolder.toggleClass('hidden', $(currentTarget).val() !== 'new');
        });
      $dialog
        .off('submit.your_projects_choose_folder')
        .on('submit.your_projects_choose_folder', async e => {
          e.preventDefault();

          const folderId = $select.val();
          const folderName = $newFolder.val().trim();
          if (await Renoworks.FolderHandler.addDesignToFolder(design.id, folderId, folderName)) {
            Renoworks.ViewController.closeModal('your_projects_choose_folder');
          }
        });

      Renoworks.ViewController.showModal('your_projects_choose_folder');
    });
    $card.on('click', '.remove_from_folder', async e => {
      e.preventDefault();

      if (folder) {
        // @TODO: confirm
        await Renoworks.FolderHandler.removeDesignFromFolder(design.id, folder);
      }
    });

    return $card;
  }

  function getProjectBackgroundImage(project) {
    return `url("data/${Renoworks.client}/projects/${project.tag}/${project.folder}/master.jpg")`;
  }
  function buildUploadCardItem(project) {
    const $card = buildCardItem(project);
    $card.attr('data-project-id', project.id);
    $card.toggleClass('disabled', !project.completed);
    $card.find('.for_project').css('display', '');
    $card.find(project.completed ? '.overlay' : '.badge').hide();
    $card.find('.grid_swatch').css('background-image', getProjectBackgroundImage(project));

    $card.on('click', '.grid_swatch, .open_project', () => {
      Renoworks.ProjectController.setProjectByID(project.id);
      Renoworks.ViewController.showModal('project_view');

      Renoworks.AnalyticsHandler.track(
        'customerProject',
        project.tag === 'sample' ? 'openProject' : 'openDSProject',
        project.folder
      );
    });

    $card.on('click', '.delete_project', () => {
      Renoworks.Dialog.confirm('del_proj_des', async () => {
        await Renoworks.User.deleteUpload(project.id);
      });
    });

    return $card;
  }
})(jQuery);
