const { $, Renoworks } = window;

class LocaleHandler {
  constructor() {
    this.localeRegex = /(en_(CA|US))|(fr_CA)/;
  }

  load() {
    return $.getJSON(`sites/${Renoworks.client}/language.json?date=${Date.now()}`).then(data => {
      Renoworks.LocaleController.setLocales(data);
    });
  }

  isValidLocale(locale) {
    return this.localeRegex.test(locale);
  }
}

Renoworks.LocaleHandler = new LocaleHandler();
