const { Renoworks, $ } = window;

class Accordion {
  constructor() {
    this.$accordionHeader = undefined;
    this.$accordionBody = undefined;
  }

  create($container) {
    this.$accordionHeader = $container.find('.section-header');
    this.$accordionBody = this.$accordionHeader.next('.section-body');

    this.$accordionHeader.click(({ currentTarget }) => {
      const $this = $(currentTarget);
      const $next = $this.next('.section-body');

      if ($next.hasClass('section-body')) {
        $next.toggleClass('open');
        $this.toggleClass('open');
      }
    });
  }
}

Renoworks.Accordion = new Accordion();
