const { Renoworks, $ } = window;

class ProjectViewPage {
  constructor() {
    this.$container = undefined;
    this.$productMainMenu = undefined;
    this.$productSubMenu = undefined;
    this.$paletteSubMenu = undefined;
    this.$image = undefined;
  }

  init() {
    // @TODO: make singleton

    this.$container = $('#project_view');
    this.$projectPreview = $('#project-preview');
    this.$productMainMenu = this.$container.find('#product_menu');
    this.$productSubMenu = this.$container.find('#product_submenu');
    this.$paletteSubMenu = this.$container.find('#designer_picks_configurator_body');
    this.$image = this.$container.find('.project_image');

    const $productMenuBtn = this.$container.find('.project_toolbar .product_menu_btn');

    const toggleMenu = () => {
      this.$productMainMenu.toggleClass('open');
      this.$image.toggleClass('open');
      $productMenuBtn.toggleClass('open');

      // close submenu and its sections
      // @TODO: should it preserve open sections?
      if (this.$productSubMenu.hasClass('open')) {
        this.$productSubMenu.removeClass('open');
        this.$productMainMenu.find('.section-header[data-product]').removeClass('open');
        this.$image.removeClass('open-submenu');

        this.$productSubMenu.find('.section-body[data-product]').removeClass('open');
      }
      if (this.$paletteSubMenu.hasClass('open')) {
        this.$paletteSubMenu.removeClass('open');
        this.$image.removeClass('open-submenu');
      }
    };

    $productMenuBtn.off('click').click(() => toggleMenu());
    this.$image.off('click').click(() => {
      if (!Renoworks.mobile && !Renoworks.MaskingController.isSpaceDown()) {
        toggleMenu();
      }
    });
    // open by default on desktop (if not already)
    if (!Renoworks.mobile && !this.$productMainMenu.hasClass('open')) {
      toggleMenu();
    }
    this.closeSubmenu();
    this.$container
      .find('.close_submenu')
      .off('click')
      .on('click', () => this.closeSubmenu());

    this.configureToolbarButtons();
    this.configureAccordion();
    this.configureDesignerPicksCTA();
    this.configureProductSearch();
    this.configureFiltering();
    this.configureBeforeAfter();
    this.configureApplyToSections();
    this.configureSaveDesign();
  }

  closeSubmenu() {
    // @HACK: since state lives in DOM, simulate the appropriate click to close the submenu
    const productType = this.$productSubMenu.data('product');
    if (productType) {
      const $header = this.$productMainMenu.find(`.section-header[data-product="${productType}"]`);
      if ($header.hasClass('open')) {
        $header.click();
      }
    }
  }

  configureToolbarButtons() {
    const $undoButton = this.$container.find('.btn.undo');
    const $redoButton = this.$container.find('.btn.redo');
    const $clearButton = this.$container.find('.btn.revert');
    const $projectPreview = this.$container.find('.before_after');

    $(Renoworks.Event)
      .on(Renoworks.Event.SAVE_DESIGN_SUCCESS, function() {
        Renoworks.AccountHandler.hasUnsavedChanges = false;
      })
      .on(Renoworks.Event.PRODUCT_UNDO_UNAVAILABLE, function() {
        Renoworks.AccountHandler.hasUnsavedChanges = false;
        $undoButton.addClass('disabled');
        $clearButton.addClass('disabled');
        $projectPreview.addClass('disabled');
      })
      .on(Renoworks.Event.PRODUCT_UNDO_AVAILABLE, function() {
        Renoworks.AccountHandler.hasUnsavedChanges = true;
        $undoButton.removeClass('disabled');
        $projectPreview.removeClass('disabled');

        if (Object.keys(Renoworks.ProjectController.getProductRegions()).length) {
          $clearButton.removeClass('disabled');
        }
      })
      .on(Renoworks.Event.PRODUCT_REDO_UNAVAILABLE, function() {
        $redoButton.addClass('disabled');

        if (!Object.keys(Renoworks.ProjectController.getProductRegions()).length) {
          $clearButton.addClass('disabled');
        }
      })
      .on(Renoworks.Event.PRODUCT_REDO_AVAILABLE, function() {
        $redoButton.removeClass('disabled');
      });

    const projectAction = (promiseFn, action = '') => ({ currentTarget }) => {
      const $this = $(currentTarget);
      if (!$this.hasClass('disabled')) {
        const actionFinished = Renoworks.Utils.useProgressSpinner($this, false);
        const finished = () => {
          actionFinished();
          Renoworks.AnalyticsHandler.track('productAction', action);
        };

        promiseFn()
          .then(finished)
          .catch(finished);
      }
    };

    $undoButton.click(projectAction(Renoworks.ProductHandler.undoProduct, 'undo'));
    $redoButton.click(projectAction(Renoworks.ProductHandler.redoProduct, 'redo'));
    $clearButton.click(projectAction(Renoworks.ProductHandler.clearProducts, 'clear'));
    $projectPreview.click(() => {
      const $slider = this.$projectPreview.find('.before-after-container .slider');
      const $separator = this.$projectPreview.find('.before-after-container .separator');
      const $afterWrapper = this.$projectPreview.find('.after-wrapper');
      const $afterImg = $afterWrapper.find('.img');

      // reset preview slider and image to initial position
      $slider.css('left', '50%');
      $separator.css('left', '50%');
      $afterWrapper.css('transform', 'translate(50%)');
      $afterImg.css('transform', 'translate(-50%)');
    });
  }

  configureAccordion() {
    // @HACK: Because accordions for grid objects are set up dynamically, no need to set the
    // click handler here, because otherwise .accordion-header will have 2 click handlers,
    // which won't allow you to open .accordion-body
    this.$container
      .find('.menu-accordion.section-header:not(.dynamic_header)')
      .off('click')
      .click(e => this.accordionHeaderClickHandler(e));
  }

  configureDesignerPicksCTA() {
    const $ctaButton = this.$productMainMenu.find('.designer_picks_cta .btn');
    $ctaButton.off('click').on('click', () => {
      this.closeSubmenu();
      this.$paletteSubMenu.toggleClass('open');
      if (this.$paletteSubMenu.hasClass('open')) {
        Renoworks.AnalyticsHandler.track('palettes', 'needHelp');
      }
    });
  }

  configureProductSearch() {
    this.$productSubMenu.find('.searchbox').on('submit', event => {
      event.preventDefault();

      const $input = $(event.currentTarget).find('input');
      Renoworks.ProductHandler.searchProduct($input.val(), $input);
    });
  }

  configureFiltering() {
    Renoworks.ProductHandler.loadSidingColors();
    const filterModalName = 'product_filters';
    const $filters = $(`#${filterModalName}`);

    const html = ({ name, name_fr }) =>
      `
    <div class="grid_cell checkbox" data-name="${name}">
        <input type="checkbox" />
        <label>
            <span></span>
            ${Renoworks.LocaleController.getLocale() === 'en' ? name : name_fr || name}
        </label>
    </div>
    `.trim();

    const buildSection = (categories, $container) => {
      // skip building the section if it already contains elements
      if ($container.children().length) {
        return;
      }

      categories.forEach(category => {
        $(html(category))
          .appendTo($container)
          .click(({ currentTarget }) => {
            const $this = $(currentTarget);
            $this.toggleClass('selected');
            const $input = $this.find('input');
            $input.prop('checked', !$input.is(':checked'));
          });
      });
    };

    const brands = Renoworks.ProductController.getBrands();
    if (brands && brands.categories && brands.categories.length) {
      buildSection(brands.categories, $filters.find('.brands .filters'));
    }

    buildSection(Renoworks.Utils.getProductCategory('siding'), $filters.find('.styles .filters'));

    const getNames = $selector => $selector.toArray().map(el => $(el).data('name'));

    const setFilterCount = count => {
      const $button = this.$productSubMenu.find('.btn.filters');
      const $count = $button.find('.count');

      if (count) {
        $button.removeClass('btn-outline');
      } else {
        $button.addClass('btn-outline');
      }

      $count.html(count || '');
    };

    const sidingProducts = Renoworks.ProductController.getProducts('Siding');
    $filters
      .find('.apply_filters')
      .click(() => {
        const selectedBrands = getNames($filters.find('.brands .selected'));
        const selectedStyles = getNames($filters.find('.styles .selected'));
        const selectedColors = getNames($filters.find('.colors .selected'));
        const selectedFilters = [...selectedBrands, ...selectedStyles];
        let colors = [];

        const products = sidingProducts.filter(({ filters }) =>
          selectedFilters.some(f => filters.includes(f))
        );

        if (selectedColors.length) {
          // filter by color
          colors = Renoworks.ProductHandler.sidingColors.filter(({ name }) =>
            selectedColors.includes(name)
          );
        }

        if (selectedFilters.length || selectedColors.length) {
          Renoworks.ProductHandler.showFilteredProduct(
            colors,
            products.length ? products : sidingProducts
          );
          Renoworks.ViewController.closeModal(filterModalName);
        } else {
          this.hideFilterResults();
        }

        setFilterCount(selectedColors.length + selectedFilters.length);
      })
      .end()
      .find('.clear')
      .click(() => {
        this.hideFilterResults();
        setFilterCount(0);
        $filters
          .find('.selected')
          .removeClass('selected')
          .find('input')
          .prop('checked', false);
      });
  }

  accordionHeaderClickHandler(event, callbackFn = undefined) {
    const { currentTarget } = event;
    const $this = $(currentTarget);
    const $next = $this.next();

    const closeSections = $container => {
      $container
        .find('.section-header.open:not(.apply_to), .section-body.open:not(.apply_to)')
        .removeClass('open');
    };

    if ($this.hasClass('opens-slideout')) {
      const productType = $this.data('product');
      const $currentSubSection = this.$productSubMenu.find(
        `.section-body[data-product="${productType}"]`
      );

      // Make sure other sections are not opened
      this.$productMainMenu
        .find(`.section-header[data-product!="${productType}"]:not(.apply_to)`)
        .removeClass('open');

      closeSections(
        this.$productSubMenu
          .find(`.section-body[data-product!="${productType}"]:not(.apply_to)`)
          .removeClass('open')
          .end()
      );

      this.$paletteSubMenu.removeClass('open');

      // open first section by default
      $currentSubSection.find('.section-header:not(.apply_to):not(.no-products):first').click();

      $this.toggleClass('open');
      $currentSubSection.toggleClass('open');

      if (!this.$productSubMenu.hasClass('open')) {
        this.$productSubMenu
          .addClass('open')
          .find('.breadcrumbs span')
          .html($this.find('span').html());

        this.$image.removeClass('open').addClass('open-submenu');
      } else if (productType === this.$productSubMenu.data('product')) {
        // if selected product type is the same as we clicked, close the submenu and any open sections
        closeSections(this.$productSubMenu.removeClass('open'));
        this.$image.addClass('open').removeClass('open-submenu');
      }

      // Update product type for submenu
      this.$productSubMenu.data('product', productType);
    }

    if ($next.hasClass('section-body')) {
      $this.toggleClass('open');
      $next.toggleClass('open');
    }

    if (callbackFn) {
      callbackFn();
    }
  }

  configureApplyToSections() {
    const layers = Renoworks.ProjectController.getLayers()
      .filter(({ type }) => type.toLowerCase() !== 'foreground')
      .filter(({ name }, i, arr) => arr.findIndex(l => l.name === name) === i);

    const getSectionBodySelector = type => `.section-body[data-product="${type}"]`;

    const applyToClickHandler = type => {
      const selector = `${getSectionBodySelector(type)} .apply_to_cell input`;
      const $applyToInput = this.$productSubMenu.find(selector);
      $applyToInput.off('change').on('change', ({ currentTarget }) => {
        const $this = $(currentTarget);
        const isSelected = $this.is(':checked');
        const $cell = $this.parent('.apply_to_cell');

        if ($cell.hasClass('all')) {
          $applyToInput.prop('checked', isSelected);
        } else {
          this.$productSubMenu
            .find('.apply_to_cell.all input')
            .prop(
              'checked',
              isSelected &&
                this.$productSubMenu.find(`${selector}:checked`).length === $applyToInput.length - 1
            );
        }
      });
    };
    const buildApplyToSection = (type, layers) => {
      const selector = `${getSectionBodySelector(type)} .apply-to-container`;
      this.buildApplyToSection(this.$productSubMenu.find(selector), type, layers);

      applyToClickHandler(type);
    };

    Object.keys(Renoworks.ProductHandler.productMap).forEach(type => {
      if (type === 'trim') {
        // trim is exception, because it contains subtypes
        Renoworks.ProductHandler.productMap.trim
          .filter(t => this.$productSubMenu.find(getSectionBodySelector(t)).length)
          .forEach(trimType => {
            const trimLayers = layers.filter(
              // check for trim type and account for window trim that internally has type of "window"
              ({ type, layerType }) =>
                type.toLowerCase() === trimType ||
                (trimType.includes(type.toLowerCase()) && layerType === 'RWWindow')
            );
            buildApplyToSection(trimType, trimLayers);
          });
      } else {
        const uniqueLayers = layers.filter(({ type: t }) =>
          Renoworks.ProductHandler.productMap[type].includes(t.toLowerCase())
        );
        buildApplyToSection(type, uniqueLayers);
      }
    });

    // select all by default
    this.$productSubMenu.find('.apply_to_cell.all input').click();
  }

  buildApplyToSection($applyToContainer, productType, layers) {
    const $applyToBody = $applyToContainer.find('.section-body');
    $applyToBody.empty();

    const getCell = () =>
      $(
        $('.apply_to_cell:first')
          .wrap('<div />')
          .parent()
          .html()
      );

    const buildApplyToCell = layer => {
      const $cell = getCell();
      const id = `apply_to_${Renoworks.Util.randomString()}`;
      const name = layer.getName();

      $cell
        .find('input')
        .attr('id', id)
        .end()
        .find('> label')
        .append(name.toLowerCase()) // convert to lowercase & let CSS handle casing
        .attr('for', id)
        .attr('data-layer-name', layer.name) // always use EN here since it's used to match
        .attr('data-layer-id', layer.id);

      if (Renoworks.ProjectController.getProductForRegion(name)) {
        $cell.find('input').addClass('has_product');
      }

      $cell
        .on('mouseover', function() {
          Renoworks.LayerHandler.hideLayerHighlights();
          Renoworks.LayerHandler.showLayerHighlightByName(name);
        })
        .on('mouseout', function() {
          Renoworks.LayerHandler.hideLayerHighlights();
        });

      $cell.show();
      return $cell;
    };

    const buildApplyToAll = type => {
      const $cell = getCell();

      $cell
        .addClass('all')
        .find('input')
        .attr('id', `apply_to_all_${type}`)
        .end()
        .find('> label')
        .attr('for', `apply_to_all_${type}`)
        .append(Renoworks.LocaleController.getValueForKey('all'));

      $cell
        .on('mouseover', function() {
          Renoworks.LayerHandler.hideLayerHighlights();
          for (var layer of layers) {
            Renoworks.LayerHandler.showLayerHighlightByName(layer.name); // Highlights all layers with the same name
          }
        })
        .on('mouseout', function() {
          Renoworks.LayerHandler.hideLayerHighlights();
        });

      $cell.show();
      return $cell;
    };

    // Get unique layers and build dom elements for each
    const $applyToLayers = layers.map(l => buildApplyToCell(l));

    // Insert 'Apply to all' in the beginning
    $applyToLayers.unshift(buildApplyToAll(productType, $applyToLayers));

    $applyToBody.append($applyToLayers);

    // visually hide the apply to section if there is only one layer
    // it's important that the $applyToContainer stays in the DOM,
    // otherwise product rendering won't be possible
    if (layers.length === 1) {
      $applyToContainer.hide();
    } else {
      // keep "apply to" section open by default
      $applyToContainer.find('.section-header').addClass('open');
      $applyToBody.addClass('open');
    }
  }

  get $applyToSection() {
    return this.$productSubMenu.find(
      `.section-body[data-product="${this.selectedProductType}"] .apply-to-container div.apply_to`
    );
  }

  get selectedProductType() {
    return this.$productSubMenu.data('product');
  }

  configureBeforeAfter() {
    const imageDiff = ({ pageX, currentTarget, gesture }) => {
      const $this = $(currentTarget);
      const $separator = $this.parent().find('.separator');
      const $slider = $this.parent().find('.slider');
      const $afterImageWrapper = $this.find('.after-wrapper');
      const $afterImg = $afterImageWrapper.find('.img.after');

      const xVal = pageX || gesture.center.x;

      if (xVal) {
        const fullWidth = $this.width();
        let xPosition = xVal - $this.offset().left;

        // Ensure `xPosition` won't go out of bounds
        if (xPosition < 0) {
          xPosition = 0;
        } else if (xPosition > fullWidth) {
          xPosition = fullWidth;
        }

        $slider.css({
          left: xPosition,
        });

        $separator.css({
          left: xPosition,
        });

        $afterImageWrapper.css({
          transform: `translateX(${xPosition}px)`,
        });

        $afterImg.css({
          transform: `translateX(-${xPosition}px)`,
        });
      }
    };

    const $wrapper = $('#project-preview .before-wrapper');

    let isDragging = false;

    const startDragging = e => {
      isDragging = true;
      imageDiff(e);
    };

    const dragging = e => {
      if (isDragging) {
        imageDiff(e);
      }
    };

    const stopDragging = () => {
      isDragging = false;
    };

    $wrapper
      .mousedown(startDragging)
      .mousemove(dragging)
      .mouseup(stopDragging)
      .mouseleave(stopDragging);

    // Setup touch events for mobile devices
    $wrapper
      .hammer()
      .bind('panstart', startDragging)
      .bind('panmove', dragging)
      .bind('panend', stopDragging)
      .bind('pancancel', stopDragging);
  }

  hideFilterResults() {
    const $sidingConfigurator = this.$productSubMenu.find('.siding_configurator');
    $sidingConfigurator.removeClass('hasFilterResults');
    Renoworks.ViewController.closeModal('product_filters');

    // Close the section, since it's opened again in productHandler
    const $header = $sidingConfigurator.find('h3.filter_results');
    if ($header.hasClass('open')) {
      $header.click();
    }
  }

  showFilterResults() {
    const $sidingConfigurator = this.$productSubMenu.find('.siding_configurator');
    $sidingConfigurator.find('div.filter_results').empty();
    $sidingConfigurator.addClass('hasFilterResults');
  }

  configureSaveDesign(saveDesignHandler = undefined) {
    const id = 'save_design';
    const $modal = $(`#${id}`);

    const $saveProjectForm = $modal
      .find('form[name="save_project"]')
      .off('change.configureSaveDesign')
      .on('change.configureSaveDesign', 'select', ({ currentTarget }) => {
        const $this = $(currentTarget);
        const $newFolder = $saveProjectForm.find('.new_folder_name');
        if ($this.val() === 'new') {
          $newFolder.removeClass('hidden');
        } else {
          $newFolder.addClass('hidden');
        }
      });
    if (saveDesignHandler) {
      $saveProjectForm
        .off('submit.configureSaveDesign')
        .on('submit.configureSaveDesign', async e => await saveDesignHandler(e));
    } else {
      $saveProjectForm
        .off('submit.configureSaveDesign')
        .on('submit.configureSaveDesign', async e => {
          e.preventDefault();

          const designName =
            $saveProjectForm
              .find('input[name="save_project_name"]')
              .val()
              .trim() || '';
          const folderId =
            $saveProjectForm
              .find('select')
              .val()
              .trim() || '';
          const folderName =
            $saveProjectForm
              .find('.new_folder_name')
              .val()
              .trim() || '';

          const designId = await Renoworks.ProjectHandler.saveDesign(designName);
          await Renoworks.FolderHandler.addDesignToFolder(designId, folderId, folderName);
        });
    }
  }
}

Renoworks.ProjectViewPage = new ProjectViewPage();
