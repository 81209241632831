/** Copyright �2013 Renoworks, Inc. All rights reserved. www.Renoworks.com
 *
 * @param {jQuery} $ A reference to the jQuery object.
 */
(function($) {
  Renoworks = window.Renoworks || {};
  Renoworks.AccountHandler = new AccountHandler();

  function AccountHandler() {
    this.lastSaveStep = null;
    this.hasUnsavedChanges = false;
  }

  AccountHandler.prototype.signInSuccessHandler = function() {
    mixpanel.identify(Renoworks.User.id);
    mixpanel.people.set_once({
      $created: new Date().toISOString(),
    });
    var user_xml = $(Renoworks.User.data);
    mixpanel.people.set({
      $first_name: user_xml.attr('first_name'),
      $last_name: user_xml.attr('last_name'),
      $phone: user_xml.attr('phone'),
      address: user_xml.attr('address'),
      city: user_xml.attr('city'),
      state: user_xml.attr('state'),
      zip: user_xml.attr('zip'),
      heard_about_us: user_xml.attr('heard_about_us'),
      home_age: user_xml.attr('home_age'),
      project_type: user_xml.attr('project_type'),
      project_when: user_xml.attr('project_when'),
      existing_siding_type: user_xml.attr('existing_siding_type'),
      home_value: user_xml.attr('home_value'),
      contractor_contact_preference: user_xml.attr('contractor_contact_preference'),
    });

    if (Renoworks.AccountHandler.lastSaveStep === 'showpalette') {
      Renoworks.ViewController.showModal('my_account');
      $('#my_account li a[href="#saved_palettes"]').click();
    } else if (Renoworks.AccountHandler.lastSaveStep) {
      const { nextModal, options } = Renoworks.AccountHandler.getLastSaveStep();
      Renoworks.ViewController.showModal(nextModal, options);
    }

    Renoworks.AccountHandler.setLastSaveStep(null);

    $('body').addClass('logged_in');
    if (Renoworks.User.dsUser) {
      $('body').addClass('ds-user');
    }

    $('#your_projects .subtitle .first_name, #my_account .subtitle .first_name').text(
      Renoworks.User.data.attr('first_name')
    );
    updateAccountInfo();
    fillUploadForm();
  };

  AccountHandler.prototype.signOutSuccessHandler = function() {
    $('body').removeClass('logged_in');
    $('body').removeClass('ds_user');

    Renoworks.ViewController.showModal('splash');
    Renoworks.ProjectController.clearUploads();
    Renoworks.FolderHandler.clearFolderView();
    fillUploadForm(true);

    Renoworks.Dialog.alert({
      title: Renoworks.LocaleController.getValueForKey('succ'),
      body: Renoworks.LocaleController.getValueForKey('sign_out_succ'),
    });
  };

  AccountHandler.prototype.getLastSaveStep = function() {
    const { lastSaveStep } = Renoworks.AccountHandler;
    if (lastSaveStep === 'string') {
      return {
        nextModal: lastSaveStep,
        options: {},
      };
    } else if (!lastSaveStep) {
      return {
        nextModal: null,
        options: {},
      };
    }
    return Renoworks.AccountHandler.lastSaveStep;
  };

  /**
   * Set the last save step to do certain functionality in the above function after a user has signed in
   * Optionally open a dialog and set a modal_closed to clear the last save step.
   * **This is done so that if the user is prompted to sign in goes to the sign in page and then closes the sign in page.
   * **The next time they go to sign in they won't be given a prompt that they shouldn't be seeing
   *
   * @param {String|Object} lastSaveStep
   * @param {String} [dialog]
   */
  AccountHandler.prototype.setLastSaveStep = function(lastSaveStep, dialog) {
    Renoworks.AccountHandler.lastSaveStep = lastSaveStep;

    // store on DOM so CSS styles can be applied based on what will happen post-login
    const { nextModal } = Renoworks.AccountHandler.getLastSaveStep();
    $('body').attr('data-lastsavestep', nextModal);

    if (dialog) {
      Renoworks.ViewController.showModal(dialog, function() {
        $(Renoworks.Event).one(Renoworks.Event.MODAL_CLOSED, function() {
          if (!Renoworks.User.loggedIn) {
            Renoworks.AccountHandler.lastSaveStep = null;
          }
        });
      });
    }
  };

  AccountHandler.prototype.updateAccountSuccessEventHandler = function() {
    updateAccountInfo();

    Renoworks.Dialog.alert({
      title: Renoworks.LocaleController.getValueForKey('succ'),
      body: Renoworks.LocaleController.getValueForKey('acct_update'),
    });
  };

  AccountHandler.prototype.updatePasswordSuccessEventHandler = function() {
    updateAccountInfo();

    $('.password, .confirm').val('');

    Renoworks.Dialog.alert({
      title: Renoworks.LocaleController.getValueForKey('succ'),
      body: Renoworks.LocaleController.getValueForKey('acct_pass_succ'),
    });
  };

  AccountHandler.prototype.updateAccountFailedEventHandler = function(data) {
    var error = data.error;
    // Display the error message
    var buttons = [];
    var button = new Renoworks.Button();
    button.name = Renoworks.LocaleController.getValueForKey('close');
    button.classes = 'btn';
    button.callback = function() {
      Renoworks.Notify.hide();
      Renoworks.ViewController.showModal('update_profile');
    };
    buttons.push(button);

    Renoworks.Notify.show(
      '<p class="locale_html" data-locale-id="acct_fail">' +
        Renoworks.LocaleController.getValueForKey('acct_fail') +
        ' ' +
        error +
        '</p>',
      Renoworks.LocaleController.getValueForKey('error_exclam'),
      'error',
      buttons
    );
  };

  function fillUploadForm(empty) {
    if (empty) {
      $('#difmFirstName')
        .val('')
        .blur();
      $('#difmLastName')
        .val('')
        .blur();
      $('#difmAddress')
        .val('')
        .blur();
      $('#difmCity')
        .val('')
        .blur();
      $('#difmState')
        .val('')
        .blur();
      $('#difmCountry')
        .val('')
        .blur();
      $('#difmZip')
        .val('')
        .blur();
      $('#difmPhone')
        .val('')
        .blur();
      $('#difmEmail')
        .val('')
        .blur();
      $('#difmNotes')
        .val('')
        .blur();
    } else {
      var user = Renoworks.User;
      var userData = user.data;
      $('#difmFirstName').val(userData.attr('first_name'));
      $('#difmLastName').val(userData.attr('last_name'));
      $('#difmAddress').val(userData.attr('address'));
      $('#difmCity').val(userData.attr('city'));
      $('#difmState').val(userData.attr('state'));
      $('#difmZip').val(userData.attr('zip'));
      $('#difmCountry').val(userData.attr('country'));
      $('#difmPhone').val(userData.attr('phone'));
      $('#difmEmail').val(user.email);
    }
  }

  AccountHandler.prototype.updateAccountInfo = function() {
    updateAccountInfo();
  };

  AccountHandler.prototype.notifyAccountCreated = function() {
    var product = '',
      userInfo = '';
    $.ajax({
      type: 'GET',
      url: 'ClientInfo',
      data: {
        site: Renoworks.client,
        command: 'email',
      },
      success: function(response) {
        var toEmail = response;
        if (!toEmail) {
          //If getClientEmail returns null, we don't want to send any emails.
          return;
        }

        var user = Renoworks.User;
        var userData = user.getData();
        userInfo += userData.first_name + '||';
        userInfo += userData.last_name + '||';
        userInfo += userData.address + '||';
        userInfo += userData.city + '||';
        userInfo += userData.state + '||';
        userInfo += userData.zip + '||';
        userInfo += userData.phone + '||';
        userInfo += user.email + '||';

        userInfo +=
          userData.heard_about_us && userData.heard_about_us != '' ? userData.heard_about_us : '';
        userInfo += '||';
        userInfo += userData.home_age && userData.home_age != '' ? userData.home_age : '';
        userInfo += '||';
        userInfo +=
          userData.project_type && userData.project_type != '' ? userData.project_type : '';
        userInfo += '||';
        userInfo +=
          userData.project_when && userData.project_when != '' ? userData.project_when : '';
        userInfo += '||';
        userInfo +=
          userData.existing_siding_type && userData.existing_siding_type != ''
            ? userData.existing_siding_type
            : '';
        userInfo += '||';
        userInfo += userData.home_value && userData.home_value != '' ? userData.home_value : '';
        userInfo += '||';
        userInfo +=
          userData.contractor_contact_preference && userData.contractor_contact_preference != ''
            ? userData.contractor_contact_preference
            : '';
        userInfo += '||';

        if (user.contact == '1' || user.contact == 'true') {
          userInfo += 'This user would like to receive news';
        } else {
          userInfo += 'This user would not like to receive news';
        }

        $.ajax({
          type: 'POST',
          url: 'EmailLead',
          data: {
            site: Renoworks.client,
            userInfo: userInfo,
            project: null,
            product: null,
            toEmail: toEmail,
          },
        });
      },
    });
  };

  AccountHandler.prototype.updateAccount = function updateAccount() {
    let $promise = $.Deferred();
    if (Renoworks.ViewController.validateForm('update_profile')) {
      var $form = $('form[name="update_profile"]');
      var email = $form.find('input[name="email"]').val();
      var contact = $form.find('input[name="opt_in_register"]').is(':checked');
      var data = {};

      // Copy existing data -- keep in mind there's stuff here we don't want
      // to overwrite that was only saved during account creation
      $.each($(Renoworks.User.data)[0].attributes, function(i, attr) {
        data[attr.name] = attr.value;
      });

      // Overwrite with new data
      data['first_name'] = $form.find('input[name="first_name"]').val();
      data['last_name'] = $form.find('input[name="last_name"]').val();
      data['address'] = $form.find('input[name="address"]').val();
      data['city'] = $form.find('input[name="city"]').val();
      data['state'] = $form.find('input[name="state"]').val();
      data['zip'] = $form.find('input[name="zip"]').val();
      data['phone'] = $form.find('input[name="phone"]').val();
      data['user_type'] = $form.find('input[name="user_type"]:checked').val();

      $promise = Renoworks.User.update(email, null, null, data, contact).then(() => {
        Renoworks.ViewController.closeModal('update_profile');
      });
    } else {
      $promise.reject();
    }

    return $promise;
  };

  function updateAccountInfo() {
    var email = Renoworks.User.email;
    var data = Renoworks.User.data;

    var fName = data.attr('first_name');
    var lName = data.attr('last_name');
    var state = data.attr('state');
    var zip = data.attr('zip');
    var phone = data.attr('phone');
    var city = data.attr('city');
    var address = data.attr('address');
    var userType = data.attr('user_type');
    var contact = Renoworks.User.contact;

    // Populate edit account
    $('#edit_profile input[name="email"]').val(email);
    $('#edit_profile input[name="first_name"]').val(fName);
    $('#edit_profile input[name="last_name"]').val(lName);
    $('#edit_profile input[name="address"]').val(address);
    $('#edit_profile input[name="city"]').val(city);
    $('#edit_profile input[name="state"]').val(state);
    $('#edit_profile input[name="zip"]').val(zip);
    $('#edit_profile input[name="phone"]').val(phone);
    $('#edit_profile input[value="' + userType + '"]').prop('checked', true);

    $('#edit_profile input[name="current_password"]').val('');
    $('#edit_profile input[name="password"]').val('');
    $('#edit_profile input[name="confirm_password"]').val('');

    if (contact == '1' || contact == 'true') {
      $('#edit_profile input[name="opt_in_register"]').prop('checked', true);
    }
  }

  AccountHandler.prototype.guestsAllowed = function guestsAllowed() {
    const { nextModal } = Renoworks.AccountHandler.getLastSaveStep();
    return !!nextModal && nextModal === 'select_scene';
  };
})(jQuery);
