const { $, Renoworks } = window;

class DesignController {
  get lastSavedDesign() {
    const { folder } = Renoworks.ProjectController.getCurrentProject();
    return Renoworks.User.getDesignForProject(folder);
  }

  // Port of functionality in Renoworks.User.saveDesign
  buildDesignXml(name = '', design = Renoworks.ProjectController.createDesign()) {
    if (!name) {
      const created = new Date();
      const iso = created.toISOString().replace('T', ' ');
      // to ensure the format is yyyy-mm-dd H:mm
      name = iso.substring(0, iso.indexOf(':') + 3);
    } else {
      name = name.encodeXML();
    }

    design.name = name;

    const createAreaNode = (name, data, type, layerIds = '') =>
      `<area name="${name}" data="${data}" type="${type}" ${layerIds &&
        `layerIds="${layerIds}"`} />`;

    const appendAreaNodes = (productData, xml, useLayerProducts = false) =>
      Object.entries(productData).reduce((current, [key, val]) => {
        let name = key;
        let layerIds = '';

        if (useLayerProducts) {
          // Make an area for each product that is assigned to one or more layers
          // Get the product to layer mapping
          val = Renoworks.ProductController.getProductDesign(key);
          if (val) {
            name = val.name;
            layerIds = productData.map(({ id }) => id).join();
          }
        }

        // Get data either from design palette or product design object
        // otherwise return unmodified xml
        if (val) {
          const { data, type } = val;
          return `${current}${createAreaNode(name, data.encodeXML(), type, layerIds)}`;
        } else {
          return current;
        }
      }, xml);

    // Convert design to xml
    let xml = `<design name="${design.name}" tag="${design.tag}" folder="${design.folder}">`;
    xml = appendAreaNodes(design.palette, xml);
    xml = appendAreaNodes(Renoworks.ProjectController.getLayerProducts(), xml, true);
    xml += '</design>';

    return xml;
  }

  /**
   * Compare current and last saved designs and verify whether current design has changed.
   * If no design has been saved before, return true
   *
   * @returns {boolean} true, if design has changed, or hasn't been saved before
   */
  isDesignChanged() {
    const { lastSavedDesign } = this;
    if (lastSavedDesign) {
      const lastSavedXml = this.buildDesignXml(lastSavedDesign.name, lastSavedDesign);
      const currentXml = this.buildDesignXml(lastSavedDesign.name);

      return currentXml !== lastSavedXml;
    }

    return true;
  }
}

Renoworks.DesignController = new DesignController();
