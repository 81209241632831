/** Copyright �2013 Renoworks, Inc. All rights reserved. www.Renoworks.com
 *
 * @param {jQuery} $ A reference to the jQuery object.
 */
(function($) {
  Renoworks = window.Renoworks || {};
  Renoworks.LayerHandler = new LayerHandler();

  function LayerHandler() {
    this.paintLayers = ['Trim', 'Soffit', 'Fascia'];
    this.openingLayers = [];
  }

  LayerHandler.prototype.showLayerHighlight = function(layer) {
    Renoworks.MaskingController.showSingleLayerHighlight(layer, 0.5, true); // layer, alpha, use default color
  };
  LayerHandler.prototype.showLayerHighlightByName = function(name) {
    const layers = Renoworks.ProjectController.getLayersForName(name);
    layers.forEach(layer => Renoworks.MaskingController.showSingleLayerHighlight(layer, 0.5, true)); // layer, alpha, use default color
  };
  LayerHandler.prototype.hideLayerHighlights = function() {
    Renoworks.MaskingController.eraseRasterCanvas();
  };

  LayerHandler.prototype.addSurfaces = function(e) {
    e.stopPropagation();
    //add the new perspective with the set dimensions and show all other openings
    addNewSurface($('#selected_layer option:selected').text());
    Renoworks.MaskingController.showAllPerspectives();
  };

  LayerHandler.prototype.addForeground = function(e) {
    e.stopPropagation();

    // check if foreground layer applied, only one foreground layer can exist
    var layers = Renoworks.ProjectController.getLayers();
    var FOREGROUND_EXISTS = false;
    for (var i = 0; i < layers.length; i++) {
      if (layers[i].type == 'Foreground') {
        FOREGROUND_EXISTS = true;
        break;
      }
    }
    if (!FOREGROUND_EXISTS) {
      addNewForeground();
      Renoworks.MaskingController.hideAllPerspectives();
      Renoworks.MaskingController.showAllHighlightPerspectives();
      if (Renoworks.ProjectController.getLayers().length === 1) {
        $(Renoworks.Event).trigger({
          type: Renoworks.Event.MASKING_LAYER_OPENED,
          value: true,
        });
      }
    } else {
      var html =
        '<p class="locale_html" data-locale-id="not_fore_layer">' +
        Renoworks.LocaleController.getValueForKey('not_fore_layer') +
        '</p>';
      var title = Renoworks.LocaleController.getValueForKey('fore_layer');
      var classes = 'notice';
      var buttons = [];
      var button = new Renoworks.Button();
      button.name = Renoworks.LocaleController.getValueForKey('close');
      button.classes = 'btn';
      button.callback = function() {
        $('.dialog_close:first').trigger('click');
      };
      buttons.push(button);

      Renoworks.Notify.show(html, title, classes, buttons);
    }
  };

  LayerHandler.prototype.updateLayerOrder = function() {
    $('#layer_regions .layer:not(first)').each(function(i) {
      // set the order of the layers
      var layer = Renoworks.ProjectController.getLayerByID($(this).data('layer-id'));
      if (layer) {
        layer.order = i;
      }
    });
  };

  /**
   * This determines whether or not the user is in masking mode
   */
  LayerHandler.prototype.layersCollapsed = function() {
    if (
      $('.layer.open .tool_button.selected:visible').length > 0 ||
      $('.layer.open .opening_tool.selected:visible').length > 0
    ) {
      return false;
    } else {
      return true;
    }
  };

  LayerHandler.prototype.setSelectedLayer = function(
    layer,
    value,
    noResize //layer can be the layer object or the layer id
  ) {
    //assigns the selected value to the layer, sets whether the perspective should be shown and draggable
    if (layer && layer.id) {
      if (layer.selected != value) {
        layer.setSelected(value);
        layer.perspective.setDraggable(value);
        if (!value) {
          layer.perspective.hide();
        }
      }
    } else {
      var lay = Renoworks.ProjectController.getLayerByID(layer);
      if (lay.selected != value) {
        lay.setSelected(value);
        lay.perspective.setDraggable(value);
        if (!value) {
          lay.perspective.hide();
        }
      }
    }

    if (!noResize && !Renoworks.mobile) {
      Renoworks.MaskingController.resizeMasking(true, true);
    }
  };

  LayerHandler.prototype.setLayerRegionEvents = function(div, isSample, layerType) {
    div
      .mouseenter(function() {
        //show rollover layers
        if (isSample || $('.layer.open').length == 0) {
          $('.layer[data-label="' + $(this).attr('data-label') + '"]').each(function() {
            var lay = Renoworks.ProjectController.getLayerByID($(this).attr('data-layer-id'));
            Renoworks.MaskingController.showSingleLayerHighlight(lay);
          });
        } else {
          //If masking show the layer highlight
          $('.layer[data-label="' + $(this).attr('data-label') + '"]').each(function() {
            var lay = Renoworks.ProjectController.getLayerByID($(this).attr('data-layer-id'));
            if ($.inArray(lay.type, Renoworks.LayerHandler.openingLayers) >= 0) {
              Renoworks.MaskingController.highlightPerspective(lay); //Doors need to show the highlightPerspective
            } else {
              Renoworks.MaskingController.showLayerHighlight(lay);
            }
          });
        }
      })
      .mouseleave(function() {
        if (
          isSample ||
          $('.layer.open').length == 0 ||
          ($('.layer.open')
            .find('.swatch')
            .hasClass('product_applied') &&
            Renoworks.LayerHandler.layersCollapsed())
        ) {
          Renoworks.MaskingController.eraseRasterCanvas();
          //Still show selected layer, unless product is applied
          var empty = false;
          $('.layer.selected').each(function() {
            var lay = Renoworks.ProjectController.getLayerByID($(this).attr('data-layer-id'));
            if (
              !$(this)
                .find('.swatch')
                .hasClass('product_applied') ||
              empty ||
              lay.type == 'Foreground'
            ) {
              empty = true;
              Renoworks.MaskingController.showSingleLayerHighlight(lay);
            }
          });
        } else {
          //If masking hide the layer highlight
          $('.layer[data-label="' + $(this).attr('data-label') + '"]').each(function() {
            var lay = Renoworks.ProjectController.getLayerByID($(this).attr('data-layer-id'));
            if ($.inArray(lay.type, Renoworks.LayerHandler.openingLayers) >= 0) {
              Renoworks.MaskingController.hideHighlightPerspective(lay); //Doors need to show the highlightPerspective
            } else {
              Renoworks.MaskingController.hideLayerHighlight(lay);
            }
          });
        }
      })
      .click(function() {
        Renoworks.ProjectController.setRegion($(this).attr('data-label'));
        if (!$(this).hasClass('selected')) {
          $('.layer.selected').removeClass('selected');
          $('.layer[data-label="' + $(this).attr('data-label') + '"]').trigger('mouseenter');
          $('.layer[data-label="' + $(this).attr('data-label') + '"]').addClass('selected');
          $('.layer[data-label="' + $(this).attr('data-label') + '"]').trigger('mouseleave');
        }
      });

    if (!isSample) {
      setMaskingLayerRegionEvents(div);
    }
  };

  function setMaskingLayerRegionEvents(div) {
    var layerId = div.attr('data-layer-id');

    div.on('click', function() {
      if (!$(this).hasClass('open')) {
        $(this)
          .parent()
          .find('.layer.open')
          .removeClass('open');
        $(this)
          .parent()
          .find('.layer .perspective_tool.tool_button.selected')
          .removeClass('selected');
        $(this)
          .parent()
          .find('.layer .opening_tool.selected')
          .removeClass('selected');
        $(this).addClass('open');
        if ($.inArray($(this).attr('data-type'), Renoworks.LayerHandler.openingLayers) >= 0) {
          $(this)
            .find('.opening_tool')
            .addClass('selected');
        } else {
          $(this)
            .find('.tool_button')
            .first()
            .click();
        }
        var layers = Renoworks.ProjectController.getLayers();
        for (var i = 0; i < layers.length; i++) {
          if ($(this).attr('data-layer-id') == layers[i].id) {
            Renoworks.LayerHandler.setSelectedLayer($(this).attr('data-layer-id'), true);
          } else {
            Renoworks.LayerHandler.setSelectedLayer(layers[i], false);
          }
        }

        Renoworks.MaskingController.setDesignMode(false);
        Renoworks.ProjectHandler.setProjectDesignMode(false);
        // Set the current layer for masking
        var layerId = $(this).attr('data-layer-id');
        Renoworks.MaskingController.setCurrentLayer(layerId);
        if ($(this).attr('data-type') == 'Door') {
          Renoworks.MaskingController.showPerspective();
          Renoworks.MaskingController.disableDrawing();
        }
        Renoworks.MaskingController.refresh();
        Renoworks.MaskingController.resizeMasking(true, true);
        Renoworks.MaskingController.showAllPerspectives();

        Renoworks.MaskingController.updateUndoRedo();
      }
    });

    div.on('click', '.layer_edit_details', function(e) {
      e.stopPropagation();
      var currentLayerName = div.attr('data-label');
      $('.dialog.layer_properties input').val(currentLayerName); // set the default layer name to be the current layer name
      var currentLayerType = div.attr('data-type');
      $('.dialog.layer_properties select option:contains("' + currentLayerType + '")').prop(
        'selected',
        true
      ); // set the default selection to be the current product type
      Renoworks.ViewController.showModal('layer_properties');
    });

    // handler for selecting masking tools
    div.on(
      'click',
      '.masking_tools .draw_tools .tool_button, .masking_tools .erase_tools .tool_button',
      function() {
        div.find('.masking_tools .draw_tools').removeClass('disabled');
        div.find('.masking_tools .erase_tools').removeClass('disabled');

        if (Renoworks.LayerHandler.layersCollapsed()) {
          //the layer opened is the selected layer, the rest are not selected
          var layers = Renoworks.ProjectController.getLayers();
          Renoworks.LayerHandler.setSelectedLayer(layerId, true);
          for (var i = 0; i < layers.length; i++) {
            if (layers[i].id != layerId) {
              Renoworks.LayerHandler.setSelectedLayer(layers[i], false);
            }
          }

          Renoworks.MaskingController.setDesignMode(false);
          Renoworks.ProjectHandler.setProjectDesignMode(false);

          var layerType = $('.layer.selected').attr('data-type');

          //if opening was selected to edit, show it else show everthing
          switch (layerType) {
            case 'Door':
              Renoworks.MaskingController.showPerspective();
              break;
            case 'Window':
              Renoworks.MaskingController.showPerspective();
              break;
            default:
              break;
          }

          Renoworks.MaskingController.showAllPerspectives();
          Renoworks.MaskingController.resizeMasking(true, true);
        }

        $('#layer_selection .tool_button.selected').removeClass('selected');
        $(this).addClass('selected');

        div.find('.masking_tools .perspective_tool').removeClass('selected');
        Renoworks.MaskingController.hideScale();
        Renoworks.LayerHandler.closeScaleSettings();

        changeToMasterImage();

        hideAdvancedSettings(div);

        // Hide perspective
        /*Renoworks.MaskingController.setStep('STEP1');
            Renoworks.MaskingController.hidePerspective();
            div.find('.masking_tools .perspective_tool').removeClass('selected');
            Renoworks.MaskingController.hideScale();
            Renoworks.LayerHandler.closeScaleSettings();

            changeToMasterImage();

            hideAdvancedSettings(div);*/
      }
    );

    div.on('click', '.masking_tools .draw_tools, .masking_tools .erase_tools', function() {
      Renoworks.MaskingController.showCanvas();
    });
    // set Draw tool
    div.on('click', '.masking_tools .draw_tools', function() {
      // Set the mode to draw
      Renoworks.MaskingController.draw();
      // Change the look of the masking tool buttons to draw
    });

    // set Erase tool
    div.on('click', '.masking_tools .erase_tools', function() {
      // Set the mode to erase
      Renoworks.MaskingController.erase();
      // Change the look of the masking tool buttons to erase
    });

    // set Polygon tool
    div.on('click', '.masking_tools .polygon', function() {
      if (!Renoworks.MaskingController.getPolygonDrawLock()) {
        Renoworks.MaskingController.changeTool('polygon');
      }
    });

    // set Rectangle tool
    div.on('click', '.masking_tools .rectangle', function() {
      if (!Renoworks.MaskingController.getPolygonDrawLock()) {
        Renoworks.MaskingController.changeTool('rectangle');
      }
    });

    // set flood fill tool
    div.on('click', '.masking_tools .flood_fill', function() {
      if (!Renoworks.MaskingController.getPolygonDrawLock()) {
        Renoworks.MaskingController.startFloodFill(div.attr('data-layer-id'));
      }
    });

    // Brightness
    div.on('change', '.masking_tools .brightness_tool input', function() {
      var newVal = parseFloat($(this).val());
      if (newVal > 100) {
        newVal = 100;
      } else if (newVal < -100) {
        newVal = -100;
      }

      $(this).val(newVal);

      $(this)
        .prev()
        .slider('option', 'value', newVal);

      Renoworks.MaskingController.setGamma(Renoworks.MaskingController.brightnessToGamma(newVal));
    });

    // Opacity
    div.on('change', '.masking_tools .opacity_tool input', function() {
      var newVal = parseInt($(this).val());
      if (newVal > 100) {
        newVal = 100;
      } else if (newVal < 0) {
        newVal = 0;
      }

      $(this).val(newVal);

      $(this)
        .prev()
        .slider('option', 'value', newVal);

      Renoworks.MaskingController.setOpacity(newVal);
    });

    // Perspective
    div.on(
      'click',
      '.masking_tools .perspective_tool.tool_button label, .masking_tools .perspective_tool.tool_button img',
      function(e) {
        e.stopPropagation();
        var selector = $(this).closest('.perspective_tool');
        if (selector.hasClass('selected')) {
          selector.removeClass('selected');
          Renoworks.MaskingController.hidePerspective();
          return;
        }
        selector.addClass('selected');
        var layerSelect = selector.closest('.layer');
        if (layerSelect.hasClass('open')) {
          Renoworks.LayerHandler.setSelectedLayer(layerSelect.attr('data-layer-id'), true);
          Renoworks.MaskingController.hideCanvas();
          Renoworks.MaskingController.hideScale();
          Renoworks.MaskingController.showPerspective();
        } else {
          Renoworks.MaskingController.showCanvas();
          //Renoworks.MaskingController.hidePerspective();
        }
        changeToMasterImage();
      }
    );

    div.on('click', '.masking_tools .preview_tool.tool_button', function(e) {
      e.stopPropagation();
      // Save the project
      Renoworks.ProjectController.saveProject(null, true);
      // De-select current tool
      $('#layer_selection .layer.open .tool_button.selected').removeClass('selected');
      // Hide the masks

      // Render the preview
      if (
        div.find('.swatch').hasClass('product_applied') ||
        div.attr('data-type') == 'Foreground'
      ) {
        Renoworks.ProjectController.render();
      } else {
        renderPreview(div);
      }
    });
  }

  function renderPreview(div) {
    Renoworks.MaskingController.setDesignMode(true);
    // Hide masking canvas
    Renoworks.ProjectHandler.setProjectDesignMode(true);

    // Set default (checkered) product, and render
    Renoworks.ProjectController.setPreviewRegion(Renoworks.ProjectController.getRegion());
    Renoworks.ProjectController.render();
  }

  function addNewForeground() {
    var html = $('.layer')
      .first()
      .wrap('<div />')
      .parent()
      .html();
    $('.layer')
      .first()
      .unwrap();
    var div = $(html);

    div.addClass('not_sortable');

    $('#layer_regions .layer').removeClass('open');
    div.addClass('open');

    var layerType = 'Foreground';
    var layerName = layerType;

    div.addClass('foreground');

    div.find('.layer_name').html(layerType);

    // Add layer to the project/canvas
    var layerId = Renoworks.MaskingController.newLayer(layerName, layerType);

    div.attr('data-label', layerType);
    div.attr('data-type', layerType);
    div.attr('data-layer-id', layerId);

    // Add layer region
    div.show();

    $('#layer_regions').prepend(div);
    Renoworks.LayerHandler.updateLayerOrder();
    Renoworks.LayerHandler.setLayerRegionEvents(div, false);

    div.on('click', function() {
      $(this)
        .parent()
        .find('.layer')
        .removeClass('open');
      $(this).addClass('open');
      Renoworks.MaskingController.updateUndoRedo();
    });

    // See if we should add a link icon to the layer
    Renoworks.LayerHandler.setLinkIcons();

    // Select polygon
    div.find('.draw_tools .polygon.tool_button').click();

    // Open first accordian
    div
      .find('h3')
      .first()
      .click();

    // Show the masking canvas
    Renoworks.MaskingController.draw();
    Renoworks.MaskingController.showRasterCanvas();
    Renoworks.MaskingController.setDesignMode(false);
    Renoworks.ProjectHandler.setProjectDesignMode(false);
    Renoworks.MaskingController.refresh();

    Renoworks.MaskingController.updateUndoRedo();
  }

  LayerHandler.prototype.setLinkIcons = function() {
    var layerNames = getLayerNameCounts(Renoworks.ProjectController.getLayers());
    // Go through the .layer and add a link if there is more than one layer with that name
    $('#layer_regions .layer').each(function() {
      var layerName = $(this)
        .find('.layerName')
        .text();
      var linkIcon = $(this).find('.swatch .link_icon');
      var layoutType = $(this)
        .find('.layoutSelect option:selected')
        .attr('data-label');
      var outerNode = $(this);
      var i = 0;
      if (layerNames[layerName] > 1) {
        $('#layer_regions .layer').each(function() {
          if (
            $(this)
              .find('.layerName')
              .text() == layerName &&
            $(this)
              .find('.layoutSelect option:selected')
              .attr('data-label') == layoutType
          ) {
            i += 1;
            if (i == 2) {
              linkIcon.show();
              outerNode.addClass('selected');
              outerNode.addClass('grouped');
            }
          }
        });
      } else {
        linkIcon.hide();
        $(this).removeClass('grouped');
      }
    });
  };

  LayerHandler.prototype.closeScaleSettings = function(step) {
    if (!step) {
      step = 'STEP1';
    }

    // Hide the scale line
    Renoworks.MaskingController.hideScale();
    Renoworks.MaskingController.setStep(step);

    $('.raphael').hide();
    if (Renoworks.LayerHandler.layersCollapsed()) {
      Renoworks.MaskingController.setDesignMode(true);
      Renoworks.ProjectHandler.setProjectDesignMode(true);
    } else {
      Renoworks.MaskingController.setDesignMode(false);
      Renoworks.ProjectHandler.setProjectDesignMode(false);
    }
  };

  LayerHandler.prototype.selectLayerRegion = function(selector) {
    var swatchLayerType = selector.find('option:selected').text(); //added since the text needs to be displayed in EN and FR
    //selector.parents('.layer').find('.click_to_apply span').html(swatchLayerType.toLowerCase());
    $('.layer.grouped')
      .find('.click_to_apply span')
      .html(selector.val().toLowerCase());
    $('.layer.grouped')
      .find('.layoutSelect')
      .val(selector.val());
    // Change layer type
    Renoworks.MaskingController.setLayerType(selector.val());
    if (Renoworks.MaskingController.setOpacity(100, true)) {
      $('.layer_masking .opacity_tool input').val(100);
    }

    if (
      selector
        .parents('.layer')
        .find('.layerName')
        .attr('changed') === 'false'
    ) {
      // Get new layer name
      var id = selector.parents('.layer').attr('data-layer-id');
      var localeLayerName = zeroPad(id, 2) + ' ' + swatchLayerType;
      var layerName = zeroPad(id, 2) + ' ' + selector.val();
      // Set the data-label
      selector.parents('.layer').attr('data-label', layerName);

      Renoworks.MaskingController.setLayerName(layerName);
      selector
        .parents('.layer')
        .find('.layerName')
        .text(localeLayerName);

      selector
        .parents('.layer')
        .find('.perspective_tool')
        .show();
    }

    // Clear applied product
    if (selector.parents('.layer').attr('data-type') != selector.val()) {
      Renoworks.ProjectController.clearProductForRegion(selector.parent().attr('data-label'));
      Renoworks.ProjectController.clearProductForRegion($('.layer.grouped').attr('data-label'));
      Renoworks.MaskingController.eraseRasterCanvas();
      Renoworks.ProjectController.saveProject(null, true);
      Renoworks.ProjectController.render();
      if (!Renoworks.LayerHandler.layersCollapsed()) {
        Renoworks.MaskingController.setDesignMode(false);
        Renoworks.ProjectHandler.setProjectDesignMode(false);
      }
      var swatch = selector.parents('.layer').find('.swatch');
      swatch.css('background-image', '');
      swatch.find('p').show();
      swatch.find('.delete_icon').hide();
      selector
        .parents('.layer')
        .find('.name')
        .html('');
      selector
        .parents('.layer')
        .find('.code')
        .html('');
      selector
        .parents('.layer')
        .find('.filter')
        .html('');

      swatch = $('.layer.grouped').find('.swatch');
      swatch.css('background-image', '');
      swatch.find('p').show();
      swatch.find('.delete_icon').hide();
      $('.layer.grouped')
        .find('.name')
        .html('');
      $('.layer.grouped')
        .find('.code')
        .html('');
      $('.layer.grouped')
        .find('.filter')
        .html('');
    }

    selector
      .parent()
      .closest('.layer')
      .attr('data-type', selector.val());
    $('.layer.grouped').attr('data-type', selector.val());
    Renoworks.LayerHandler.setLinkIcons();
  };

  function zeroPad(num, places) {
    var zero = places - num.toString().length + 1;
    return Array(+(zero > 0 && zero)).join('0') + num;
  }

  function addNewSurface(surfaceLayer, wid, hgt) {
    surfaceLayer = surfaceLayer.trim();

    var html = $('.layer')
      .first()
      .wrap('<div />')
      .parent()
      .html();
    $('.layer')
      .first()
      .unwrap();
    var div = $(html);

    //creating a new layer sets that layer to be selected and all others to be false
    // Get next layerID (not the official layerID.  that is set in masking controller (should be the same though))
    var max = 0;
    var layers = Renoworks.ProjectController.getLayers();
    for (var i = 0; i < layers.length; i++) {
      var id = layers[i].id;
      Renoworks.LayerHandler.setSelectedLayer(layers[i], false);
      if (id > max) {
        max = id;
      }
    }
    max++;

    //select the layer that was clicked
    var layerType = surfaceLayer;
    var layerName = zeroPad(max, 2) + ' ' + surfaceLayer;
    div.find('.layerName').html(layerName);
    div.attr('data-label', layerName);
    div.attr('data-type', layerType);

    // Add layer to the project/canvas
    var layerId = Renoworks.MaskingController.newLayer(
      layerName,
      layerType,
      wid,
      hgt,
      '#ffff00',
      true,
      false,
      true,
      true
    ); // the new layer created will be order 0
    var layer = Renoworks.ProjectController.getLayerByID(layerId);

    //initialize layer properties
    //layer.enableGridLines(true);
    var swatchLayerName = layerName.substring(layerName.indexOf(' ') + 1);
    //div.find('.click_to_apply span').html(swatchLayerName.toLowerCase());

    div.find('.layer_info .layer_name').text(layerName);
    //div.find('.layer_info .product_name').text(layerType);
    $('#layer_regions .layer').removeClass('open');
    div.addClass('open');

    // Set the button group name to include the layerId to make it unique to this .layer
    var drawEraseButtons = div.find('.layer_masking .draw_erase_buttons .toggle_button input');
    drawEraseButtons.attr('name', drawEraseButtons.attr('name') + layerId);

    div.attr('data-layer-id', layerId);

    Renoworks.MaskingController.hidePerspective();
    // Add layer region
    div.show();

    var foreground = $('#layer_regions .layer[data-type="Foreground"]:visible');
    if (foreground.length > 0) {
      // if foreground is in the project, append the new layer BELOW it
      foreground.after(div);
    } else {
      $('#layer_regions').prepend(div);
    }

    Renoworks.LayerHandler.updateLayerOrder();
    Renoworks.LayerHandler.setLayerRegionEvents(div, false, layer.type);

    // Select polygon
    div.find('.draw_tools .polygon.tool_button').click();

    // See if we should add a link icon to the layer_region
    Renoworks.LayerHandler.setLinkIcons();

    div.find('.brightness_tool .slider').slider({
      slide: function(e, ui) {
        $(this)
          .next('input')
          .val(ui.value);
        Renoworks.MaskingController.setGamma(
          Renoworks.MaskingController.brightnessToGamma(ui.value)
        );
      },
      min: -100,
      max: 100,
      step: 10,
      value: 0,
    });

    div.find('.opacity_tool .slider').slider({
      slide: function(e, ui) {
        $(this)
          .next('input')
          .val(ui.value);
        Renoworks.MaskingController.setOpacity(ui.value);
      },
      min: 0,
      max: 100,
      step: 10,
      value: 0,
    });

    setPerspectiveEvent(div, layerId);

    // Show the masking canvas
    Renoworks.MaskingController.draw();
    Renoworks.MaskingController.showRasterCanvas();
    Renoworks.MaskingController.setDesignMode(false);
    Renoworks.ProjectHandler.setProjectDesignMode(false);
    Renoworks.MaskingController.refresh();

    // added this here because "foreground.after(div)" code seemed to have been causing issues when the div.click() was fired after inserting, it would always select foreground..
    if (!div.hasClass('selected')) {
      div.click();
    }

    Renoworks.MaskingController.updateUndoRedo();
  }

  LayerHandler.prototype.loadLayerRegions = function(isSample) {
    var shutterImages = {};
    $('#layer_selection .layer').remove();

    var regions = Renoworks.ProjectController.getRegions();
    var layers = Renoworks.ProjectController.getLayers();
    var projectType = Renoworks.ProjectController.getTag();

    var html = $('.layer')
      .first()
      .wrap('<div />')
      .parent()
      .html();
    $('.layer')
      .first()
      .unwrap();

    var layerNames = getLayerNameCounts(layers);

    // sort the layers by descending order first so they are added in the proper order
    //		if(!isSample){
    layers.sort(function(a, b) {
      return parseInt(a.order) - parseFloat(b.order);
    });
    //		}
    for (var i = 0; i < layers.length; i++) {
      var layer = layers[i];
      var layerId = layer.id;

      var layerName = layer.name;
      var layerType = layer.type;

      var localeLayerName = layer.name;
      var localeLayerType = layer.type;

      //shiv-change layerName based on locale. Done here so all layers in every sample project xml do not need to be modified.
      if (isSample) {
        if (Renoworks.LocaleController.getLocale() == 'fr') {
          switch (layerName) {
            case 'Trim':
              localeLayerName = Renoworks.LocaleController.getValueForKey('trim');
              break;
            case 'Siding Accent':
              localeLayerName = Renoworks.LocaleController.getValueForKey('siding_accent');
              break;
            case 'Siding Main':
              localeLayerName = Renoworks.LocaleController.getValueForKey('siding_main');
              break;
            case 'Roofing':
              localeLayerName = Renoworks.LocaleController.getValueForKey('roofing');
              break;
            case 'Shutter':
              localeLayerName = Renoworks.LocaleController.getValueForKey('shutter');
              break;
            case 'Window':
              localeLayerName = Renoworks.LocaleController.getValueForKey('window');
              break;
            case 'Door':
              localeLayerName = Renoworks.LocaleController.getValueForKey('door');
              break;
            default:
              localeLayerName = layer.name;
              break;
          }
          switch (layerType) {
            case 'Trim':
              localeLayerType = Renoworks.LocaleController.getValueForKey('trim');
              break;
            case 'Siding':
              localeLayerType = Renoworks.LocaleController.getValueForKey('siding');
              break;
            case 'Siding Accent':
              localeLayerType = Renoworks.LocaleController.getValueForKey('siding_accent');
              break;
            case 'Roofing':
              localeLayerType = Renoworks.LocaleController.getValueForKey('roofing');
              break;
            case 'Shutter':
              localeLayerType = Renoworks.LocaleController.getValueForKey('shutter');
              break;
            case 'Window':
              localeLayerType = Renoworks.LocaleController.getValueForKey('window');
              break;
            case 'Door':
              localeLayerType = Renoworks.LocaleController.getValueForKey('door');
              break;
            default:
              localeLayerType = layer.type;
              break;
          }
        }
      }

      // we want to add the foreground layer to the right side only if its an uploaded photo
      if (layerType && (!isSample || (layerType != 'Foreground' && isSample))) {
        var skip = false;
        if (isSample && $('.layer[data-label="' + layerName + '"]').length > 0) {
          skip = true;
        }

        var newHtml = html.replace(new RegExp('#LAYER_NAME#', 'g'), layerName);

        if (layerType.indexOf('siding') != -1)
          newHtml = newHtml.replace(new RegExp('#LAYER_TYPE#', 'g'), layerName);
        // instead of the type being just "Siding" we need it to be "Siding Main" or "Siding Accent"
        else newHtml = newHtml.replace(new RegExp('#LAYER_TYPE#', 'g'), layerType);

        var div = $(newHtml);
        $(div)
          .find('.layer_name')
          .html(localeLayerName);
        // Set the div's layer id
        div.attr('data-layer-id', layer.id);
        //div.find('.click_to_apply span').html(localeLayerType.toLowerCase());

        // If there are more than one layer that have this name, show the link icon
        if (layerNames[layerName] > 1 && !isSample) {
          // Show the link icon
          div.find('.swatch .link_icon').show();
          div.addClass('selected');
        } else {
          div.find('.swatch .link_icon').hide();
        }

        //If this is a sample scene, remove the delete layer button
        if (isSample) {
          div.find('.layer_function_buttons .layer_delete').remove();
          div.find('.layer_function_buttons .layer_edit_details').remove();
        }

        setLayerRegionType(Renoworks.ProjectController.getTag(), div, layerType);

        // Initialize the accordion of the masking tools
        div.find('.accordion').accordion({
          active: false,
          collapsible: true,
          heightStyle: 'content',
        });

        //add title module
        div.find('[title]').tooltip({
          tooltipClass: 'ui-tooltip-custom',
          position: {
            my: 'left center-50',
            at: 'left center',
          },
        });

        // Load the layer type into the layerType dropdown
        div.find('.layoutSelect').val(layer.type);

        // Load the brightness/opacity values into inputs
        div
          .find('.brightness_tool input')
          .val(Renoworks.MaskingController.gammaToBrightness(layer.gammaLevel));
        div.find('.opacity_tool input').val(layer.shadowsLevel);

        var pWidth = layer.perspective.width;
        var pHeight = layer.perspective.height;
        var pWidFt = parseInt(pWidth);
        var pWidIn = Math.round((pWidth % 1) * 12);
        var pHgtFt = parseInt(pHeight);
        var pHgtIn = Math.round((pHeight % 1) * 12);

        var perspectiveSettings = div.find('.perspective_tool_settings');

        // Load the scale values into inputs
        perspectiveSettings
          .find('input:eq(0)')
          .val(pWidFt)
          .attr('data-val', pWidFt);
        perspectiveSettings
          .find('input:eq(1)')
          .val(pWidIn)
          .attr('data-val', pWidIn);
        perspectiveSettings
          .find('input:eq(2)')
          .val(pHgtFt)
          .attr('data-val', pHgtFt);
        perspectiveSettings
          .find('input:eq(3)')
          .val(pHgtIn)
          .attr('data-val', pHgtIn);

        if (!skip) {
          div.show();
        } else {
          div.attr('style', 'display: none !important'); // override css making duplicates inline-block
        }

        div.find('.draw_erase_buttons input').attr('name', $(this).attr('name') + layer.id); // this code fixed the draw/erase button bug where both were unchecked by default

        $('#layer_regions').append(div);

        Renoworks.LayerHandler.setLayerRegionEvents(div, isSample, layer.type);

        if (layerType === 'Foreground') {
          layers[i].maskColor = '#e52222';
          layers[i].mask_color = '#e52222'; // should always be red
          div.addClass('not_sortable');
          div.find('.swatch').remove();
          div
            .find('.masking_tools')
            .children(':not(.draw_tools,.erase_tools,.undo_redo_tools)')
            .remove();
          div
            .find('.masking_tools')
            .css('height', '115px')
            .css('padding-top', '16px');
          div.find('.layer_name').html(layerType);
          div.find('.layer_name').attr('contenteditable', 'false');
          div.find('.layer_info .product_name, .layer_info .product_info').remove();
          div
            .find(
              '.layer_function_buttons .layer_sort, .layer_function_buttons .layer_edit_details'
            )
            .remove();
        }

        //We want to reapply product swatches and names onto the layer_regions if product is applied to any layers.
        var productData = Renoworks.ProjectController.getProductForRegion(layers[i].name);
        if (productData) {
          if (productData.type == 'tile') {
            productData = productData.data;
            var product = Renoworks.ProductController.getProductByRWD(
              productData.split('||')[1].split('=')[1]
            );
            var colorName = productData.split('||')[0].split('=')[1];
            if (Renoworks.LocaleController.getLocale() == 'fr') {
              div.find('.name').html(product.name_fr);
            } else {
              div.find('.name').html(product.name);
            }
            for (var z = 0; z < product.colors.length; z++) {
              var color = product.colors[z];
              if (color.name == colorName) {
                if (Renoworks.LocaleController.getLocale() == 'fr') {
                  div.find('.code').html(color.name_fr);
                } else {
                  div.find('.code').html(color.name);
                }
                var swatch = div.find('.swatch');
                swatch.css('background-color', color.rgb);
                swatch.find('.delete_icon').show();
                swatch.find('.click_to_apply').hide();

                break;
              }
            }
          } else {
            //Shutter
            productData = productData.data;
            var product = Renoworks.ProductController.getProductByRWD(
              productData.split('||')[0].split('=')[1]
            );
            product.selectByGridSettings(productData.split('||')[1].split('=')[1]);
            for (var x = 0; x < product.tabs.length; x++) {
              if (product.tabs[x].name == 'Style') {
                div.find('.code').html(product.tabs[x].selectedGrid.name);
              }
            }
            if (Renoworks.LocaleController.getLocale() == 'fr') {
              div.find('.name').html(product.name_fr);
            } else {
              div.find('.name').html(product.name);
            }

            var swatch = div.find('.swatch');
            swatch.find('.delete_icon').show();
            swatch.find('.click_to_apply').hide();
            swatch.css('background-image', shutterImages[layers[i].id]);
          }
        }
      }

      // JAMES
      if (div) {
        div.find('.brightness_tool .slider').slider({
          slide: function(e, ui) {
            $(this)
              .next('input')
              .val(ui.value);
            Renoworks.MaskingController.setGamma(
              Renoworks.MaskingController.brightnessToGamma(ui.value)
            );
          },
          min: -100,
          max: 100,
          step: 10,
          value: Renoworks.MaskingController.gammaToBrightness(layer.gammaLevel),
        });

        div.find('.opacity_tool .slider').slider({
          slide: function(e, ui) {
            $(this)
              .next('input')
              .val(ui.value);
            Renoworks.MaskingController.setOpacity(ui.value);
          },
          min: 0,
          max: 100,
          step: 10,
          value: layer.shadowsLevel,
        });

        setPerspectiveEvent(div, layerId);
      }
    }

    // END JAMES

    if (!isSample) {
      Renoworks.LayerHandler.updateLayerOrder(); // update the layer order
    }
  };

  function setPerspectiveEvent(div, layerId) {
    $(Renoworks.Event).bind(Renoworks.Event.PERSPECTIVE_DIMENSIONS_CHANGE + layerId, function(e) {
      var dimensions = e.dimensions;
      var perspectiveSettings = div.find('.perspective_tool_settings');

      perspectiveSettings
        .find('input:eq(0)')
        .val(dimensions.wF)
        .attr('data-val', dimensions.wF);
      perspectiveSettings
        .find('input:eq(1)')
        .val(dimensions.wI)
        .attr('data-val', dimensions.wI);
      perspectiveSettings
        .find('input:eq(2)')
        .val(dimensions.hF)
        .attr('data-val', dimensions.hF);
      perspectiveSettings
        .find('input:eq(3)')
        .val(dimensions.hI)
        .attr('data-val', dimensions.hI);
    });
  }

  function disableMaskingTools(layerRegion) {
    layerRegion.find('.layer_masking .draw_erase_buttons').addClass('disabled');

    // Enable the masking tools
    layerRegion.find('.layer_masking .drawing_tool_section ').addClass('disabled');
  }

  function setLayerRegionType(projectTag, div, layerType) {
    // Customize the .layer to be of sample project type
    if (projectTag == 'sample') {
      // remove the edit_delete buttons
      div.find('.edit_delete_buttons').remove();

      // remove the drag button
      div.find('.layer_sort').hide();

      // make the layer_name not editable
      div.find('.layerName').removeAttr('contenteditable');

      // remove the product type dropdown
      div.find('select.layoutSelect').remove();

      // remove the layer masking tools section
      div.find('.layer_masking').remove();
    }
  }

  function getLayerNameCounts(layers) {
    var layerNames = {};
    for (var i = 0; i < layers.length; i++) {
      var layerName = layers[i].name;

      if (!layerNames.hasOwnProperty(layerName)) {
        layerNames[layerName] = 1;
      } else {
        layerNames[layerName]++;
      }
    }
    return layerNames;
  }

  function changeToMasterImage() {
    // Only do the change if the image is different than the one to chnage to
    var projectImage = $('.project_image');

    // Show the master image
    var masterPath = Renoworks.ProjectController.getMasterImage();
    var projectImagePath = projectImage.css('background-image');
    projectImagePath = projectImagePath.replace('url(', '').replace(')', '');

    // Only change the background-image if it's a different image
    if (projectImagePath.indexOf(masterPath) == -1) {
      projectImage.css('background-image', "url('" + masterPath + "')");
    }
  }

  function hideAdvancedSettings(layerRegion) {
    var advancedTools = layerRegion.find('.layer_masking .advanced-tools');

    // Deselect all the advanced settings
    advancedTools.find('.advanced_tool').removeClass('selected');
    // Hide advanced tool settings
    advancedTools.find('.advanced_tool .tool_settings').hide();
  }
})(jQuery);
