var Renoworks = window.Renoworks || {};

function Utils() {
  this.homeplayClientRegex = /^homeplay(prime)?$/;

  // Matches either `/ca/fr`, `/ca` or `/en` and
  // ignores files with any extension or words that might contain `/ca/fr`, `/ca` or `/en`
  this.localeUrlRegex = /(\/ca\/fr|\/en|\/ca)(?!\..+|\w+)/;
}

Renoworks.Utils = new Utils();

/**
 * Splits array into chunks of chunkSize
 *
 * @param arr array to split
 * @param chunkSize chunk size
 * @returns {Array} array of arrays of chunkSize
 */
Utils.prototype.chunkArray = function chunkArray(arr, chunkSize) {
  var result = [];
  for (var i = 0; i < arr.length; i += chunkSize) {
    var chunk = arr.slice(i, i + chunkSize);
    result.push(chunk);
  }
  return result;
};

/**
 * Gets locale name based on url path. Passed name can be either `ca` or `fr`
 *
 * @param {string} name locale name
 * @returns {string} name for locale file
 */
Utils.prototype.getLocaleName = function getLocaleName(name, withProduct) {
  var mapping = {
    en: 'en',
    ca: 'en',
    fr: 'fr',
    withProduct: {
      en: 'en_US',
      ca: 'en_CA',
      fr: 'fr_CA',
    },
  };

  return this.homeplayClientRegex.test(Renoworks.client) || withProduct
    ? mapping.withProduct[name]
    : mapping[name];
};

Utils.prototype.getLocalePath = function getLocalePath(locale) {
  var mapping = {
    en_US: '/en',
    en_CA: '/ca',
    fr_CA: '/ca/fr',
  };

  return mapping[locale];
};

/**
 * Parses url parameters and returns a simple object
 *
 * @param {string} url with prameters to parse
 * @param {boolean} [allowAmp=false] If the parser should allow the ampersand character or not.
 * @returns {object} object that contains `param: value` fields
 */
Utils.prototype.deparam = function deparam(url, allowAmp) {
  var queryString = {};
  var regex = allowAmp ? /([^?=]+)(=([^#]*))?/g : /([^?&=]+)(=([^&#]*))?/g;
  (url || '').replace(regex, function($0, $1, $2, $3) {
    queryString[$1] = $3 && decodeURIComponent($3).replace(/\+/g, ' ');
  });
  return queryString;
};

Utils.prototype.getLocaleFromURL = function getLocaleFromURL(url, withProduct) {
  var params = this.deparam(url, false);
  var locale = params.locale;

  if (!locale) {
    var match = this.localeUrlRegex.exec(window.location.pathname);
    if (match && match.length) {
      var args = match[0].split('/');
      locale = args.length ? this.getLocaleName(args[args.length - 1], withProduct) : 'en';
    } else {
      locale = 'en';
    }
  }

  return locale;
};

Utils.prototype.parseDataString = function parseDataString(data) {
  let args = data.split('||');
  if (args.length) {
    return args
      .map(a => this.deparam(a.replace('&amp;', '&'), true))
      .reduce((result, current) => {
        Object.entries(current).forEach(entry => {
          result[entry[0]] = entry[1];
        });

        return result;
      }, {});
  }
};

Utils.prototype.getAppRoot = function getAppRoot(app) {
  const { origin, pathname } = window.location;

  return `${origin}${pathname !== '/' ? pathname.replace(/\/$/, '') : ''}/sites/${app ||
    Renoworks.royalConfig.app}`;
};

Utils.prototype.pathTo = function pathTo(app, file = undefined) {
  return `${this.getAppRoot(app || Renoworks.royalConfig.app)}${file || ''}`;
};

/**
 * A 'hook' that will attach a spinner to a specified element and will return a function to terminate
 * spinner
 * @param {jQuery} $el element to which a spinner will be attached
 * @param {boolean} [autoDisable = true] autoDisable a flag, indicates whether an $el will be disabled automatically
 *                  when returned function is called.
 * @returns {Function} a function that 'stops' the spinner - removes it from the $el and enables the $el
 */
Utils.prototype.useProgressSpinner = function useProgressSpinner($el, autoDisable = true) {
  const $progressSpinner = $('<i class="fa fa-spinner fa-spin" style="margin-left: 1em;"></i>');
  const hasSpinner = $el.find('.fa-spinner').length;

  if (!hasSpinner) {
    $el.append($progressSpinner);

    if (autoDisable) {
      $el.addClass('disabled');
    }
  }

  return () => {
    $el.find('.fa-spinner').remove();

    if (autoDisable) {
      $el.removeClass('disabled');
    }
  };
};

Utils.prototype.useOverlaySpinner = function useOverlaySpinner($el) {
  const html = `
    <div class="overlay-spinner">
      <div class="spinner">
        <div class="bounce"></div>
        <div class="bounce"></div>
      </div>
    </div>
  `.trim();

  const $overlay = $(html);

  if (!$el.find('.overlay-spinner').length) {
    $el.prepend($overlay);
  }

  return () => {
    if ($el.find('.overlay-spinner')) {
      $el.find('.overlay-spinner').remove();
    }
  };
};

/**
 * Format date string
 * @param {string} dateStr a date string with following format: 2019-06-17 15:33:26.0
 * @returns {string} formatted date string with AM/PM time instead of 24hr
 */
Utils.prototype.getFormattedDate = function getFormattedDate(dateStr) {
  const timestamp = Date.parse(dateStr.replace(/\s/, 'T'));
  if (Number.isNaN(timestamp)) {
    return '';
  }

  const date = new Date(timestamp);
  const formattedDate = date.toISOString().slice(0, 10);
  const formattedTime = Renoworks.Util.formatAMPM(date);
  return `${formattedDate} ${formattedTime}`;
};

Utils.prototype.getProductCategory = function getProductCategory(categoryName) {
  const category = Renoworks.ProductController.getCategories().find(
    ({ name }) => name.toLowerCase() === categoryName.toLowerCase()
  );

  return category.categories || [];
};

Utils.prototype.intersect = function intersect(arrA = [], arrB = []) {
  return arrA.filter(v => arrB.includes(v));
};

Utils.prototype.capitalize = function capitalize(str = '') {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

Utils.prototype.fetchBlob = function fetchBlob(src) {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open('GET', src);
    xhr.responseType = 'blob';
    xhr.onload = () => {
      if (xhr.status == 200) {
        resolve(xhr.response, xhr);
      } else {
        reject(xhr.response, xhr);
      }
    };
    xhr.onerror = reject;
    xhr.send();
  });
};
